<template>
  <div>
    <default-video
      v-if="media_type == 'default'"
      :media_url="media_url"
      :playing="playing"
      :currentTime="currentTime"
      :current_language="current_language"
      :subtitle="current_subtitle_segment"
      :is_translate="is_translate"
      @loadeddata="initCurrentTime"
      @play="onPlay"
      @pause="onPause"
      @waiting="onWaiting"
      @timeupdate="onTimeUpdate($event.target.currentTime * 1000)"
      @seeked="onSeeked($event.target.currentTime * 1000)"
    />
    <vimeo-player
      v-if="media_type == 'vimeo'"
      :vimeo_embed_link="vimeoData.vimeo_embed_link"
      :playing="playing"
      :currentTime="currentTime"
      :current_language="current_language"
      :subtitle="current_subtitle_segment"
      :is_translate="is_translate"
      @loadeddata="initCurrentTime"
      @play="onPlay"
      @pause="onPause"
      @waiting="onWaiting"
      @seeked="onSeeked($event.seconds * 1000)"
      @timeupdate="onTimeUpdate($event.seconds * 1000)"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
const DefaultVideo = () => import('@/components/VideoPlayer/default.vue');
const VimeoPlayer = () => import('@/components/VideoPlayer/vimeo.vue');

let firebase = null;

if (process.env.NODE_ENV == "development") {
  firebase = require("@/firebase/test_environment/db_test");
} else {
  firebase = require("@/firebase/production_environment/db");
}

const db = firebase.db;

export default {
  components: {
    DefaultVideo,
    VimeoPlayer,
  },

  data() {
    return {
      playing: false,
      currentTime: 0,
      slowInternetTimeout: null,
    };
  },

  computed: {
    ...mapState({
      media_type: (state) => state.media_type,
      media_url: (state) => state.media_url,
      current_language: (state) => state.current_language,
      task_id: (state) => state.task_id,
    }),

    ...mapGetters({
      playPause: "video/getPlayPress",
      replay: "video/getReplay",
      current_video_time: "video/getUpdateTime",
      getRewindForward: "video/getRewindForward",
      is_translate: "srt/is_translate",
      last_time_watched: "video/getLastTimeWatched",
      lastPlayedTime: "srt/lastPlayedTime",
      vimeoData: "getVimeoData",
      videoTimeChange: "video/getVideoTimeChange",
    }),

    current_subtitle_segment() {
      const getSubtitleByTime = this.$store.getters["srt/subtitle_by_time"];
      return getSubtitleByTime(this.current_video_time);
    },
  },

  watch: {
    playPause() {
      this.playing = !this.playing;
    },
    replay() {
      const id = this.$store.getters["controller/getCurrentSegmentIndex"];
      const segment = this.$store.getters["srt/subtitle"](id);

      const newValue = segment.start;
      if (this.currentTime === newValue) this.currentTime = newValue + 1;
      else this.currentTime = newValue;

      this.$store.commit("video/setCurrentTime", newValue);
    },
    getRewindForward() {
      this.currentTime = this.getRewindForward;
    },
    videoTimeChange(newValue) {
      this.currentTime = newValue;
      this.$store.commit("video/setCurrentTime", newValue);
    },
  },

  mounted() {
    window.addEventListener("beforeunload", async (e) => {
      e.preventDefault();

      const last_seen = this.last_time_watched;

      if (this.is_translate) {
        await db.collection("tasks").doc(this.task_id).update({
          last_ms_played_translate: last_seen,
        });
      } else {
        await db.collection("tasks").doc(this.task_id).update({
          last_ms_played_transcription: last_seen,
        });
      }

      // this.leaving;
    });
  },

  methods: {
    ...mapActions(["setLocalTime"]),

    // leaving() {
    //   let last_seen = this.last_assisted_timing;
    //   let milliseconds = last_seen * 1000;
    //   this.setLocalTime({
    //     mili: milliseconds,
    //   });
    // },

    initCurrentTime() {
      this.currentTime = this.lastPlayedTime || 0;
    },

    onTimeUpdate(milliseconds) {
      this.$store.commit("video/setUpdateTime", milliseconds);
    },

    onSeeked(milliseconds) {
      this.$store.commit("video/setCurrentTime", milliseconds);
    },

    onWaiting() {
      if (!this.slowInternetTimeout) {
        this.slowInternetTimeout = setTimeout(() => {
          this.$store.commit("soundwave/setPlay", false);
          clearTimeout(this.slowInternetTimeout);
          this.slowInternetTimeout = null;
        }, 50);
      }
    },

    onPlay() {
      if (this.slowInternetTimeout) {
        clearTimeout(this.slowInternetTimeout);
        this.slowInternetTimeout = null;
      }
      this.$store.commit("soundwave/setPlay", true);
      this.playing = true;
    },

    onPause() {
      this.$store.commit("soundwave/setPlay", false);
      this.playing = false;
    },
  },
};
</script>
