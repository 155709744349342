<template>
  <div>
    <b-modal id="modal-1" title="Configurações" 
        @ok="setShortCutes" 
        @cancel="resetShortCutes"
        :ok-disabled="isBlock"
        :ok-title='"Aplicar"'
        :cancel-title='"Restaurar padrão"'
        >

        <div class="buttons-options">
            <button @click="navegation = 1">Navegação</button>
            <div class="separate">|</div>
            <button @click="navegation = 2">Editor</button>
            <div class="separate">|</div>
            <button @click="navegation = 3">Outros</button>
        </div>

        <div class="error" v-if="this.error != '' ">{{error}}</div>
        
        <div class="col" v-for="shortCute in config" v-bind:key="shortCute.indice">
             <div class="config-item"  v-if="shortCute.page == navegation">
                <span class="description">{{shortCute.title}}</span>
                <span class="trigger"><b>ALT + </b></span>
                <input class="inputItem"  @click="filterOpen = true" maxlength="1"  type="text" @keydown="getKey($event,  shortCute)" v-model="shortCute.atl">
            </div>

        </div>

        <div class="filterGetKey" v-if="filterOpen" @click="filterOpen = false">
            <div class="filterArea">
                <span>Digite uma tecla para configurar o atalho</span>
            </div>
        </div>
    
    </b-modal>
    </div>
</template>

<script>

export default {
    data(){
        return {
            error: '',
            codesInUse: [],
            codesBlocked: [18],
            navegation: 1 ,
            isBlock: false,
            filterOpen: false,
        }
    },
    computed:{
        config:{
            get(){
                return this.$store.getters['controller/getShortCutes']
            },
            set(newValue){
                return newValue;
            }
        }
    },
    created(){
        let userConfig = localStorage.getItem('taskConfig')
        userConfig = JSON.parse(userConfig)

        if(userConfig == null){
             this.config = this.$store.getters['controller/getShortCutes']
        }else{
            this.$store.dispatch('controller/setShortCutes', userConfig)
            this.config = this.$store.getters['controller/getShortCutes']
        }
    },
    methods:{
        getKey(event, config){

            let key = event.key
            let keyCode = event.keyCode
            this.filterOpen = false

            if(keyCode == config.code) return // If new code not change, return 
            if(this.codesBlocked.includes(keyCode)) return // If keyCode is blocked return

            if(this.keyCodeIsValid(keyCode) == false){
                
                this.error = ''
                
                // Search and replace the new code 
                let indexToChange = this.codesInUse.indexOf(config.code)
                this.codesInUse.splice(indexToChange, 1, keyCode)

                config.atl = key.toLowerCase()
                config.code = keyCode
                this.isBlock = false
             
            }else{
                this.error = 'Já existe um atalho definido com está tecla!'  
                this.isBlock = true
            }
            
            
        },
        setShortCutes(bvModalEvent){
             this.$store.dispatch('controller/setShortCutes', this.config)
             localStorage.setItem('taskConfig', JSON.stringify(this.config))
        },
         resetShortCutes(bvModalEvent){
            this.$store.dispatch('controller/resetShortCutes')
            this.error = ''
            this.isBlock = false
            bvModalEvent.preventDefault()
        },
        keyCodeIsValid(keyCode){
            this.codesInUse = []
            Object.keys(this.config).forEach(shortcute => {
                this.codesInUse.push(this.config[shortcute].code)
            })
            return this.codesInUse.includes(keyCode)
        },
    }
    
}
</script>

<style lang="scss">
   @import './styles/index.scss';
</style>