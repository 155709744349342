<template>
  <nav
    id="LeftNavbar"
    class="navbar has-background-right"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <a class="has-text-dark" :href="returnToURL">
        <i
          class="
            fas
            fa-arrow-left
            has-text-white-ter
            fa-lg
            navbar-item
            icon-hover
          "
        ></i>
      </a>
      <a class="navbar-item" href="https://skylar.ai" target="_blank">
        <img src="@/assets/skylar-logo.png" width="112" height="28" />
      </a>
      <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div v-if="is_srt" class="navbar-menu">
      <div class="navbar-end">
        
        <ChangePlataform v-if="switchToEditor"/>
        <!-- SAVED SEGMENTS -->
        <div id="saved-segments" style="position: relative">
          <label class="saved-segments-btn">
            <select
              v-model="segmentsFilter"
              @change="changeSegmentFilter"
              ref="segmentsFilter"
            >
              <option value="all">Todos segmentos</option>
              <option value="unsaved">Segmentos não confirmados</option>
              <option value="saved">Segmentos Confirmados</option>
            </select>
          </label>
        </div>
        <Alert />
        <!-- SAVED SEGMENTS -->

        <div class="search-bar-settings">
          <input
            v-model="query"
            v-if="!is_translate"
            class="input is-rounded"
            id="search-bar"
            type="search"
            placeholder="Buscar ( Ctrl+F )"
            autocomplete="off"
            @blur="searchResultsVisible = true"
            @focus="searchResultsVisible = true"
            @keydown.esc="reset"
            @input="softReset"
            @keydown="performSearch"
            ref="search"
          />
          <input
            v-model="query"
            v-else
            class="input is-rounded"
            id="search-bar"
            type="search"
            placeholder="Buscar (Ctrl+F)"
            autocomplete="off"
            @blur="searchResultsVisible = true"
            @focus="searchResultsVisible = true"
            @keydown.esc="reset"
            @input="softReset"
            @keydown="performTranslateSearch"
            ref="search"
          />
          <div
            v-if="query.length > 0 && searchResultsVisible"
            class="optional-results"
          >
            <div
              v-if="searchResults"
              @keydown.enter="searchResultsVisible = true"
              class="card"
            >
              <header v-if="searchResults.length > 0" class="card-header">
                <p class="card-header-title">
                  Resultados: {{ this.initialIndex }} de
                  {{ searchResults.length }}
                </p>
              </header>
              <header v-else class="card-header">
                <p class="card-header-title is-centered no-results">
                  Nenhum resultado
                </p>
              </header>

              <!-- REPLACE AREA-->
              <div v-if="enableReplace" class="card-content">
                <div class="content">
                  <input
                    v-model="replaceQuery"
                    class="input is-rounded"
                    type="search"
                    placeholder="Inserir texto para substituir"
                    ref="replaceText"
                    enabled
                  />
                  <br />
                </div>
              </div>

              <!-- END REPLACE AREA  -->
              <footer
                @mousedown.prevent="searchResultsVisible = true"
                class="card-footer"
              >
                <a @click="highlightPrevious" class="card-footer-item"
                  ><i class="fas fa-chevron-up" aria-hidden="true"></i
                ></a>
                <a @click="highlightNext" class="card-footer-item">
                  <i class="fas fa-chevron-down" aria-hidden="true"></i
                ></a>
                <a class="card-footer-item" @click="enableReplaceAction"
                  >Substituir</a
                >
                <a
                  v-if="
                    enableReplace && replaceQuery.length > 0 && !is_translate
                  "
                  class="card-footer-item"
                  id="replace-button"
                  @click="replaceTextTest"
                  >OK</a
                >
                <a
                  v-else-if="
                    enableReplace && replaceQuery.length > 0 && is_translate
                  "
                  class="card-footer-item"
                  id="replace-button"
                  @click="replaceTranslateText"
                  >Sub
                </a>
              </footer>
            </div>
          </div>
        </div>

        <!-- SEARCH FOR BOOKMARKS  -->
        <div class="bookmark-btn" id="bookmark">
          <button class="button is-rounded" @click="openBookmark = true">
            Bookmarks
          </button>
        </div>

        <Bookmark v-if="openBookmark" @closeModal="closeBookmarkModal" />
      </div>
    </div>

    <div class="navbar-end">
      <div
        class="icon-hover navbar-item dropdown"
        :class="{ 'is-active': hotkey_dropdown_active }"
        @mouseover="hotkey_dropdown_active = true"
        @mouseleave="hotkey_dropdown_active = false"
      >
        <div class="dropdown-trigger">
          <i slot="trigger" role="button">
            <i class="far fa-question-circle has-text-white-ter fa-lg"></i>
          </i>
        </div>
        <div class="dropdown-menu" id="dropdown-menu" role="menu">
          <div class="dropdown-content">
            <div class="dropdown-item">
              <b>Atalhos</b>
            </div>
            <hr class="dropdown-divider" />
            <div class="hotkeys">
              <div class="hotkeys-right">
                <p
                  class="dropdown-item is-size-12"
                  v-for="(hotkey, i) of hotkeys_right"
                  :key="i"
                >
                  <b>
                    {{ hotkey.key }}
                    <i :class="hotkey.icon" />
                  </b>
                  : {{ hotkey.action }}
                </p>
              </div>
              <div class="hotkeys-left">
                <p
                  class="dropdown-item is-size-12"
                  v-for="(hotkey, i) of hotkeys_left"
                  :key="i"
                >
                  <b>
                    {{ hotkey.key }}
                    <i :class="hotkey.icon" />
                  </b>
                  : {{ hotkey.action }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <i
        class="navbar-item icon-hover"
        slot="trigger"
        role="button"
        @click="changeLayoutSide"
      >
        <p class="has-text-dark" title="Trocar layout">
          <i class="fas fa-columns fa-lg has-text-white-ter"></i>
        </p>
      </i>
      <DarkModeToggler></DarkModeToggler>
    </div>
  </nav>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import DarkModeToggler from "./DarkModeToggler";
import Alert from "./Alert";
import Bookmark from "./Bookmarks";
import ChangePlataform from "./ChangePlataform";

import Fuse from "fuse.js";
const { mapState, mapMutations } = createNamespacedHelpers("srt");
const { mapActions } = createNamespacedHelpers("controller");
export default {
  components: {
    DarkModeToggler,
    Alert,
    Bookmark,
    ChangePlataform
  },
  data() {
    return {
      hotkey_dropdown_active: false,

      hotkeys_left: [
        {
          key: "ALT+P",
          action: "Play/pause video.",
        },
        {
          key: "ALT+R",
          action: "Play/pause segment.",
        },
        {
          key: "ALT+",
          icon: "fas fa-chevron-up",
          action: "Segmento anterior.",
        },
        {
          key: "ALT+",
          icon: "fas fa-chevron-down",
          action: "Próximo segmento.",
        },
        {
          key: "ALT+,",
          action: "Voltar no segmento.",
        },
        {
          key: "ALT+.",
          action: "Avançar no segmento.",
        },
        {
          key: "CTRL+B",
          action: "Bookmark no segmento.",
        },
        {
          key: "ALT+B",
          action: "Novo segmento.",
        },
        {
          key: "ALT+N",
          action: "Unir segmento.",
        },
        {
          key: "ALT+DEL",
          action:
            "Retira a primeira palavra do segmento seguinte, e insere no fim do segmento atual.",
        },
      ],
      hotkeys_right: [
        {
          key: "ALT+J",
          action: "Altera tempo inicial.",
        },
        {
          key: "ALT+K",
          action: "Altera tempo final.",
        },
        {
          key: "ALT+I",
          action: "Ativa/desativa modo revisão.",
        },
        {
          key: "ALT+W",
          action: "¿",
        },
        {
          key: "ALT+Q",
          action: "¡",
        },
        {
          key: "CTRL+ENTER",
          action:
            "Recortar texto a partir do cursor e colar no segmento abaixo.",
        },
        {
          key: "ALT+ENTER",
          action:
            "Recortar texto a partir do cursor e adicionar em novo segmento.",
        },
        {
          key: "CTRL+F",
          action: "Buscar e substituir.",
        },
      ],
      //segments_filter: 'all',
      query: "",
      replaceQuery: "",
      searchResults: [],
      subtitlesData: [],
      highlightedIndex: 0,
      initialIndex: 0,
      enableReplace: false,
      options: {
        shouldSort: false,
        //tokenize: true,
        // matchAllTokens: true,
        includeMatches: true,
        threshold: 0.1,
        location: 0,
        distance: 800,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: ["text"],
      },
      translate_options: {
        shouldSort: false,
        //tokenize: true,
        // matchAllTokens: true,
        includeMatches: true,
        threshold: 0.1,
        location: 0,
        distance: 800,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: ["translate_text"],
      },
      openBookmark: false,
    };
  },

  computed: {
    returnToURL() {
      return document.referrer || "https://review.skylar.ai";
    },
    is_translate: function () {
      return this.$store.getters["srt/is_translate"];
    },

    is_srt: function () {
      return this.$store.getters["getTaskType"].includes("srt");
    },
    switchToEditor: function () {
      return this.$store.getters["switchToEditor"];
    },
    segmentsFilter: {
      get() {
        return this.$store.getters["controller/getSegmentsFilter"];
      },
      set(newValue) {
        return newValue;
      },
    },
    ...mapState({
      subtitles: (state) => state.subtitles,
    }),
  },
  mounted() {
    if (this.$refs.search) {
      this.$refs.search.addEventListener("keydown", (e) => {
        if (e.keyCode === 114 || (e.ctrlKey && e.keyCode === 70)) {
          e.preventDefault();
          this.$refs.search.focus();
        }
      });
      window.addEventListener("keydown", this.focusSearch);
    }
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.focusSearch);
  },

  methods: {
    changeSegmentFilter() {
      let value = this.$refs.segmentsFilter.value;
      this.$store.dispatch("controller/changeSegmentsFilter", value);
    },
    changeLayoutSide() {
      this.$store.commit("controller/changeLayoutSide");
    },

    reset() {
      this.query = "";
      this.replaceQuery = "";
      this.enableReplace = false;
      this.highlightedIndex = 0;
    },

    softReset() {
      this.searchResultsVisible = true;
    },

    enableReplaceAction() {
      this.enableReplace = !this.enableReplace;
    },

    focusSearch(e) {
      if (e.keyCode == 70 && e.ctrlKey) {
        e.preventDefault();

        this.$refs.search.focus();
      }
    },

    performSearch(e) {
      this.searchResults = new Fuse(this.subtitles, this.options).search(
        this.query
      );
      this.subtitlesData = this.subtitles;
      var index = this.highlightedIndex;
      const vm = this;
      if (e.keyCode == 13) {
        e.preventDefault();
        if (this.searchResults.length == 0) return;

        this.$refs.search.focus();
        var questionArray = this.searchResults;
        var arrayLength = questionArray.length;
        if (index < arrayLength) {
          vm.setCurrentIndex(questionArray[index].refIndex);
          this.highlightedIndex++;
          this.$refs.search.focus();
        } else {
          this.highlightedIndex = 0;
          index = 0;
          vm.setCurrentIndex(questionArray[index].refIndex);
        }
      }
    },

    performTranslateSearch(e) {
      this.searchResults = new Fuse(
        this.subtitles,
        this.translate_options
      ).search(this.query);
      this.subtitlesData = this.subtitles;
      var index = this.highlightedIndex;
      const vm = this;
      if (e.keyCode == 13) {
        e.preventDefault();
        if (this.searchResults.length == 0) return;

        this.$refs.search.focus();
        var questionArray = this.searchResults;
        var arrayLength = questionArray.length;
        if (index < arrayLength) {
          vm.setCurrentIndex(questionArray[index].refIndex);
          this.highlightedIndex++;
          this.$refs.search.focus();
        } else {
          this.highlightedIndex = 0;
          index = 0;
          vm.setCurrentIndex(questionArray[index].refIndex);
        }
      }
    },

    highlight() {
      if (!this.searchResults) return;

      const iterate = [];
      const indexes = this.searchResults;
      for (var key in indexes) {
        if (indexes.hasOwnProperty(key)) {
          iterate.push(indexes[key].refIndex);
          const i = iterate[0];
          this.setCurrentIndex(i);
        }
      }
    },

    highlightNext() {
      if (this.searchResults.length == 0) {
        this.$swal("Nenhum resultado", "", "error");
        return;
      }
      // if(!this.searchResults.refIndex) return
      this.$refs.search.focus();
      var index = this.highlightedIndex;
      const vm = this;
      var resultsArray = this.searchResults;
      var arrayLength = resultsArray.length;

      if (index < arrayLength) {
        vm.setCurrentIndex(resultsArray[index].refIndex);
        this.highlightedIndex++;
        if (this.initialIndex < arrayLength) {
          this.initialIndex++;
        }
        this.$refs.search.focus();
      } else {
        this.highlightedIndex = 0;
        index = 0;
        vm.setCurrentIndex(resultsArray[index].refIndex);
        this.highlightedIndex++;
        this.initialIndex = 1;
        //this.initialIndex++
        this.$refs.search.focus();
        return;
      }
    },

    highlightPrevious() {
      if (this.searchResults.length == 0) {
        this.$swal("", "Nenhum resultado", "error");
        return;
      }
      const vm = this;
      var index = this.highlightedIndex;
      index--;
      var resultsArray = this.searchResults;
      var arrayLength = resultsArray.length;
      if (index > 1) {
        index--;
        this.highlightedIndex--;
        this.initialIndex--;
        vm.setCurrentIndex(resultsArray[index].refIndex);
      } else {
        this.highlightedIndex = 0;
        this.initialIndex = 1;
        index = 0;
        vm.setCurrentIndex(resultsArray[index].refIndex);
        return;
      }
    },

    setCurrentIndex(index) {
      this.$store.commit("controller/setCurrentSegmentIndex", index);
    },

    replaceTextTest() {
      var replacement = "";
      var textToModify = this.searchResults;
      const iterate = [];
      const indexes = this.searchResults;
      for (var key in indexes) {
        if (indexes.hasOwnProperty(key)) {
          iterate.push(indexes[key].refIndex);
        }
      }
      const values = Object.values(iterate);
      for (var indexModifier of values) {
        var subtitleModified = this.subtitles[indexModifier].text.replace(
          this.query,
          this.replaceQuery
        );
        var index = this.subtitles[indexModifier];
        index.text.replace(this.query, this.replaceQuery);
        var end = this.subtitles[indexModifier].end;
        var start = this.subtitles[indexModifier].start;
        var id = this.subtitles[indexModifier].id;
        var subtitle = {
          end,
          id,
          start,
          text: subtitleModified,
        };
        this.$store.commit("srt/updateSubtitle", subtitle);
      }
    },

    replaceTranslateText() {
      var replacement = "";
      var textToModify = this.searchResults;
      const iterate = [];
      const indexes = this.searchResults;
      for (var key in indexes) {
        if (indexes.hasOwnProperty(key)) {
          iterate.push(indexes[key].refIndex);
        }
      }
      const values = Object.values(iterate);
      for (var indexModifier of values) {
        var subtitleModified = this.subtitles[
          indexModifier
        ].translate_text.replace(this.query, this.replaceQuery);
        var index = this.subtitles[indexModifier];
        index.translate_text.replace(this.query, this.replaceQuery);
        var end = index.end;
        var start = index.start;
        var id = index.id;
        var text = index.text;
        var subtitle_index = indexModifier;
        var subtitle = {
          end,
          id,
          start,
          text,
          translate_text: subtitleModified,
        };
        this.$store.commit("srt/updateSubtitle", subtitle);
      }
    },

    highlightResultsText() {
      if (!this.query) {
        return;
      }
      var content = this.resultsArray;
      return this.content.replace(new RegExp(this.query, "gi"), (match) => {
        return '<span class="highlightedText">' + match + "</span>";
      });
    },
    openBookmarkModal() {
      this.openBookmark = true;
    },
    closeBookmarkModal() {
      this.openBookmark = false;
    },
    ...mapActions(["setCurrentSegmentIndex"]),

    ...mapMutations(["updateSubtitle"]),
  },
};
</script>

<style lang="scss">
.bookmark-btn {
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 13px;
  align-items: center;

  button {
    height: 80%;
    padding: 3px;
  }
}

.saved-segments-card {
  position: absolute;
  display: block;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 13px;
  top: 90px;
}

.saved-segments-btn {
  position: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 13px;
  height: 100%;
  select {
    height: 100%;
  }
}

.saved-segments-btn select {
  background-color: #526079;
  color: white;
  cursor: pointer;
  height: 70%;
  margin-bottom: 10px;
}

.saved-segments-card__btn {
  background: #526079;
  color: white;
  border: none;
  width: 6rem;
  border-radius: 5px;
  cursor: pointer;
}

.content {
  padding: 5px;
}

.card-header,
.card-footer,
.card-content {
  font-size: 16px;
}

#highlightedText {
  background: yellow;
}

#search-bar {
  display: flex;
  width: 170px;
  margin-right: 10px;
}

.search-bar-settings {
  display: flex;
  align-items: center;
  justify-content: center;
  input {
    height: 70%;
    padding: 3px;
  }
}

.no-results {
  color: crimson;
  background: rgb(238, 238, 113);
}

#replace-button {
  background: lightgreen;
}

.dropdown-menu {
  width: 30rem;
}
.hotkeys {
  display: grid;
  grid-template-columns: auto auto;
}

.navbar-menu-test {
  display: flex;
  width: auto;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

#LeftNavbar {
  display: flex;
  padding: 0;

  .has-text-dark {
    margin-bottom: 0px;
  }

  .optional-results {
    position: absolute;
    z-index: 10;
    margin-top: 20%;
  }

  .navbar-item,
  .navbar-link {
    margin-bottom: 5px;
  }

  .label {
    margin-bottom: 5px;
  }
}
</style>
