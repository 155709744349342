let firebase = null

if(process.env.NODE_ENV == "development"){
  firebase = require('@/firebase/test_environment/db_test')
}
else{
  firebase = require('@/firebase/production_environment/db')
}

const db = firebase.db
const auth = firebase.auth

const state = {
    done: [],
    redo: [],
    initial_state: [],
    subtitles_unsubscriber: null,
}

const getters = {
    subtitles_live(state, getters, rootState, rootGetters){
        return rootGetters['srt/subtitles_live']
    }
}

const mutations = {
    addDone: (state, mutation) =>{
        if(state.done.length > 10000){
            state.done.shift()
        }
        state.done.push(mutation)
    },

    addRedo: (state, mutation) => {
        if(state.redo.length > 10000){
            state.redo.shift()
        }
        state.redo.push(mutation)
    },

    cleanLastUndo: (state) => state.done.pop(),

    cleanLastRedo: (state) => state.redo.pop(),

    storeInitialState: (state, subtitles) => {
        state.initial_state = [...subtitles]
    },

}

const actions = {
    addDoneAction({commit, getters}, mutation){
        if(mutation.type === 'srt/updateSubtitle'){
            const subtitle_id = mutation.payload.id
            const initialValue = state.initial_state.find(el => el.id === subtitle_id)
            let firstValue = {}
                firstValue.type = 'srt/updateSubtitle'
                firstValue.payload = initialValue
            if(!initialValue){
                commit('storeInitialState', getters.subtitles_live)
                commit('addDone', mutation)

            } else{
                const index_initial = state.initial_state.map(t => t.id).indexOf(subtitle_id)
                const index_live = getters.subtitles_live.map(t => t.id).indexOf(subtitle_id)

                if(index_live == index_initial){
                    commit('addDone', firstValue )
                    commit('addDone', mutation)

                } else{
                    commit('addDone', firstValue )
                    commit('storeInitialState', getters.subtitles_live)
                    commit('addDone', mutation)
                }
            }
        } else if(mutation.type === 'srt/addMiddleSubtitle'){
            commit('addDone', mutation)
        } else if(mutation.type === 'srt/removeSubtitle'){
            const subtitle_id = mutation.payload.id
            const initialValue = state.initial_state.find(el => el.id === subtitle_id)

            let firstValue = {}
                firstValue.type = 'srt/removeSubtitle'
                firstValue.payload = initialValue

            if(!initialValue){
                commit('storeInitialState', getters.subtitles_live)
                commit('addDone', mutation)

            } else{
                const index_initial = state.initial_state.map(t => t.id).indexOf(subtitle_id)
                const index_live = getters.subtitles_live.map(t => t.id).indexOf(subtitle_id)

                if(index_live == index_initial){
                    commit('addDone', firstValue)
                    //commit('addDone', mutation)

                } else{
                    commit('addDone', mutation)
                    //commit('storeInitialState', getters.subtitles_live)
                    //commit('addDone', mutation)
                }
            }
        }
    },

    undoAction: ({commit, dispatch, rootState, rootGetters , state}) => {
        if(state.done.length == 0) return

        const mutation_type = state.done[state.done.length - 1].type

        if(mutation_type ===  "srt/addMiddleSubtitle"){
            dispatch('undoAddMiddleSubtitle')
            
        }else if(mutation_type === 'srt/removeSubtitle'){
            commit('srt/receivedMutations', null, {root: true})
            
            const addRedo =  state.done[state.done.length - 1]
            commit('addRedo', addRedo)

            dispatch('undoRemovedSegment')
            
        }else if(mutation_type === "srt/updateSubtitle"){
            const addRedo =  state.done[state.done.length - 1]
            commit('addRedo', addRedo)
            
            if(state.done.length > 1){
                commit('cleanLastUndo')
            }

            dispatch('undoUpdateSubtitle')
        }
    },

    undoUpdateSubtitle: ({commit, dispatch, rootState, rootGetters , state}) => {
        const task_id = rootState.task_id
        const subtitles_live = rootGetters['srt/subtitles_live']

        const payload        = state.done[state.done.length - 1].payload
        const document_id    = payload.id
        const text           = payload.text
        const translate_text = payload.translate_text || ""
        const start          = payload.start
        const end            = payload.end
        const is_bookmark    = payload.is_bookmark

        db.collection('tasks').doc(task_id).collection('subtitles').doc(document_id).update({
            end,
            is_bookmark,
            start,
            text,
            translate_text
        })
        .then(() => {
            const index = subtitles_live.map(t => t.id).indexOf(document_id)
            commit('controller/setCurrentSegmentIndex', index, {root: true})
            commit("srt/setLastSegmentModified", index, {root: true})
            commit('cleanLastUndo')
        })
        .catch(err => console.log("Error updating subtitle, undo -> ",err))
    },

    undoAddMiddleSubtitle: ({commit, dispatch, rootState, rootGetters , state}) => {
        commit('srt/receivedMutations', null, {root: true})

        const addRedo =  state.done[state.done.length - 1]
        commit('addRedo', addRedo)

        const subtitle_id = state.done[state.done.length - 1].payload.subtitle.id
        dispatch('srt/removeSegment', subtitle_id, {root: true})
        commit('cleanLastUndo')
    },

    undoRemovedSegment({ state, commit, rootState }) {
        const task_id = rootState.task_id 
        const payload = state.done[state.done.length - 1].payload

        commit('cleanLastUndo')
        
        const new_segment = { }
        new_segment.text             = payload.text || ''
        new_segment.translate_text   = payload.translate_text || ''
        new_segment.start            = payload.start
        new_segment.end              = payload.end
        new_segment.index            = payload.index
        new_segment.is_bookmark      = "false"
        const id                     = payload.id
 
         db.collection('tasks')
           .doc(task_id)
           .collection('subtitles')
           .doc(id)
           .set(new_segment)
           .catch(error => {
               commit('error/addError', { 
                   context: 'add_new_segment',
                   error_ref: error,
                   segment_ref: new_segment,
                   message: `Erro ao adicionar novo segmento`,
               }, { root: true })
          })
    },

    redoAction: ({commit, dispatch, rootState, rootGetters, state}) => {
        if(state.redo.length == 0) return

        const mutation_type = state.redo[state.redo.length - 1].type

        if(mutation_type === 'srt/updateSubtitle'){
            dispatch('redoUpdateSubtitle')
        }else if(mutation_type === 'srt/addMiddleSubtitle'){
            commit('srt/receivedMutations', null, {root: true})
            dispatch('redoAddMiddleSubtitle')
            commit('cleanLastRedo')
            
        }else if(mutation_type === 'srt/removeSubtitle'){
            //commit('srt/receivedMutations', null, {root: true})
            const subtitle_id = state.redo[state.redo.length - 1].payload.id
            dispatch('srt/removeSegment', subtitle_id, {root: true})
            commit('cleanLastRedo')
        }
    },

    redoUpdateSubtitle:({commit, dispatch, rootState, rootGetters , state}) => {
        const payload = state.redo[state.redo.length - 1].payload
        const subtitles_live = rootGetters['srt/subtitles_live']
        const task_id = rootState.task_id

        const document_id    = payload.id
        const text           = payload.text
        const translate_text = payload.translate_text || ""
        const start          = payload.start
        const end            = payload.end
        const is_bookmark    = payload.is_bookmark

        db.collection('tasks').doc(task_id).collection('subtitles').doc(document_id).update({
            end,
            is_bookmark,
            start,
            text,
            translate_text
        })
        .then(() => {
            const index = subtitles_live.map(t => t.id).indexOf(document_id)
            commit('controller/setCurrentSegmentIndex', index, {root: true})
            commit("srt/setLastSegmentModified", index, {root: true})
            commit('cleanLastRedo')
        })
        .catch(err => console.log("Redo -> ",err))
    },

    redoAddMiddleSubtitle: ({state, commit, rootState}) => {
        const task_id = rootState.task_id
        const payload = state.redo[state.redo.length - 1].payload.subtitle
        const id      = payload.id
        const index   = state.redo[state.redo.length - 1].payload.index

        const new_segment           = {}
        new_segment.text            = payload.text || ''
        new_segment.translate_text  = payload.translate_text || ''
        new_segment.start           = payload.start
        new_segment.end             = payload.end
        new_segment.index           = index
        new_segment.is_bookmark     = "false"

        db.collection('tasks').doc(task_id).collection('subtitles').doc(id).set(new_segment)
        .catch(error => {
              commit('error/addError', { 
                  context: 'add_new_segment',
                  error_ref: error,
                  segment_ref: current_segment,
                  message: `Erro ao adicionar novo segmento`,
              }, { root: true })
        })


    },
}

const namespaced = true;

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
}