<template>
    <b-alert v-if="show" show variant="danger" class="alertTime">
        <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
        {{message}}
        <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
    </b-alert>
</template>

<script>
export default {
    data(){
        return{
            show: false,
            message: '',
        }
    },
    computed:{
        getAlertData(){
            return this.$store.getters['srt_divided/getAlertTimeRange']
        },
        active_segment(){
            return this.$store.getters['srt_divided/active_segment']	
        },
        subtitles: function () {
            return this.$store.state.srt_divided.subtitles
        },
        getTimeWorker() {
            return this.$store.getters['getTimeWorker'];
        },
    },
    watch:{
        active_segment(){
            this.checkIfSegmentHasError()
        },
        getAlertData(data){
            this.show = data.show
            this.message = data.message
        },
    },
    methods:{
        checkIfSegmentHasError(){
             if(this.getTimeWorker.start != '' && this.getTimeWorker.end != ''){
                if(this.active_segment.start < this.getTimeWorker.start || this.active_segment.end > this.getTimeWorker.end) {
                    this.show = true
                    this.message = 'Segmento fora do intervalo de trabalho!'
                    this.$store.dispatch('error/setSegmentWithError', true)
                    return true
                }else{
                    this.show = false
                    this.message = ''
                    this.$store.dispatch('error/setSegmentWithError', false)
                    return false
                }
            }
        }
    }
}
</script>

<style lang='scss'>
    .alertTime{
        top: 0px;
        max-width: 90%;
        margin-left: 5%;
        padding: 4px !important;
        text-align: center;
        font-size: 12px;
        margin-bottom: 3px !important;
        color: #000;
        svg{
            margin-right: 5px;
            margin-left: 5px;
        }
  }
</style>