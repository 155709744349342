<template>
  <div class="task-name">
      <span>{{ task_name}}</span>
  </div>
</template>

<script>
export default {
    computed: {
        task_name: function () {
            return this.$store.state.task_name

        }
    }
}
</script>

<style >
    .task-name {
        display: flex;
        height: 50px;
        font-size: 24px;
        font-weight: bold;
        color: #fff;
        padding: 10px;
    }
</style>