// TXT task module

let firebase = null

if(process.env.NODE_ENV == "development"){
  firebase = require('@/firebase/test_environment/db_test')
}
else{
  firebase = require('@/firebase/production_environment/db')
}

const db = firebase.db
const auth = firebase.auth

const state = {
    task: {
        id: '',
        name: '',
        source_language: '',
        status: '',
        callback_url: '',
        media_url: '',
        translate_language: '',
        type: '',
        text: '',
        translate_text: '',
        last_saved_date: null,
    },
};

const getters = {
    last_saved_date: state => {
        return state.task.last_saved_date
    }
}

const mutations = {
    setTask: (state, task) => state.task = task,
    setLastSavedTime: (state, timestamp) => state.task.last_saved_date = timestamp, 
};

const actions = {
    updateText({ dispatch, rootState, state }, text) {
        const task_id = rootState.task_id

        if (state.task.translate_language) {
            db.collection('tasks').doc(task_id).set({ translate_text: text }, { merge: true })
            .then(() => dispatch('updateLastSavedDate'))
            .catch(err => console.log(err))
        } else {
            db.collection('tasks').doc(task_id).set({ text }, { merge: true })
            .then(() => dispatch('updateLastSavedDate'))
            .catch(err => console.log(err))
        }
    },

    updateLastSavedDate({commit,rootState}){
        const task_id = rootState.task_id
        const timestamp = Date.now()
        db.collection('tasks').doc(task_id).update({last_saved_date: timestamp})
        .then(() => commit('setLastSavedTime', timestamp))
        .catch(err => console.log(err))
    },
}

const namespaced = true;

export default  {
    namespaced,
    state,
    mutations,
    actions,
    getters,
};
