<template>
<div>
  <h4 class="alert-tutorial" v-if="showAlert">Precisa ativar seu corretor ortográfico<strong class="alert-tutorial">{{current_language | specific_language}}</strong> no navegador. <a  @click="okModal">Ver tutorial</a> </h4>

  <div class="modal" :class="{'is-active': showModalFlag}">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head warning-header">
        <p class="modal-card-title">Atenção!</p>
      </header>
      <section class="modal-card-body">
        <div class="content">
          <p>Precisa ativar seu corretor ortográfico dentro do navegador.</p>
          <p>Por favor adicionar o idioma <strong>{{current_language | specific_language}}</strong>.</p>
          <p>Se deseja ver o tutorial faça click no botão "Ver tutorial".</p>
        </div>
      </section>
      <footer class="modal-card-foot warning-footer">
        <button class="button is-success card-footer-item" @click="okModal">
          <i class="fas fa-clipboard-list" style="margin-right: 10px"></i>
          Ver tutorial
          </button>
        <button class="button card-footer-item" @click="cancelModal">
          <i class="far fa-times-circle" style="margin-right: 10px"></i>
          Fechar
          </button>
      </footer>
    </div>
  </div>

  <div class="modal" :class="{'is-active': showTutorial}">
    <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
              <p class="modal-card-title">Tutorial</p>
              <button class="delete" aria-label="close" @click="closeTutorial"></button>
        </header>
        <section class="modal-card-body">
        <!-- Content ... -->
        <div class="content" v-if="current_page == 1">
          <h3>Passo 1</h3>
          <ul>
            <li>Abra uma nova aba.</li>
            <li>Clique sobre a barra de endereços do Chrome e acesse o comando: <strong>chrome://settings/languages</strong></li>
            <li>Ou, faça click nos 3 pontos no canto superior direito do navegador e acesse as Configurações > Avançado > Idiomas.</li>
          </ul>
          <p class="image ">
            <img src="./assets/passo1.png" alt="configurações">
          </p>
        </div>
        <div class="content" v-else-if="current_page == 2">
          <h3>Passo 2</h3>
          <p> A página de configurações de idioma do navegador será exibida. Clique sobre o botão da seta, no canto superior direito, para ver todas as opções. Em seguida, pressione "Adicionar Idiomas".</p>
          <p class="image">
            <img src="./assets/passo22.png" alt="config">
          </p>
        </div>
        <div class="content" v-else-if="current_page == 3">
          <h3>Passo 3</h3>
          <p> Marque a opção <strong>"{{current_language | specific_language}}"</strong> na lista e pressione "Adicionar".</p>
          <p class="image is-3by2">
            <img src="./assets/passo3.png" alt="idioma">
          </p>
        </div>
        <div class="content" v-else-if="current_page == 4">
          <h3>Passo 4</h3>
          <p> Voltando à tela anterior, ative a opção de "Verificação Ortográfica" e ative a Verificação ortográfica para o idioma que vai utilizar na revisão.</p>
          <p class="image ">
            <img src="./assets/passo4.png" alt="">
          </p>
        </div>
        <div class="content" v-else-if="current_page == 5">
          <h3>Passo 5</h3>
          <p> O corretor ortográfico deve começar a trabalhar e identificar as palavras erradas dependendo o idioma selecionado, caso não esteja ativado, só precisa atualizar a pagina.</p>
          <p class="image is-4by3">
            <img src="./assets/passo5.png"  alt="">
          </p>
        </div>
        </section>
        <footer class="modal-card-foot">
          <button :disabled="current_page == 1"
                class="button is-info card-footer-item" 
                aria-label="Previous" 
                @click="firePagination(current_page - 1)"
                > 
                <span class="icon is-small">
                <i class="fas fa-angle-left"></i>  
                </span> 
                <span>Anterior</span>
                </button>
          <button class="button is-warning is-outlined card-footer-item" @click="closeTutorial">Fechar</button>
          <button :disabled="current_page == total_pages"
                class="button is-link is-outlined card-footer-item" 
                aria-label="Next" 
                @click="firePagination(current_page + 1)"
                >
                <span>Próximo</span>
                <span class="icon is-small">
                <i class="fas fa-angle-right"></i>  
                </span> 
                </button>
        </footer>
     </div>
  </div>
</div>
</template>
<script>

export default {
    data(){
        return{
            showModalFlag: false,
            showTutorial: false,
            show_alert: false,
            current_page: 1,
            total_pages: 5,
            current_language: ""
        }
    },

    created(){
        let languages = navigator.languages
        let task_language = this.$store.state.current_language
        this.current_language = task_language
        
        let result = languages.includes(task_language)
        if(!result){
            this.showModalFlag = true
            this.show_alert = true
        }
    },

    methods: {
    showModal() {
      this.showModalFlag = true;
    },
    okModal() {
      this.showModalFlag = false;
      this.showTutorial = true;
    },
    cancelModal() {
      this.showModalFlag = false;
    },
    closeTutorial(){
      this.showTutorial = false;
      this.showModalFlag = false;
      this.current_page = 1;
    },
    firePagination(page) {
      this.detail_show_index = null
      this.current_page = page
    },
  },

  computed: {
    showAlert(){
      return this.show_alert
    }
  },
  
  filters:{
    specific_language(lang){
      let specific_lang=  ""
      if(lang == 'pt-BR'){
        return specific_lang = "Portugês (Brasil) "
      } else if(lang == 'en-US'){
        return specific_lang = "Inglês (Estados Unidos)"
      } else if(lang == 'es-ES'){
        return specific_lang = "Espanhol (Espanha)"
      }
    }
  }
}
</script>
<style lang="scss" >
.modal-card {
    width: 650px !important;
}
.modal-card-title{
  margin: auto !important;
}
.warning-header{
  background-color: white !important;
  border-bottom: transparent !important;
}
.warning-footer{
  background-color: white !important;
  border-top: transparent !important;
}
.alert-tutorial{
  color:cadetblue;
  font-size: 13px;
  margin-left: 5px;
}

</style>