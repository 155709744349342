<template>
	<div>
		<button id="finalizeRevisionButton" class="finalize-button button is-primary" @click="openCloseModal">
			Finalizar
		</button>
	</div>
</template>

<script>

export default {
	components: {
	},
	data() {
		return {
			openModal: false,
		};
	},
	computed: {
		getSubtitlesNotConfirmed() {
			return this.$store.getters['srt_divided/subtitles'].filter((subtitle) => subtitle.is_saved === false);
		},
		getTask() {
			return this.$store.getters['srt_divided/get_task'];
		},
		getReviewdByQa() {
			if (
				this.$store.getters['srt_divided/getReviewdByQa'] ||
				this.getTask.status == 'evaluating_transcription' ||
				this.getTask.status == 'evaluating_translate'
			) {
				return true;
			} else {
				return false;
			}
		},
		isAssessment() {
			return this.getTask.assessment_id ? true : false;
		},
	},
	methods: {
		async openCloseModal() {
			if (!this.verifyIfCanFinishTask()) {
				await this.$store.dispatch('controller/changeSegmentsFilter', 'all')
				this.$swal({
					text: 'Existem Segmentos que não foram salvos. Por favor verifique e tente novamente.',
					confirmButtonText: 'Mostre-me',
					confirmButtonColor: '#3085d6',
				}).then(async () => {
					await this.$store.dispatch('controller/changeSegmentsFilter', 'unsaved')
				})
				return;
			}
			this.finalizeTask();
		},
		verifyIfCanFinishTask() {
			if (this.getReviewdByQa) return true;
			if (this.getSubtitlesNotConfirmed.length > 0 && this.getTask.type != 'txt') {
				return false;
			}
			return true;
		},
		async finalizeTask(data = null) {
			const loader = this.$loading.show()
			if (this.isAssessment) {
				await this.$store.dispatch('srt_divided/finalizeAssessment', { feedback: data })
					.then(this.handleFinalizedTask)
					.catch(this.handleErrorFinalizingTask)
					.finally(loader.hide)
			} else {
				await this.$store.dispatch('srt_divided/finalizeTask', { feedback: data })
					.then(this.handleFinalizedTask)
					.catch(this.handleErrorFinalizingTask)
					.finally(loader.hide)
			}
		},
		handleFinalizedTask() {
            this.$swal(
                'Finalizada!',
                'Você finalizou a tarefa.',
                'success'
            ).then(() => {
                window.location.href = `https://review.skylar.ai/`;
            })
        },
        handleErrorFinalizingTask(err) {
            console.error(err)
        },
	},
};
</script>

<style lang="scss" scoped></style>
