<template>
  <div class="subtask-info" v-if="reviewdByQa && getJoinPoints.length > 0">
      <div class="buttons-points">
            <div class="points-area">
                <div class="points-title">
                    <span class="has-text-white-ter is-size-5">Partes divididas</span>
                </div>
                <div class="points-list">
                    <div class="points-item" v-for="(point, i) in getJoinPoints" :key="point.subtask_id">
                        <span>{{i + 1}}:</span>
                        <button class="button is-primary" @click="goToSegment(point.start, 'start')">
                            <span>Início</span>
                        </button>
                        <button class="button is-primary" @click="goToSegment(point.end, 'end')">
                            <span>Fim</span>
                        </button>
                    </div>
                </div>
            </div>
      </div>
      <div class="info-msg">
          <span>Esse job foi dividido, verifique os pontos de junção.</span>
      </div>
  </div>
</template>

<script>
export default {
    computed:{
        getJoinPoints: function () {
            return this.$store.state.join_points
        },
        reviewdByQa(){
            return this.$store.getters['getReviewdByQa']
        },
        subtitles: function () {
            return this.$store.state.srt.subtitles
        },
    },
    methods:{
        async goToSegment(time, point){
            let sub
            time = parseInt(time)
            if(point == 'start'){
                sub  = this.subtitles.filter(subtitle => {
                    return parseInt(subtitle.start) >= time
                })
            }else{
                sub  = this.subtitles.filter(subtitle => {
                    return parseInt(subtitle.end) >= time
                })
            }
             
            
            if(sub.length){     
                await this.$store.commit('controller/setCurrentSegmentIndex', sub[0].store_index)
            } 
        },
    }
}
</script>

<style lang="scss">
    .subtask-info{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        font-size: 16px;
        color: #fff;
        padding: 10px;

        .info-msg{
            width: 100%;
            height: 5%;
            display: flex;
            color: rgb(184, 194, 40);
            font-size: 12px;
        }
        
        .points-item{
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            margin-top: 5px;
        }

        .button{
            width: 70px;
            height: 30px;
            margin-left: 5px;
        }
    }

    .points-list{
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;   
        .points-item{
            margin-left: 10px;
        }     
    }
</style>