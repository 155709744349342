<template>
  <div style="display: none;"></div>
</template>

<script>
export default {
    computed: {
        is_revision_mode: function () {
            return this.$store.state.controller.revision_mode
        },
        current_video_time: function () {
            return this.$store.getters['video/getUpdateTime']
        },

        active_segment: function () {
            return this.$store.getters['srt/active_segment']
        },
        subtitles: function () {
            return this.$store.state.srt.subtitles
        },
    },
    watch: {
        current_video_time:async function (time) {
            if (!this.is_revision_mode) return
            let sub  = this.subtitles.filter(subtitle => {
                return subtitle.start < time && subtitle.end > time
            })
            
            if(sub.length){
                //await this.$store.dispatch('srt/updateSegmentBeforeChange')
                if(sub[0].store_index == this.active_segment.store_index) return 
                await this.$store.commit('controller/setCurrentSegmentIndex', sub[0].store_index)
            } 
        }
    }
}
</script>