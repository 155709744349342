<template>
  <div id="TxtEditor" style="height: 100%">
    <tinymceEditor
      v-if="showEditor"
      id="transcription-textarea-tiny"
      api-key="g47ic48j15b5ucrwg9d6vnxsv8snz1k47eigq9bk4jcube13"
      model-events="init input"
      v-model="text"
      :init="editorInitSettings"
      @onInit="onEditorInit"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

// import Hotkeys from "../assets/hotkeys";
import Editor from "@tinymce/tinymce-vue";
import getXPath from "get-xpath";

export default {
  components: {
    tinymceEditor: Editor,
  },

  data() {
    return {
      showEditor: true,
      autoSavedText: "",
      text: "",
      intervalId: -1,
      focusParentXPath: "",
      focusNodeIndex: 0,
      focusOffset: 0,
    };
  },

  watch: {
    theme(newValue, oldValue) {
      // if theme has changed
      if (newValue !== oldValue) {
        // reinitialize editor to load new theme
        this.reloadEditor();
      }
    },

    autoSavedText(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.saveContent(newValue);
      }
    },
  },

  mounted() {
    this.text = this.task_text;

    const { focusParentXPath, focusNodeIndex, focusOffset } = this.last_focus;

    this.focusParentXPath = focusParentXPath;
    this.focusNodeIndex = focusNodeIndex;
    this.focusOffset = focusOffset;

    this.intervalId = setInterval(() => {
      this.autoSavedText =
        localStorage[`autosaving_txt_task_${this.task_id}_draft`];
    }, 1000);
  },

  beforeDestroy() {
    clearInterval(this.intervalId);
  },

  methods: {
    reloadEditor() {
      this.showEditor = false;
      this.$nextTick(() => {
        this.showEditor = true;
      });
    },

    onEditorInit(event, editor) {
      // Hotkeys.initialize(self, editor.contentDocument);

      editor.on("input", this.onEditorChange);
      editor.on("change", this.onEditorChange);

      editor.focus();

      try {
        const result = editor
          .getDoc()
          .evaluate(
            this.focusParentXPath,
            editor.getDoc(),
            null,
            XPathResult.FIRST_ORDERED_NODE_TYPE,
            null
          );

        if (result && result.singleNodeValue) {
          const parentEl = result.singleNodeValue;

          parentEl.classList.add("focus-highlight");
          setTimeout(() => {
            parentEl.classList.remove("focus-highlight");
          }, 1000);

          editor.selection.setCursorLocation(
            parentEl.childNodes[this.focusNodeIndex],
            this.focusOffset
          );
        }
      } catch (error) {
        console.warn("Error restoring cursor location.", error);
      }
    },
    onEditorChange(event) {
      this.updatePosition(tinymce.activeEditor.selection);
    },

    updatePosition(editorSelection) {
      const selection = editorSelection.getSel();
      if (selection.focusNode.data) {
        const idx = Array.prototype.indexOf.call(
          selection.focusNode.parentElement.childNodes,
          selection.focusNode
        );
        this.focusParentXPath = getXPath(selection.focusNode.parentElement);
        this.focusNodeIndex = idx;
        this.focusOffset = selection.focusOffset;
      }
    },

    saveContent(content) {
      this.$store.dispatch("txt/updateText", content).catch((err) => {
        console.error("Error updating text.", err);
      });

      this.$store
        .dispatch("txt/updatePosition", {
          focusParentXPath: this.focusParentXPath,
          focusNodeIndex: this.focusNodeIndex,
          focusOffset: this.focusOffset,
        })
        .catch((err) => {
          console.error("Error updating position.", err);
        });
    },
  },

  computed: {
    ...mapState({
      task_id: "task_id",
    }),

    ...mapGetters({
      theme: "theme",
      browserLocale: "browserLocale",
      task_text: "txt/task_text",
      last_focus: "txt/last_focus",
      translate_language: "txt/translate_language",
    }),

    editorInitSettings() {
      return {
        width: "100%",
        statusbar: false,
        toolbar: "searchreplace",
        plugins: "autosave, searchreplace",
        autosave_interval: "1s",
        autosave_prefix: `autosaving_txt_task_${this.task_id}_`,
        skin: this.theme == "light" ? "" : "oxide-dark",
        content_css: this.theme == "light" ? "" : "dark",
        content_style:
          ".focus-highlight { background: #ffdd5766; transition: background 0.5s ease; } ",
        language: this.browserLocale.replace("-", "_"),
      };
    },
  },
};
</script>

<style>
#TxtEditor {
  width: 100%;
  z-index: 11;
  background-color: var(--bg-color);
}
p {
  color: var(--font-color);
}
</style>