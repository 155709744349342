import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth'
import 'firebase/analytics'


const firebase_config = {
    apiKey: "AIzaSyDFV3T2wmobRIb-QVAuCZp-EoYRc_y27tE",
    authDomain: "task-v4.firebaseapp.com",
    databaseURL: "https://task-v4.firebaseio.com",
    projectId: "task-v4",
    storageBucket: "task-v4.appspot.com",
    messagingSenderId: "594763995905",
    appId: "1:594763995905:web:09e88480e3022a82ac9900",
    measurementId: "G-YED8EFSWGK"

}

const fb_app = firebase.initializeApp(firebase_config)
fb_app.analytics()


export const db = fb_app.firestore();
export const auth = fb_app.auth();    