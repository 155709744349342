<template>
  <div>
      <button 
        @click="changePlataform" 
        class="button is-primary changePlataform">
            
            Usar novo editor
            <span class='cut right'></span>
        </button>
  </div>
</template>

<script>
export default {
    methods:{
        async changePlataform(){
            await this.$store.dispatch('updateAccess').then((token)=>{
                setTimeout(()=>{
                    if (process.env.NODE_ENV == 'development') {
                        const TASK_URL = process.env.VUE_APP_TASK_URL || `https://editor-dev-env.web.app`;
                        window.location.href = `${TASK_URL}/?id=${token}`;
                    } else {
                        window.location.href = `https://editor.skylar.ai/?id=${token}`; // Trocar quando tiver a url de produção
                    }
                },1000)
               
            })
        }
    }
}
</script>

<style lang='scss'>
.changePlataform {
    box-sizing: border-box;
    margin-left: 10px;
    height: 30px;
    &::after{
        content: 'New';
        display: flex;
        padding-left: 2px;
        background-color: red;
        color: rgb(255, 254, 254);
        transform: skewY(25deg) skewX(-25deg);
        font-size: 10px;
        width: 25px;
        height: auto;
        right: 1px;
        top:-2px;
        margin-right: -5px;
        position: absolute;
    }


  
}

</style>