<template>
    <div id="TxtEditor">
        <textarea id="transcription-textarea-tiny" v-model="text">
        </textarea>
        Salvo por útimo às: {{ last_saved_date | toDate }}
    </div>
</template>

<script>
import Hotkeys from '../../../assets/hotkeys'
export default {
    data() {
        return {
            last_saved_date: null,
            last_content: '',
        }
    },

    mounted() {
        this.last_content = this.text
        const self = this

        tinymce.init({
            selector: '#transcription-textarea-tiny',
            height: '95%',
            autosave_interval: "1s",
            plugins: 'autosave',
            autosave_prefix: `autosaving_txt_task_${this.task_id}_`,

            setup: function (editor) {
                editor.on('init', function(e) {
                    Hotkeys.initialize(self, e.target.contentDocument)
                })
            },
        })
        setInterval(() => {
            this.handleContentChecking(localStorage[`autosaving_txt_task_${this.task_id}_draft`])
        }, 3000)
    },

    methods: {
        handleContentChecking(content) {
            if (content != this.last_content) {
                this.last_content = content
                this.saved = false
                this.saveContent(content)
            }
        },

        saveContent(content) {
            this.$store.dispatch('txt/updateText', content)
            .then(() => {
                this.last_saved_date = new Date()
            })
            .catch(this.handleSaveTextError)
        },

        handleSaveTextError(e) {
            // TODO
            console.error('ERRO', e)
        }
    },

    computed: {
        text: function () {
            if (this.translate_language) {
                return this.$store.state.txt.task.translate_text
            } else {
                return this.$store.state.txt.task.text
            }
        },

        translate_language: function () {
            return this.$store.state.txt.task.translate_language
        },

        task_id: function () {
            return this.$store.state.task_id
        },
    },

    filters: {
        toDate(val) {
            if (!val) val = new Date()

            return val.toLocaleString().split(' ')[1]
        }
    }
}
</script>

<style>
#TxtEditor {
    width: 100%;
}
</style>