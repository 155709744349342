<template>
  <div v-if="task_type">
        <div id="TaskWrap" :class="custom_flex">
            <div v-if="finished" style="align-items: center; justify-content: center; display: flex; width: 100%;">
                <h1 class="is-size-1">Tarefa já finalizada! <a @click="backPage">Voltar</a></h1>
            </div>

            <!-- Left -->
            <div id="Left" class="has-background-left" v-else>
                <left-navbar />

                <edit-txt v-if="task_type == 'txt'" />
                <edit-srt v-if="task_type == 'srt'" />             

            </div>
            <!-- Right -->
            <div id="Right" class="has-background-right" v-if="!finished">
                <right-navbar />

                <video-player v-if="mount_player" />
                <JoinSubTaskInfo />
                <tool-box v-if="is_srt"/>
                <Configs />
                <div>
                    <language-detector/>
                </div>
                <div class="buttons-area">
                    <finalize-task />
                    <AutomaticReviewer v-if="is_srt" style="margin-left: 10px;"/>
                </div>
                <div style="color: white, margin-top: 50px">
                    <save-task />
                </div>
            </div>

            <Soundwave v-if="soundwaveEnabled && task_type != 'txt' && soundWaveOpen"/>
        </div>
  </div>
</template>

<script>
import LeftNavbar from '@/components/LeftNavbar'
import RightNavbar from '@/components/RightNavbar'
import VideoPlayer from '@/components/VideoPlayer'
import EditTxt from '@/components/EditTxt'
import EditSrt from '@/components/EditSrt'
import ToolBox from '@/components/EditSrt/ToolBox'
import FinalizeTask from '@/components/FinalizeTask/FinalizeTask'
import SaveTask from '@/components/SaveTask'
import LanguageDetector from '@/components/SpellCheck/LanguageDetector'
import AutomaticReviewer from '@/components/AutomaticReviewer'
import Configs from '@/components/Configs'
import JoinSubTaskInfo from '@/components/JoinSubTaskInfo/JoinSubTaskInfo'
import { mapGetters } from 'vuex'

const Soundwave = () => import(/* webpackChunkName: "soundwave" */ '@/components/Soundwave');

export default {
    components: {
        LeftNavbar,
        RightNavbar,
        VideoPlayer,
        EditTxt,
        EditSrt,
        ToolBox,
        FinalizeTask,
        SaveTask,
        LanguageDetector,
        AutomaticReviewer,
        Soundwave,
        Configs,
        JoinSubTaskInfo
    },
    data() {
        return {
            custom_flex: 'c-is-flex-row',
        }
    },
    methods: {
        backPage() {
            window.history.back()
        },
    },

    computed: {
        ...mapGetters({
            soundwaveEnabled: 'soundwaveEnabled',
            soundWaveOpen: 'soundwave/getIsOpen',
        }),
        change_layout_sides: function () {
            return this.$store.state.controller.change_layout_sides
        },

        task_type: function () {
            return this.$store.getters['getTaskType']
        },

        is_srt(){
            return this.task_type.includes("srt");
        },

        finished: function () {
            return this.$store.state.finished
        },

        mount_player: function () {
            // treats player mount to wait for subtitles on SRT types
            if (this.task_type == 'txt') {
                return true
            } else {
                return this.subtitles.length > 0
            }
        },

        subtitles: function () {
            return this.$store.state.srt.subtitles
        },
    },

    watch: {
        change_layout_sides: function () {
            if (this.custom_flex.includes('-reverse')) {
                this.custom_flex = this.custom_flex.replace('-reverse', '')
            } else {
                this.custom_flex += '-reverse'
            }
        },

        task_type: function (type) {
            if (type) {
                this.$nextTick(() => {
                    document.getElementById('Right').addEventListener('wheel', ((e)=>{ e.stopPropagation() }))
                })
            }
        }
    }
}
</script>


<style>
#TaskWrap {
  display: flex;
  min-height: 100vh;
  align-items: center;
}

#Left {
  flex: 1;
  min-height: inherit;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
}

#Right {
  display: flex;
  width: 100%;
  height: 100 vh;
  flex-direction: column;
}

.c-is-flex-row {
  flex-direction: row;
}
.c-is-flex-row-reverse {
  flex-direction: row-reverse;
}

#Left > div {
    flex: 1;
    justify-content: center;
    align-items: stretch;
    display: flex;
}
.right-options{
    display: flex;
    flex-direction: row;
}

.buttons-area{
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: row;
    padding: 10px;
}
</style>