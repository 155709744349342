<template>
    <div class="tool-button-center">
        <span class="is-size-7">
            {{ title }}
        </span>
        <div 
            class="tool-button" 
            :class="{'has-background-primary': highlight}"
            @click="action()"
        >
            <i :class="icon"></i>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: { type: String, required: true },
        icon: { type: String, required: true },
        action: { type: Function, required: true },
        highlight: { type: Boolean, required: false }
    }
}
</script>

<style>
.tool-button-center, .tool-button {
    display: flex;
    align-items: center;
    justify-content: center;
}
.tool-button-center {
    flex-direction: column;
    width: 40px;
    align-self: flex-start;
}
.tool-button {
    width: 30px;
    height: 30px;
    background: rgba(0,0,0,.5);
    padding: 2px 0;
    cursor: pointer;
    transition: all 0.1s;
}
.tool-button:hover {
    background: rgba(0,0,0,.7);
}
.tool-button:active {
    background: rgba(0,0,0,.3);
}
</style>