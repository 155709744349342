<template>
  <div class="time-input-wrapper">
    <div v-if="controls">
      <input
        type="text"
        class="is-small"
        @input="timeChanged($event.target.value)"
        :value="mili2srt(value)"
        :disabled="disabled"
        v-mask="'##:##:##,###'"
        ref="time"
      />
      <i
        class="fas fa-chevron-down fa-sm icon-hover"
        @mousedown="startIncrementTime('minus')"
        @mouseup="stopIncrementTime"
        @mouseout="stopIncrementTime"
      ></i>
      <i
        class="fas fa-chevron-up fa-sm icon-hover"
        @mousedown="startIncrementTime('plus')"
        @mouseup="stopIncrementTime"
        @mouseout="stopIncrementTime"
      ></i>
    </div>
  </div>
</template>


<script>
const { mili2srt, srt2mili } = require("@/utils/functions");
export default {
  props: {
    disabled: { type: Boolean, default: false },
    controls: { type: Boolean, default: true },
    value: { type: Number, required: true },
    start: { type: Number, required: true },
    end: { type: Number, required: true },
    modifier: { type: String, required: true },
  },

  data() {
    return {
      last_time: null,
    };
  },

  created() {
    this.last_time = this.value;
  },

  methods: {
    /**
     * Fires onchange, converts to mili and manipulate
     */
    timeChanged(time) {
        let time_mili = srt2mili(time);

        if (this.modifier == "start") {
          if (time_mili >= this.end) {
            time_mili = this.end - 100;
            this.$emit("timeChanged", time_mili);
            this.last_time = time_mili;
            return;
          }
        } else {
          if (time_mili <= this.start) {
            time_mili = this.start + 100;
            this.$emit("timeChanged", time_mili);
            this.last_time = time_mili;
            return;
          }
        }

        if (time.length < 12) return;
        if (time == this.last_time) return;

        this.$emit("timeChanged", time_mili);
        this.last_time = time;
    },
    /**
     * Fired on mousedown, starts the incrementation
     */
    startIncrementTime(type) {
      let temp_start = this.start;
      let temp_end = this.end;

      this.increment_interval = setInterval(() => {
        let time = srt2mili(this.$refs.time.value);

        /**
         * check the type (plus or minus)
         */
        if (temp_start <= temp_end) {
          if (type == "plus") {
            time++;
            if (this.modifier == "start") {
              temp_start++;
            } else {
              temp_end++;
            }
          } else {
            time--;
            if (this.modifier == "start") {
              temp_start--;
            } else {
              temp_end--;
            }
          }
        } else {
          if (this.modifier == "start") {
            if (type != "plus") {
              time--;
              temp_start--;
            }
          }
          if (this.modifier == "end") {
            if (type == "plus") {
              time++;
              temp_end++;
            }
          }
        }
        /**
         * blocks negative numbers
         */
        time = time < 0 ? 0 : time;

        /**
         * converts back to srt and set to the input
         */
        this.$refs.time.value = mili2srt(time);
      }, 1);
    },
    /**
     * Fired on mouseup, stops incrementation
     */
    stopIncrementTime() {
      clearInterval(this.increment_interval);

      /**
       * call function to run changes
       */
      this.timeChanged(this.$refs.time.value);
    },

    mili2srt,
    srt2mili,
  },
};
</script>

<style scoped>
.fas {
  padding-left: 0.4vw;
}
.is-small {
  padding-left: 0;
  padding-right: 0;
  width: 6vw;
  border-radius: 0.4vw;
  font-size: 1vw;
  flex: unset;
}

.time-input-wrapper {
  display: flex;
  flex-direction: row !important;
}
.time-input-wrapper > div:first-child {
  flex: unset;
}
.time-input-wrapper > div:nth-child(2) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
</style>