import Vue from 'vue'
import App from './App.vue'
import store from './store'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import VueTheMask from 'vue-the-mask'
import Notifications from 'vue-notification'
import VueSweetalert2 from 'vue-sweetalert2'
import VueFuse from 'vue-fuse'
import TextHighlight from 'vue-text-highlight';
import 'sweetalert2/dist/sweetalert2.min.css'
import './style/main.scss'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.config.productionTip = false

Vue.use(Loading, {
  canCancel: false,
  color: '#1D71B8',
  loader: 'spinner',
  width: 64,
  zIndex: 99999,
})

Vue.use(VueTheMask)
Vue.use(Notifications)
Vue.use(VueSweetalert2)
Vue.use(VueFuse)
Vue.component('text-highlight', TextHighlight);
new Vue({
  store,
  render: h => h(App)
}).$mount('#app')