<template>
    <div class="bookmark">
        <div class="modal-bookmark" >
            <div class="bar bar-bookmark">
                <span>Bookmarks salvos: {{actualBookmark + 1}} de {{totalBookmarks}}</span>
                <span class="closeModal" v-on:click="closeModal">X</span>
            </div>
            <div class="text">
                <input type="text" v-model="textModal">
            </div>
            <button class="buttonUp" @click="upBookmark"><i class="fas fa-arrow-alt-circle-up"></i></button>
            <button class="buttonDown" @click="downBookmark"><i class="fas fa-arrow-alt-circle-down"></i></button>
        </div>
    </div>

    
</template>

<script>
export default {
    data(){
        return {
            textModal: '',
            totalBookmarks: 0,
            actualBookmark: -1,
            bookmarkList: [],
            mousePosition: 0,
            isDown: false,
            offset: [0,0],
        }
    },
    watch:{
        getSubtitles(){
            this.bookmarkList = this.getSubtitles.filter(s=> s.is_bookmark == 'true')
        },
        bookmarkList(){
            this.totalBookmarks = this.bookmarkList.length
            if(this.totalBookmarks > 0){
                this.actualBookmark = this.totalBookmarks - 1
            }else{
                this.actualBookmark = -1
                this.textModal = "Nenhum bookmark salvo!"
            }
        },
        actualBookmark(){
            if(this.actualBookmark != -1){
                this.textModal = this.actualBookmark + 1
            }else{
                this.$store.dispatch('controller/setCurrentSegmentIndex', this.getActiveSegment.store_index)
            }
        }
    },
    computed: {
        getSubtitles(){
            if(this.$store.state.is_divided){
                return this.$store.getters['srt_divided/subtitles']
            }else{
                return this.$store.getters['srt/subtitles']
            }
           
        },
        getActiveSegment(){
            if(this.$store.state.is_divided){
                return this.$store.getters['srt_divided/active_segment']
            }else{
                return this.$store.getters['srt/active_segment']
            }
           
        }
    },
    created(){
       this.bookmarkList = this.getSubtitles.filter(s=> s.is_bookmark == 'true')

    },
    methods: {
        upBookmark(){
            if(this.bookmarkList.length > 0){
                if(this.actualBookmark > 0){
                    this.actualBookmark -= 1
                    this.$store.dispatch('controller/setCurrentSegmentIndex', this.bookmarkList[this.actualBookmark].store_index)
                }else{
                    this.$store.dispatch('controller/setCurrentSegmentIndex', this.bookmarkList[this.actualBookmark].store_index)
                }
            }
        },
        downBookmark(){
            if(this.bookmarkList.length > 0){
                if(this.actualBookmark < this.bookmarkList.length - 1){
                    this.actualBookmark += 1
                    this.$store.dispatch('controller/setCurrentSegmentIndex', this.bookmarkList[this.actualBookmark].store_index)
                }else{
                    this.$store.dispatch('controller/setCurrentSegmentIndex', this.bookmarkList[this.actualBookmark].store_index)
                }
                
            }
        },
        closeModal(){
            this.$emit('closeModal')
        }
    }
}
</script>

<style lang="scss">
@import './styles/index.scss';

</style>