<template>
  <div id="app">
    <!-- Divided Task -->
    <template v-if="is_divided">
      <divided-task />
    </template>
    
    <!-- Normal Task -->
    <template v-else>
      <index />
    </template> 

    <!-- Error Box -->
    <error-box />

  </div>
</template>

<script>
import Hotkeys from './assets/hotkeys'
import Index from './views/index.vue'
import DividedTask from './views/DividedTask'
import ErrorBox from './components/ErrorBox.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'app',

  components: {
    Index,
    ErrorBox,
    DividedTask,
  },

  data() {
    return {
      is_divided: null,
      timeOutTime: 60*10, // X segundos * Y Minutos para timeout 
      timeOut: 0
    }
  },

  computed: {
    ...mapGetters({
      theme: 'theme',
    })
  },

  watch: {
    theme(newValue, oldValue) {
      if (newValue !== oldValue) {
        document.documentElement.setAttribute('data-theme', newValue);
        localStorage.setItem('theme', newValue);
      }
    }
  },
  
  created() {
    const url_params = new URLSearchParams(window.location.search)
    const access_id = url_params.get('id')
    
    // TODO handle if id not found

    this.$store.commit('setAccessId', access_id)

    const loader = this.$loading.show()

    this.$store.dispatch("getSettings")

    this.$store.dispatch('anonymousLogin')
    .then(() => this.$store.dispatch('getAccess'))
    .then(() => {
      if (this.$store.state.is_divided)  this.$store.dispatch('getSubtask')
      else  this.$store.dispatch('getTask')
    })
    .then(() => {
      this.$store.state.is_divided ? this.is_divided = true : this.is_divided = false
      let element = null
      Hotkeys.initialize(this, element, this.is_divided)
      this.setTimeOut()
    })
    .catch(this.handleGetTaskError)
    .finally(loader.hide)



  },

  methods: {
    handleGetTaskError(e) {
      console.error(e)
      
      if (e.type == 503) { // access denied
        window.history.back()
      }
    },
    setTimeOut(){
      let taskPage = document.getElementById('app')
      this.timeOut = this.timeOutTime

      taskPage.addEventListener('click', ()=>{
        this.timeOut = this.timeOutTime
      })
      
      taskPage.addEventListener('mousemove', ()=>{
        this.timeOut = this.timeOutTime
      })

      taskPage.addEventListener('keypress', ()=>{
        this.timeOut = this.timeOutTime
      })

      setInterval(()=>{
        this.timeOut -= 1
        if(this.timeOut == 0){
          window.history.back()
        }
      }, 1000)
    }
  },
}
</script>

<style>
</style>