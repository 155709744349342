<!--
    =>LoadingScreen:

    Este componente é resposável por realizar os requests que faz a verificação de textos dos segmentos.
    A resposabilidade deste componente é exclusivamente tratar as requisições e exibir o status
    sobre este processo ao usuário e, após a realização dessa primeira etapa, enviar uma notificação
    para seu componente pai que realizara notificações adicionais à verificação de texto do segmento.

    Params:                             Função:
        showLoadingScreen                   Exibir a tela de loading
    Methods:
        finished                            Evento emitito após termino do loading
        closeLoadingScreen                  Evento emitido após usuário cancelar o loading
-->
<template>
  <div class="loading-screen">
    <span class="spinner spinner-large spinner-blue spinner-slow"></span>
    <br>
    <span>Verificando erros ortográficos: {{segmentsDone}} de {{totalSegments}}</span>
    <br>
    <span class="alert-message">A primeira revisão pode demorar um pouco...</span> 
    <br>
    <button class="button" v-on:click="closeLoadingScreen()">Cancelar</button>
  </div>
</template>

<script>
import axios from 'axios'

export default {
    data(){
        return {
            subtitles:[],
            sentence: null,
            language: null,
            segmentsDone: 0,
            totalSegments: 0,
            segmentsErrors: 0,
            cancelTokenSource: null,
            is_translate: null,
            type: 'srt'
        }
    },
    watch:{
        segmentsDone(){
             if((this.segmentsDone + this.segmentsErrors) == this.totalSegments
                && this.segmentsDone != 0){
                this.finished()
            }

            return this.segmentsDone
        },
        totalSegments(){
            return this.totalSegments
        }
    },
    beforeMount(){
        this.sendRequests()
    },
    beforeDestroy(){
        this.segmentsDone = 0
        this.totalSegments = 0
        this.segmentsErrors = 0
    },
    created(){

        if(this.$store.getters['isDivided']){
            this.type = 'srt_divided'
        }else{
            this.type = 'srt'
        }

        let is_translate = this.$store.getters[`${this.type}/is_translate`]
        if(is_translate){
            this.is_translate = true
        }else{
            this.is_translate = false
        }
    },
    methods:{
         async sendRequests(){
            
            this.subtitles = this.$store.getters[`${this.type}/subtitles`]
            if(this.is_translate){
                this.language = this.$store.getters[`${this.type}/get_task`].translate_language
            }else{
                this.language = this.$store.getters[`${this.type}/get_task`].source_language
            }
            this.totalSegments = this.subtitles.length
           
            // PT-BR: cancelTokenSource é usado para interromper as requisições caso seja cancelado pelo usuário.
            const CancelToken = axios.CancelToken
            this.cancelTokenSource = CancelToken.source()
            
            for(let i=0; i<= this.totalSegments; i++){
                if(this.subtitles[i]){
                    let text
                    if(this.is_translate){
                        text = this.subtitles[i].translate_text
                    }else{
                        text = this.subtitles[i].text
                    }
                    
                    let sentence = this.parseText(text)
                    let segmentId =  this.subtitles[i].store_index

                    var res = axios.post('https://fastfunctions-fica22pegq-uc.a.run.app/spellcheck', {
                        segment: segmentId,
                        sentence: sentence,
                        language: this.language,
                    },{
                        cancelToken: this.cancelTokenSource.token
                    }).then((res)=>{
                        if(res.status == 200){
                            this.segmentsDone += 1
                            this.$emit("segmentDone", res.data) // Envia o segmento para o componente pai 
                        }else{
                            this.segmentsErrors += 1
                        }
                    }).catch((thrown)=>{
                        this.segmentsErrors += 1
                        if(axios.isCancel(thrown)){
                            //console.log("Request canceled", thrown.message)
                        }
                    })
                }
           
            }
         
        },
        parseText(text) {
            return text.replace(/(\r\n|\n|\r)/gm, " ");
        },
        finished(){
            this.$emit('finishedLoading')
        },
        closeLoadingScreen(){
            this.cancelTokenSource.cancel()
            this.$emit('closeLoadingScreen')
        }
    },
   
}
</script>

<style lang="scss">
@import './styles/index.scss';

</style>