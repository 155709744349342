<template>
	<div v-if="tutorialStarted">
		<b-popover
			v-for="(step, idx) in steps"
			:key="idx"
			:show.sync="step.visible"
			:target="step.target"
			:placement="step.placement"
		>
			<!-- <template #title>Popover Title</template> -->
			<div class="text-dark mb-3">
				{{ step.msg }}
			</div>
			<div class="d-flex justify-content-around">
				<button
					class="btn btn-outline-secondary btn-sm border-0"
					v-show="!isFinalStep"
					@click="skipTutorial"
				>Skip</button>
				<button class="btn btn-primary btn-sm" @click="nextStep">
					<i class="fas fa-angle-double-right" v-show="!isFinalStep" />
					{{ isFinalStep ? 'finish' : 'next' }}
				</button>
			</div>
		</b-popover>
	</div>
</template>

<script>
export default {
	name: 'TutorialPopover',

	props: {
		stepsDefinitions: {
			type: Array,
			requred: true,
		},
	},

	data() {
		return {
			/** @type {Boolean} */
			tutorialStarted: false,
			tutorialCallback: null,
			/** @type {HTMLCanvasElement} */
			darkCanvas: null,
			/** @type {Number} */
			current: -1,
			/** @type {Array} */
			steps: [],
		};
	},

	computed: {
		enabledSteps() {
			return this.steps.filter((s) => !s.skip);
		},
		currentStep() {
			return this.enabledSteps[this.current];
		},
		isFinalStep() {
			return this.current === this.enabledSteps.length - 1;
		},
	},

	watch: {
		currentStep(newValue) {
			if (this.tutorialStarted) {
				this.emphasisTarget(newValue.target);
			}
		},
	},

	mounted() {
		// create steps
		this.stepsDefinitions.forEach((step) => {
			this.steps.push({
				msg: step.msg || '',
				target: step.target || '',
				placement: step.placement || 'top',
				skip: step.skip || false,
				visible: false,
			});
		});
	},

	methods: {
		createDarkCanvas() {
			// create dark canvas
			this.darkCanvas = document.createElement('canvas');
			this.darkCanvas.width = document.body.clientWidth;

			const body = document.body,
				html = document.documentElement;
			const height = Math.max(
				body.scrollHeight,
				body.offsetHeight,
				html.clientHeight,
				html.scrollHeight,
				html.offsetHeight
			);

			this.darkCanvas.height = height;
			this.darkCanvas.classList.add('darkCanvas');
			document.body.appendChild(this.darkCanvas);
		},
		drawDarkCanvas(emphasisRect) {
			// based on: https://codepen.io/loclegkxim/pen/eNeeZQ/

			const ctx = this.darkCanvas.getContext('2d');
			ctx.clearRect(0, 0, this.darkCanvas.width, this.darkCanvas.height);

			ctx.beginPath();
			ctx.moveTo(0, 0);
			ctx.lineTo(this.darkCanvas.width, 0);
			ctx.lineTo(this.darkCanvas.width, this.darkCanvas.height);
			ctx.lineTo(0, this.darkCanvas.height);
			ctx.lineTo(0, 0);
			ctx.closePath();

			if (emphasisRect) {
				//polygon1--- usually the outside polygon, must be clockwise
				const offset = 10;
				const org = {
					x: emphasisRect.x - offset,
					y: emphasisRect.y - offset,
					width: emphasisRect.width + offset,
					height: emphasisRect.height + offset * 2,
				};

				//polygon2 --- usually hole,must be counter-clockwise
				ctx.moveTo(org.x, org.y);
				ctx.lineTo(org.x, org.height + org.y);
				ctx.lineTo(org.width + org.x, org.height + org.y);
				ctx.lineTo(org.width + org.x, org.y);
				ctx.lineTo(org.x, org.y);
				ctx.closePath();
			}

			ctx.fillStyle = '#000a';
			ctx.fill();
		},
		emphasisTarget(target) {
			if (target) {
				const targetEl = document.getElementById(target);
				const rect = targetEl.getBoundingClientRect();

				this.drawDarkCanvas(rect);
			}
		},
		startTutorial() {
			return new Promise((resolve) => {
				this.current = 0;
				this.createDarkCanvas();
				this.currentStep.visible = true;
				this.tutorialCallback = resolve;
				this.tutorialStarted = true;
			});
		},
		nextStep() {
			this.currentStep.visible = false;

			if (!this.isFinalStep) {
				this.current++;
				this.currentStep.visible = true;
			} else {
				if (this.tutorialCallback) this.finishTutorial();
			}
		},
		skipTutorial() {
			this.currentStep.visible = false;
			this.finishTutorial();
		},
		finishTutorial() {
			this.tutorialStarted = false;
			this.darkCanvas.remove();
			if (this.tutorialCallback) this.tutorialCallback(false);
		},
	},
};
</script>

<style>
.darkCanvas {
	background-color: #fff3;
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 1059;
}
</style>
