const state = {
    errors: [],
    segmentWithError: false
}

const mutations = {
    addError: (state, error) => {
        state.errors.push(error)
    },
    setSegmentWithError: (state, segmentWithError) => {
        state.segmentWithError = segmentWithError
    }
}

const getters = {
    segmentWithError: state => state.segmentWithError
}

const actions = {
    setSegmentWithError: ({ commit }, error) => {
        commit('setSegmentWithError', error)
    },
}

const namespaced = true

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced,
}