<template>
    <div class="modal" :class='{"is-active": open_batch_segment_editor}'>
        <div class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Alterar tempos em lote</p>
                <button class="delete" @click="close"></button>
            </header>
            <section class="modal-card-body">
                <div class="columns is-centered">
                    <div class="column is-12">
                        <div class="field">
                            <label class="label">Primeiro segmento</label>
                            <div class="control">
                                <input 
                                    class="input"
                                    type="number"
                                    placeholder="Segmento inicial"
                                    :min="1"
                                    :max="max_index + 1"
                                    v-model.number="first_segment_index"
                                >
                                    <p class="help is-danger" v-if="invalid_first_index">Índice do primeiro segmento inválido</p>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Último segmento</label>
                            <div class="control">
                                <input 
                                    class="input"
                                    type="number"
                                    placeholder="Segmento final"
                                    v-model.number="last_segment_index"
                                    :max="max_index + 1"
                                >
                                <p class="help is-danger" v-if="invalid_last_index">Índice do último segmento inválido</p>
                                <p class="help">Deixe em branco para aplicar até o final</p>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Tempo inicial</label>
                            <div class="control">
                                <input 
                                    class="input"
                                    type="text"
                                    placeholder="HH:MM:SS.mmm"
                                    v-mask="'##:##:##,###'"
                                    v-model="time_init_srt">
                                <p class="help is-danger" v-if="invalid_time_init">Tempo inicial inválido</p>
                                <p class="help">HH:MM:SS.mmm</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer class="modal-card-foot">
                <button 
                    class="button is-primary" @click="aplyBatchSegmentTime" :disabled="!allow_to_apply">Aplicar</button>
                <button class="button" @click="close">Cancelar</button>
            </footer>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('srt_divided')

import { srt2mili } from '@/utils/functions'
export default {
    data() {
        return {
            first_segment_index: null,
            last_segment_index: null,
            time_init_srt: null,
        }
    },

    methods: {
        close() {
            this.$store.commit('controller/setOpenBatchSegmentEditor', false)
        },

        aplyBatchSegmentTime() {
            const batch_info = {
                first_index: this.first_segment_index - 1,
                last_index: this.last_segment_index - 1,
                time_init_ms: srt2mili(this.time_init_srt),
            }

            const loader = this.$loading.show()

            this.stopListeningToSubtitles()
            .then(() => this.updateBatchTimeSegments(batch_info))
            .then(() => this.startListeningToSubtitles())
            .then(this.handleSuccess)
            .catch(this.handleError)
            .finally(loader.hide)
        },

        handleSuccess() {
            this.close()
        },
        handleError(error) {
            console.error(error)
            // TODO
        },

        ...mapActions([
            'updateBatchTimeSegments',
            'stopListeningToSubtitles',
            'startListeningToSubtitles',
        ])
    },
    computed: {
        open_batch_segment_editor: function () {
            return this.$store.state.controller.open_batch_segment_editor
        },

        invalid_first_index: function () {
            if (this.first_segment_index == null) return false
            return this.first_segment_index <= 0 || this.first_segment_index > this.max_index
        },
        invalid_last_index: function () {
            if (this.last_segment_index == null || this.last_segment_index == 0) return false
            return this.last_segment_index <= this.first_segment_index || this.last_segment_index < 0 || this.last_segment_index > this.max_index + 1
        },
        invalid_time_init: function () {
            return this.time_init_srt && this.time_init_srt.length < 12
        },

        allow_to_apply: function () {
            if (this.time_init_srt == null) return false
            if (this.invalid_first_index == null) return false
            if (this.invalid_first_index) return false
            if (this.invalid_last_index) return false
            if (this.invalid_time_init) return false

            return true
        },

        time_init_mili: function () {
            if (this.time_init_srt == null) return 0
            const mili = srt2mili(this.time_init_srt)

            return mili
        },

        ...mapGetters([
            'max_index',
        ])
    }
}
</script>

<style>
.modal-card {
    width: 350px !important;
}
</style>