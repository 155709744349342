let firebase = null

if(process.env.NODE_ENV == "development"){
  firebase = require('@/firebase/test_environment/db_test')
}
else{
  firebase = require('@/firebase/production_environment/db')
}

const db = firebase.db
const auth = firebase.auth

const state = {
    isOpen: false,
    isPlay: false
}

const getters = {
    getIsOpen(state){
        return state.isOpen
    },
    getPlay(state){
        return state.isPlay
    }
}

const mutations = {
    open: (state) => state.isOpen = !state.isOpen,
    setPlay: (state, status) => state.isPlay = status
}

const actions = {
    open({commit}){
        commit('open')
    },
    setPlay({commit}, status){
        commit('setPlay', status)
    }
}

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
}