import Vue from "vue";
import Vuex from "vuex";
import TxtModule from "./modules/txt";
import ControllerModule from "./modules/controller";
import VideoModule from "./modules/video";
import SoundwaveModule from "./modules/soundwave";
import SrtModule from "./modules/srt";
import ErrorModule from "./modules/error";
import UndoRedoModule from "./modules/undoRedo";
import DividedSrtModule from "./modules/dividedTask/srt";
import DividedTxtModule from "./modules/dividedTask/txt";
import { getBrowserLocale } from "../utils/functions";

let firebase = null;

if (process.env.NODE_ENV == "development") {
  firebase = require("@/firebase/test_environment/db_test");
} else {
  firebase = require("@/firebase/production_environment/db");
}

/**
 * @type {firebase.firestore.Firestore}
 */
const db = firebase.db;
const auth = firebase.auth;

// URL PARA Teste

//  Legendagem
// http://localhost:8080/?id=00000_Tests_Subtitling

// Transcricao
// http://localhost:8080/?id=00000_Tests_Transcription

Vue.use(Vuex);
Vue.use(require("vue-shortkey"));

const myPlugin = (store) => {
  // Called when store is initialized
  store.subscribe((mutation, state) => {
    // Called after every mutation
    // Mutation comes in the format `{ type, payload }`
    if (
      mutation.type === "srt/updateSubtitle" ||
      mutation.type == "srt/addMiddleSubtitle" ||
      mutation.type == "srt/removeSubtitle"
    ) {
      store.dispatch("undo/addDoneAction", mutation);
    }
  });
};

const defaultLocale = getBrowserLocale();

const state = {
  access_id: "",
  access_denied: false,
  task_id: "",
  is_divided: false,
  subtask_id: "",
  task_type: "",
  task_name: "",
  media_url: "",
  mediapeaks_url: "",
  media_type: "",
  current_language: "",
  reviwed_by_qa: false,
  finished: false,
  requires_spellcheck: false,
  theme: window.localStorage.theme, // light or dark
  locale: defaultLocale || "pt-BR",
  vimeo_uri: "",
  vimeo_embed_link: "",
  settings: {},
  start_time: "",
  end_time: "",
  join_points: [],
};
const getters = {
  theme: (state) => state.theme,
  browserLocale: (state) => state.locale,
  getReviewdByQa: (state) => {
    return state.reviwed_by_qa;
  },
  isDivided: (state) => {
    return state.is_divided;
  },
  subTaskId: (state) => {
    return state.subtask_id;
  },
  getTaskId: (state) => {
    return state.task_id;
  },
  getTaskType: (state) => state.task_type || "",
  getMediaPeaksUrl: (state) => {
    return state.mediapeaks_url;
  },
  soundwaveEnabled: (state) => state.settings.soundwave_enabled,
  switchToEditor: (state) => state.settings.editor_switcher_enabled,
  getVimeoData: (state) => ({
    vimeo_uri: state.vimeo_uri,
    vimeo_embed_link: state.vimeo_embed_link,
  }),
  getJoinPoints: (state) => {
    return state.join_points;
  },
  getTimeWorker: (state) => {
    return { start: state.start_time, end: state.end_time };
  },
};

const mutations = {
  setTheme: (state, newTheme) => (state.theme = newTheme),
  setAccessId: (state, id) => (state.access_id = id),
  accessDenied: (state) => (state.access_denied = true),
  setTaskId: (state, id) => (state.task_id = id),
  setSubtaskId: (state, id) => {
    state.subtask_id = id;
    state.is_divided = true;
  },
  setTaskType: (state, type) => (state.task_type = type),
  setTaskName: (state, name) => (state.task_name = name),
  setTaskMediaURL: (state, media_url) => (state.media_url = media_url),
  setMediaType: (state, type) => (state.media_type = type),
  setCurrentLanguage: (state, language) => (state.current_language = language),
  setFinished: (state, finished) => (state.finished = finished),
  setReviewdByQa: (state, status) => (state.reviwed_by_qa = status),
  setSettings: (state, settings) => (state.settings = settings),
  setMediaPeaksUrl: (state, url) => (state.mediapeaks_url = url),
  setVimeoData: (state, { vimeo_uri, vimeo_embed_link }) => {
    state.vimeo_uri = vimeo_uri || "";
    state.vimeo_embed_link = vimeo_embed_link || "";
  },
  setStartTime: (state, time) => (state.start_time = time),
  setEndTime: (state, time) => (state.end_time = time),
  setJoinPoints: (state, points) => (state.join_points = points),
};
const actions = {
  getSettings: async ({ commit }) => {
    const settingsDoc = await db.doc("settings/default").get();
    if (settingsDoc.exists) {
      commit("setSettings", settingsDoc.data());
    }
  },

  getAccess: async function({ commit, state, dispatch }) {
    const access_id = state.access_id;

    const snapshot = await db
      .collection("access")
      .doc(`${access_id}`)
      .get();
    const access = snapshot.data();

    if (!access) throw { message: "Access do not exists", type: 404 };

    if (access.status != "available") {
      commit("accessDenied");
      throw { message: "Access Denied", type: 503 };
    }

    if (access.is_divided) {
      const subtask_id = access.subtask_id;
      const task_id = access.task_id;

      commit("setSubtaskId", subtask_id);
      commit("setTaskId", task_id);
      return access.is_divided;
    }

    const task_id = access.task_id;
    commit("setTaskId", task_id);
    if (access.token != "dev-token") {
      await db
        .collection("access")
        .doc(`${access_id}`)
        .update({ status: "unavailable" });
    }
  },
  updateAccess: async function({ state }) {
    const access_id = state.access_id;
    await db
      .collection("access")
      .doc(`${access_id}`)
      .update({ status: "available" })
    return access_id
  },
  getTask: async function({ commit, state, dispatch }) {
    const task_id = state.task_id;
    const snapshot = await db
      .collection("tasks")
      .doc(task_id)
      .get();
    const task = snapshot.data();
    if (!task) throw new ReferenceError("Task do not exists");

    if (task.status.includes("finished")) commit("setFinished", true);

    task.id = snapshot.id;
    task.last_saved_date = task.last_saved_date;
    // if (state.is_divided) {
    //   commit('setReviewdByQa', task.reviewed_by_qa)
    //   dispatch("getSubtask", task)
    //   return state.is_divided
    // }

    commit("setTaskType", task.type);

    let taskName = task.name;
    if (!task.name.endsWith(task.source_language))
      taskName += " - " + task.source_language;
    commit("setTaskName", taskName);

    commit("setTaskMediaURL", task.media_url);
    commit("setMediaType", task.media_type);
    commit(`${task.type}/setTask`, task);
    commit("setReviewdByQa", task.reviewed_by_qa);
    commit("setVimeoData", {
      vimeo_uri: task.vimeo_uri,
      vimeo_embed_link: task.vimeo_embed_link,
    });
    if (task.join_points) {
      commit("setJoinPoints", task.join_points);
    }

    if (task.mediapeaks_url) {
      commit("setMediaPeaksUrl", task.mediapeaks_url);
    }

    if (task.translate_language) {
      commit("setCurrentLanguage", task.translate_language);
    } else {
      commit("setCurrentLanguage", task.source_language);
    }
    if (task.type == "srt") {
      await dispatch("srt/startListeningToSubtitles");
      dispatch("srt/updateSubtitlesCount");
      commit(
        "controller/setCurrentSegmentIndex",
        task.last_segment_selected || 0
      );
    }
  },

  getSubtask: async function({ commit, state, dispatch }) {
    const { task_id, subtask_id } = state;
    const snapshot = await db
      .doc(`tasks/${task_id}/subtasks/${subtask_id}`)
      .get();
    const subtask = snapshot.data();

    if (!subtask) throw new ReferenceError("Task do not exists");

    if (subtask.status.includes("finished")) commit("setFinished", true);

    subtask.id = snapshot.id;
    subtask.last_saved_date = snapshot.last_saved_date;

    commit("setTaskType", subtask.type);

    let taskName = subtask.name;
    if (!subtask.name.endsWith(subtask.source_language))
      taskName += " - " + subtask.source_language;
    commit("setTaskName", taskName);

    commit("setTaskMediaURL", subtask.media_url);
    commit("setMediaType", subtask.media_type);
    commit(`${subtask.type}_divided/setTask`, subtask);
    commit("setReviewdByQa", subtask.reviewed_by_qa);
    commit("setVimeoData", {
      vimeo_uri: subtask.vimeo_uri,
      vimeo_embed_link: subtask.vimeo_embed_link,
    });
    commit("setStartTime", subtask.start_time);
    commit("setEndTime", subtask.end_time);

    if (subtask.mediapeaks_url) {
      commit("setMediaPeaksUrl", subtask.mediapeaks_url);
    }
    if (subtask.translate_language) {
      commit("setCurrentLanguage", subtask.translate_language);
    } else {
      commit("setCurrentLanguage", subtask.source_language);
    }
    if (subtask.type == "srt") {
      await dispatch("srt_divided/startListeningToSubtitles");
      dispatch("srt_divided/updateSubtitlesCount");
      commit(
        "controller/setCurrentSegmentIndex",
        subtask.last_segment_selected || 0
      );
    }
  },

  anonymousLogin({}) {
    return auth.signInAnonymously();
  },
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    txt: TxtModule,
    controller: ControllerModule,
    video: VideoModule,
    srt: SrtModule,
    error: ErrorModule,
    undo: UndoRedoModule,
    srt_divided: DividedSrtModule,
    txt_divided: DividedTxtModule,
    soundwave: SoundwaveModule,
  },
  plugins: [myPlugin],
});
