let firebase = null

if(process.env.NODE_ENV == "development"){
  firebase = require('@/firebase/test_environment/db_test')
}
else{
  firebase = require('@/firebase/production_environment/db')
}

const db = firebase.db
const auth = firebase.auth

const state = {
    play: null,
    replay: null,
    play_from_mili: null,
    updatedTime: null,
    current_time: 0,
    last_time_watched: 0,
    forward_backward_interval: 2000,
    changeRewindForward: null,
    duration: 0,
    video_time_change: 0,
}

const mutations = {
    setUpdateTime(state, time){
        state.updatedTime = time
    },
    setCurrentTime(state, milliseconds) {
        // if (state.current_time === milliseconds) state.current_time = milliseconds + 1;
        // else state.current_time = milliseconds
        state.current_time = milliseconds
        state.last_time_watched = milliseconds
    },
    setVideoTimeChange(state, milliseconds){
        state.video_time_change = milliseconds;
    },
    setForwardBackwardInterval(state, interval){
        state.forward_backward_interval = interval
    },
    /**
     * When called, change the 'play' value
     * so the component can watch and play the 
     * video
     */
    play(state) {
        state.play = Date.now()
    },
    replay(state){
        state.replay = Date.now()
    },
    setRewindForward(state, time){
        state.changeRewindForward = time
    },
    setDuration(state, duration) {
        state.duration = duration;
    } 
}

const getters = {
    /**
     * Gets the current time of any video
     * according to its media_type
     */
    getCurrentTime(state, {}, rootState) {
        const media_type = rootState.media_type
        return media_type ? state.current_time : 0
    },
    getUpdateTime(state){
        return state.updatedTime
    },
    getCurrentTimeByMouse(state){
        return state.mouseTimeClick 
    },
    getLastTimeWatched( state ){
        return state.last_time_watched
    },
    getPlayPress(state){
        return state.play
    },
    getReplay(state){
        return state.replay
    },
    getRewindForward(state){
        return state.changeRewindForward
    },
    getDuration(state) {
        return state.duration;
    },
    getVideoTimeChange(state) {
        return state.video_time_change
    }
}

const actions = {
    /**
     * Call toggle play
     */
    play({ state, commit }) {
        commit('play')
    },

    /**
     * Call playFromMili passing active segment time_init
     */
    replaySegment({ commit, rootGetters, rootState }){
       commit('replay')
    },

    /**
     * Call playFromMili passing -2 seconds
     */
    rewind({ state, commit }) {
        const REWIND_TIME = state.forward_backward_interval

        let updatedTime = state.updatedTime - REWIND_TIME
        if (updatedTime < 0) updatedTime = 0

        commit('setRewindForward', updatedTime)
    },
    
    /**
     * Call playFromMili passing +2 seconds
     */
    forward({ state, commit }) {
        const FORWARD_TIME = state.forward_backward_interval

        let updatedTime = state.updatedTime + FORWARD_TIME

        commit('setRewindForward', updatedTime)
    },

    /**
     * Store local time in a variable
     */
    setLocalTime({state, rootState}, mili){
        let task_id = rootState.task_id
        let last_time_watched= state.last_time_watched
        db.collection('tasks').doc(task_id).update({
            last_time_watched : mili,
        })
    },
}

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
}