export function mili2srt(mili) {
    if (mili === 0) return '00:00:00,000'
    if (mili === undefined) return ''
    const addZero = function (a) {
        if (a < 10) {
            a = "0" + a;
        }
        return a;
    };
    const addDoubleZero = function (a) {
        if (a < 100) {
            if (a < 10) {
                a = "00" + a;
            }
            else {
                a = "0" + a;
            }
        }
        return a;
    };
    const clock = addZero(Math.floor(mili / 3600000)) + ":" + addZero(Math.floor((mili % 3600000) / 60000)) + ":" + addZero(Math.floor((mili % 60000) / 1000)) + "," + addDoubleZero(Math.floor(mili % 1000));
    return clock;
}
export function srt2mili(b) {
    let tempos1 = b.split(",");
    let milis = tempos1[1];
    let tempos2 = tempos1[0].split(":");
    let horas = tempos2[0];
    let minutos = tempos2[1];
    let segundos = tempos2[2];
    b = (horas * 3600000);
    let c = (minutos * 60000);
    let d = (segundos * 1000);
    let e = (milis * 1);
    let a = b + c + d + e;
    return a;
}

export function capitalize(text) {
    return `${text.trim()[0].toUpperCase()}${text.trim().slice(1)}`
}

/**
 * Try to get browser's preferred language.
 * See: {@link https://phrase.com/blog/posts/ultimate-guide-to-vue-localization-with-vue-i18n/#Detecting_the_Users_Preferred_Locale_in_the_Browser|Detecting the User's Preferred Locale in the Browser}
 * @param {*} options Options: countryCodeOnly (indicates if wants just language country code, e.g.: pt-BR --> pt)
 * @returns Browser's preferred language or undefined.
 */
 export const getBrowserLocale = function (options = {}) {
	const defaultOptions = { countryCodeOnly: false };
	const opt = { ...defaultOptions, ...options };
	const navigatorLocale = navigator.languages !== undefined ? navigator.languages[0] : navigator.language;
	if (!navigatorLocale) {
		return undefined;
	}
	const trimmedLocale = opt.countryCodeOnly ? navigatorLocale.trim().split(/-|_/)[0] : navigatorLocale.trim();
	return trimmedLocale;
};