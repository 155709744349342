let firebase = null

if(process.env.NODE_ENV == "development"){
  firebase = require('@/firebase/test_environment/db_test')
}
else{
 firebase = require('@/firebase/production_environment/db')
}

const db = firebase.db
const auth = firebase.auth

import _ from 'lodash'
import {
    capitalize
} from '../../utils/functions'
import axios from 'axios'

const LAST_CHARS_TO_CAPITALIZE = [
    '.', '!', '?'
]
const INVALID_CHARS_TO_CAPITALIZE = [
    '"', ' ', '¿'
]
var  FINALIZE_TASK_URL, FINALIZE_ASSESSMENT_URL
if(process.env.NODE_ENV === "development"){
    // development environment
    const TASK_URL = process.env.VUE_APP_TASK_FUNCTIONS_EMULATOR_URL || 'https://us-central1-task-dev-env.cloudfunctions.net'
    FINALIZE_TASK_URL = `${TASK_URL}/finalizeTask`    
    FINALIZE_ASSESSMENT_URL = `${TASK_URL}/finalizeAssessmentTask`;
}else{
    // production environment
    FINALIZE_TASK_URL = 'https://us-central1-task-v4.cloudfunctions.net/finalizeTask'
    FINALIZE_ASSESSMENT_URL = `https://us-central1-task-v4.cloudfunctions.net/finalizeAssessmentTask`;
}

let DEBOUNCE_TIME_MS = 1000
let DEBOUNCE_TIME_CAP_MS = 10

const state = {
    task: {
        id: '',
        name: '',
        source_language: '',
        status: '',
        callback_url: '',
        media_url: '',
        translate_language: '',
        type: '',
        last_segment_selected: 0,
        last_ms_played_translate: 0,
        last_ms_played_transcription: 0,
        last_saved_date: null,
    },

    cursor_position: 0,
    subtitles: [],
    temp_subtitles: [],

    subtitles_unsubscriber: null,

    sentInitialState: false,
    received_mutations: false,
    removed_index: '',
    tagColor: ['#ff9800', '#4caf50', '#2196f3', '#9c27b0', '#ffeb3b', '#673ab7', '#3f51b5', '#f44336'],
};

const getters = {
    temp_subtitles: state => state.temp_subtitles,
    subtitle_length: state => state.subtitles.length,
    subtitles_length: state => state.subtitles.length,
    current_index: ({}, {}, rootState) => rootState.controller.current_segment_index,
    max_index: (state) => {
        return state.subtitles.length - 1
    },
    is_translate: state => !!state.task.translate_language,
    subtitles: state=> state.subtitles,
    subtitle: state => index => {
        if (state.subtitles.length == 0) {
            return {
                start: 0,
                end: 0,
                text: '',
                index: 0,
                id: Date.now(),
                is_bookmark: "false"
            }
        }
        return {
            ...state.subtitles[index],
            index,
        }
    },
    getSubtitleById: state=> id =>{
        return state.subtitles.filter(segment => segment.id == id)[0]
    },
    getReviewdByQa: (state) => {
		return state.task.reviewed_by_qa;
	},
    unsaved_subtitles: (state, getters) => {
        let unsaved_subtitles = getters.subtitles_live.filter(subtitle => subtitle.is_saved !== true)
        let i = 0
        unsaved_subtitles.forEach(subtitle => {
            subtitle.index = i
            i++
        })
        return unsaved_subtitles
    },

    saved_subtitles: (state, getters) => {
        let unsaved_subtitles = getters.subtitles_live.filter(subtitle => subtitle.is_saved === true)
        let i = 0
        unsaved_subtitles.forEach(subtitle => {
            subtitle.index = i
            i++
        })
        return unsaved_subtitles
    },

    subtitle_by_time: state => time_ms => {
        return _.find(state.subtitles, function (subtitle) {
            const start_less_equal = subtitle.start <= time_ms
            const end_more = subtitle.end > time_ms
            return start_less_equal && end_more
        })
    },

    active_segment: function (state, getters) {
        const current_segment_index = getters.current_index
        return getters.subtitle(current_segment_index)
    },
    next_segment: function (state, getters) {
        const current_segment_index = getters.current_index
        return getters.subtitle(current_segment_index + 1)
    },
    previous_segment: function (state, getters) {
        const current_segment_index = getters.current_index
        return getters.subtitle(current_segment_index - 1)
    },


    last_segment_modified: state => {
        return state.task.last_segment_selected
    },
    last_removed_segment: state => {
        return state.removed_index
    },
    cursor_position: state => {
        return state.cursor_position
    },
    subtitles_live: state => {
        return state.subtitles
    },
    last_saved_date: state => {
        return state.task.last_saved_date
    },
    get_task: state =>{
        return state.task
    },
    isLastSegmentActive: (state, getters) => (getters.current_index === getters.max_index),

    lastPlayedTime: (state, getters) => (getters.is_translate ? state.task.last_ms_played_translate : state.task.last_ms_played_transcription),
    getJoinPoints: (state) => {
		if (state.task.join_points) return state.task.join_points;
		return [];
	},
    getTagColors: (state) => {
		return state.tagColor;
	},
    getIsReviewQA: (state) => {
		if (state.task.status === 'quality_transcription' || state.task.status === 'quality_translation') {
			return true;
		} else {
			return false;
		}
	},
}

const mutations = {
    setTask: (state, task) => state.task = task,
    clear: state => {
        state.subtitles = []
        state.subtitles_unsubscriber = null
    },
    setSubtitlesUnsubscriber: (state, unsub) => state.subtitles_unsubscriber = unsub,

    addMiddleSubtitle: (state, {
        subtitle,
        index
    }) => {
        if (!subtitle) return
        state.subtitles.splice(index, 0, subtitle)
    },

    addTempSubtitle: (state, subtitle) => {
        let temp_subtitle = {
            id: subtitle.id,
            store_index: subtitle.store_index
        }
        state.temp_subtitles.push(temp_subtitle)
    },
  
    spliceTempSubtitles: state => {
    },

    clearTempSubtitles: state => state.temp_subtitles = [],

    addEndSubtitle: (state, subtitle) => {
        if (!subtitle) return
        subtitle.store_index = state.subtitles.length + 1

        state.subtitles.push(subtitle)
    },

    updateSubtitle: (state, subtitle) => {
        const index = state.subtitles.map(t => t.id).indexOf(subtitle.id)
        subtitle.store_index = state.subtitles[index].store_index        
        state.subtitles.splice(index, 1, subtitle)
    },
    removeSubtitle: (state, subtitle) => {
        const index = state.subtitles.map(t => t.id).indexOf(subtitle.id)
        state.subtitles.splice(index, 1)
    },
    undo_remove_subtitle: (state, subtitle) => {
        const index = state.subtitles.map(t => t.id).indexOf(subtitle.id)
        state.subtitles.splice(index, 1)
    },

    setSubtitles: (state, subtitles) => state.subtitles = subtitles,

    setLastSegmentModified: (state, index) => state.task.last_segment_selected = index,

    receivedMutations: (state) => state.received_mutations = true,

    cursorPositionChanged: (state, position) => state.cursor_position = position,

    setLastSavedTime: (state, timestamp) => state.task.last_saved_date = timestamp, 

    updateIndexBeforeRender: (state, index) => state.segment_index_before_render = index
};

const actions = {

    updateStoreIndexAdd({state, rootState},index){
        // if(rootState.controller.segments_filter === 'all'){
        for(let i = index; i < state.subtitles.length; i++){
            state.subtitles[i].store_index = i
            }
        // }
        // else{
        //     for(let i = index; i < state.subtitles.length; i++){
        //             state.subtitles[i].store_index += 1
        //     }
    },

    updateStoreIndexRemove({state, rootState, dispatch},index){
        if(rootState.controller.segments_filter === 'all'){
            for(let i = index; i < state.subtitles.length; i++){
                state.subtitles[i].store_index = i
            }           
        }

    },
    startListeningToSubtitles({
        commit,
        state,
        dispatch,
        rootState,
        rootGetters,
        getters
    }) {
        const task_id = rootState.task_id
        const collection_name = `tasks/${task_id}/subtitles`
        let first_snap = true // handle to resolve or do not and to populate all subtitles intead of one by one
 
        return new Promise(resolve => {
                const query = db.collection(collection_name)
                let subtitles = []

                const subtitles_unsubscriber = query.onSnapshot(snapshot => {
                    snapshot.docChanges().forEach(change => {
                        const subtitle = change.doc.data()
                        subtitle.id = change.doc.id

                        if (first_snap) {
                            subtitles.push(subtitle)
                        } else {
                            if (change.type === "added") { // adds only when user is editing
                                if (getters.current_index >= getters.max_index) {
                                    commit('addEndSubtitle', subtitle) 
                                    dispatch('updateStoreIndexAdd', subtitle.index)                                  
                                    dispatch('updateLastSegmentSelected', getters.current_index)
                                } else if (state.received_mutations) { //adds if command received from Undo/Redo
                                    commit('addMiddleSubtitle', {
                                        subtitle,
                                        index: subtitle.index
                                    })
                                    dispatch('updateStoreIndexAdd', subtitle.index)
                                    if(rootState.controller.segments_filter !== 'all'){
                                        commit('addTempSubtitle', subtitle)
                                    }
                                    commit('undo/cleanLastUndo', null, {
                                        root: true
                                    })
                                    state.received_mutations = false
                                    state.removed_index = ''
                                } else { 
                                    commit('addMiddleSubtitle', {
                                        subtitle,
                                        index: getters.current_index + 1
                                    })
                                    dispatch('updateStoreIndexAdd', subtitle.index)
                                    if(rootState.controller.segments_filter !== 'all'){
                                        commit('addTempSubtitle', subtitle)
                                    }
                                    else{
                                        dispatch('updateLastSegmentSelected', getters.current_index)
                                    }
                                }
                            }
                            if (change.type === "modified") {
                                commit('updateSubtitle', subtitle)
                            }
                            if (change.type == "removed") {
                                if (state.received_mutations) {
                                    commit('undo_remove_subtitle', subtitle)
                                    state.received_mutations = false
                                } else {
                                    subtitle.index = state.removed_index
                                    commit('removeSubtitle', subtitle)                             
                                    dispatch('updateStoreIndexRemove', subtitle.index)                                                                                   
                                }                                
                            }
                        }

                    })                    
                    if (first_snap) {
                        let i = 0
                        subtitles = subtitles.map(element => {
                            element.store_index = i
                            i++
                            return element
                        })

                        // let temp_subtitles = state.temp_subtitles
                        // if(temp_subtitles.length > 0 && rootState.controller.segments_filter === 'all'){
                        //     temp_subtitles.forEach(element => {
                        //         console.log(element)
                        //         // let elementToAdd = subtitles.find(x => x.id === element.id)
                        //         // let index = subtitles.indexOf(elementToAdd)
                        //         // subtitles.splice(index, 1)
                        //         // elementToAdd.store_index = element.store_index
                        //         // subtitles.splice(store_index, 0, elementToAdd);
                        //     })
                        //     console.log('here')
                        //     commit('clearTempSubtitles')
                        // }
                        
                        commit('setSubtitles', subtitles)
                        
                        // if(rootState.controller.segments_filter === 'unsaved'){
                        //     commit('setSubtitles', getters.unsaved_subtitles)
                        // }
                        // else if(rootState.controller.segments_filter === 'saved'){
                        //     commit('setSubtitles', getters.saved_subtitles)
                        // }
                        resolve()
                        first_snap = false
                    }
                })
                commit('setSubtitlesUnsubscriber', subtitles_unsubscriber)
            })
            .then(() => {
                if (state.sentInitialState === false) {
                    commit('undo/storeInitialState', state.subtitles, {
                        root: true
                    })
                    state.sentInitialState = !state.sentInitialState
                }
            })
    },
    stopListeningToSubtitles({
        commit,
        state
    }) {
        if (state.subtitles_unsubscriber) state.subtitles_unsubscriber()
        commit('setSubtitlesUnsubscriber', null)
    },

    async updateSubtitlesCount({state, rootState}){
        let segments_count = state.subtitles.length
        let task_id = rootState.task_id

        await db.collection('tasks').doc(task_id).update({
            count: segments_count
        })

    },

    updateLastSegmentSelected({
        commit,
        rootState
    }, index) {
        const task_id = rootState.task_id
        db.collection('tasks').doc(task_id).update({
                last_segment_selected: index,
            })
            .then(() => commit('setLastSegmentModified', index))
    },

    updateLastSavedDate({commit,rootState}){
        const task_id = rootState.task_id
        const timestamp = Date.now()
        db.collection('tasks').doc(task_id).update({last_saved_date: timestamp})
        .then(() =>{
            commit('setLastSavedTime', timestamp)
        })
        .catch(err =>{
            console.error(err)
        })
    },

    updateTimeInit({
        commit,
        getters,
        rootState,
        dispatch,
        state
    }, {
        time_ms,
        index,
        segment_id
    }) {
        const task_id = rootState.task_id
        const segment = getters.getSubtitleById(segment_id) //
        const previous_segment = getters.subtitle(segment.store_index - 1)

        db.doc(`tasks/${task_id}/subtitles/${segment.id}`).update({
                start: time_ms
            })
            .then(() => dispatch('updateLastSegmentSelected', index))
            .then(() => dispatch('updateLastSavedDate'))
            .then(() => {
                // check for previous segment overlapping
                if (index == 0) return true
                if (previous_segment.end <= time_ms) return true
                const previous_id = previous_segment.id

                return db.doc(`tasks/${task_id}/subtitles/${previous_id}`).update({
                    end: time_ms
                })
            })
            .catch(error => {
                console.error(error)
                commit('error/addError', {
                    context: 'update_time_init',
                    error_ref: error,
                    segment_ref: segment,
                    message: `Erro ao atualizar o tempo inicial do segmento`,
                }, {
                    root: true
                })
            })
    },
    updateTimeFinal({
        commit,
        getters,
        rootState,
        dispatch,
        state
    }, {
        time_ms,
        index,
        segment_id
    }) {
        const task_id = rootState.task_id
        const segment = getters.getSubtitleById(segment_id) 
        const next_segment = getters.subtitle(segment.store_index + 1)

        db.doc(`tasks/${task_id}/subtitles/${segment.id}`).update({
                end: time_ms
            })
            .then(() => dispatch('updateLastSegmentSelected', index))
            .then(() => {
                // check for next segment overlapping
                dispatch('updateLastSavedDate')
                if (index == getters.max_index) return true
                if (next_segment.start >= time_ms) return true
                const next_id = next_segment.id

                return db.doc(`tasks/${task_id}/subtitles/${next_id}`).update({
                    start: time_ms
                })
            })
            .catch(error => {
                commit('error/addError', {
                    context: 'update_time_final',
                    error_ref: error,
                    segment_ref: segment,
                    message: `Erro ao atualizar o tempo final do segmento`,
                }, {
                    root: true
                })
            })
    },

    renderSubtitlesAgain({dispatch}){
            dispatch('stopListeningToSubtitles')
            dispatch('startListeningToSubtitles')
            dispatch('updateSubtitlesCount')
    },

    saveAllSegments: _.debounce(async function ({
        rootState,
        getters
    }, array) {
        const task_id = rootState.task_id

        for(let i = 0; i < array.length; i++){
            await db.collection('tasks').doc(task_id).collection('subtitles').doc(array[i].id).update({
                is_saved: true
            })
        }

    }, DEBOUNCE_TIME_CAP_MS),

    changeSaveSegment: _.debounce(async function ({
        commit,
        rootState,
        getters
    }, id) {
        const task_id = rootState.task_id
        let segmentId
        
        id === 'current' ?  segmentId = getters.active_segment.id : segmentId = id
        
        const segment = await db.collection('tasks').doc(task_id).collection('subtitles').doc(segmentId).get()

        if(segment.data().is_saved != undefined){
            if (segment.data().is_saved == false) {
                await db.collection('tasks').doc(task_id).collection('subtitles').doc(segmentId).set({
                    is_saved: true
                }, {merge: true})
            } else {
                await db.collection('tasks').doc(task_id).collection('subtitles').doc(segmentId).set({
                    is_saved: false
                }, {merge: true})
            }

        }else{
            await db.collection('tasks').doc(task_id).collection('subtitles').doc(segmentId).set({
                is_saved: true
            }, {merge:true})
        }

        if(rootState.controller.segments_filter !== 'all'){
            this.dispatch('srt/renderSubtitlesAgain')
        }
  
    }, DEBOUNCE_TIME_CAP_MS),

    saveSegment: _.debounce(async function ({
        rootState,
        getters,
        commit
    }, id) {
        const task_id = rootState.task_id
        let segmentId
        id === 'current' ?  segmentId = getters.active_segment.id : segmentId = id
        
        const segment = await db.collection('tasks').doc(task_id).collection('subtitles').doc(segmentId).get()
        await db.collection('tasks').doc(task_id).collection('subtitles').doc(segmentId).set({
            is_saved: true
        }, {merge: true})

        if(rootState.controller.segments_filter !== 'all'){
            this.dispatch('srt/renderSubtitlesAgain')
        }

    }, DEBOUNCE_TIME_CAP_MS),

    setSavedSegmentFalse: _.debounce(async function ({
        rootState,
        getters
    }, id) {
        const task_id = rootState.task_id
        let segmentId

        id === 'current' ?  segmentId = getters.active_segment.id : segmentId = id
        
        const segment = await db.collection('tasks').doc(task_id).collection('subtitles').doc(segmentId).get()
        await db.collection('tasks').doc(task_id).collection('subtitles').doc(segmentId).set({
            is_saved: false
        }, {merge: true})
        
    }, DEBOUNCE_TIME_CAP_MS),

    bookmarkActualSegment: _.debounce(async function ({
        rootState,
        getters
    }) {
        const task_id = rootState.task_id
        const current_segment = getters.active_segment

        const is_bookmark = await db.collection('tasks').doc(task_id).collection('subtitles').doc(current_segment.id).get()

        if (is_bookmark.data().is_bookmark == "false" || is_bookmark.data().is_bookmark == undefined) {
            await db.collection('tasks').doc(task_id).collection('subtitles').doc(current_segment.id).update({
                is_bookmark: "true"
            })
        } else {
            await db.collection('tasks').doc(task_id).collection('subtitles').doc(current_segment.id).update({
                is_bookmark: "false"
            })
        }

    }, DEBOUNCE_TIME_CAP_MS),

    capitalizeNextSegment: _.debounce(function ({
        commit,
        rootState,
        getters,
    }) {
        if (getters.isLastSegmentActive) return;

        const task_id = rootState.task_id
        let segment_to_change = getters.next_segment
        let current_segment = getters.active_segment
        
        let textArea = document.getElementById('transcription')
        let text = textArea.value 

        let textAreaTranslate = document.getElementById('translation')
        let translate_text = textAreaTranslate.value
        
        let aux_subt_txt = text || null
        let aux_trans_txt = translate_text || null

        if (!getters.is_translate) {
            var i;
            for (i = 0; i < LAST_CHARS_TO_CAPITALIZE.length; i++) {
                if ((LAST_CHARS_TO_CAPITALIZE[i]) == aux_subt_txt[aux_subt_txt.length - 1]) {
                    
                    db.collection('tasks').doc(task_id).collection('subtitles').doc(segment_to_change.id).update({
                            text: capitalize(segment_to_change.text)
                        })
                        .catch(error => {   
                            commit('error/addError', {
                                context: 'update_subtitle_text',
                                error_ref: error,
                                segment_ref: current_segment,
                                message: `Erro ao capitalizar o texto da transcrição do segmento seguinte`,
                            }, {
                                root: true
                            })
                        })
                }
            }            
        }
        if (getters.is_translate) {
            var i;
            for (i = 0; i < LAST_CHARS_TO_CAPITALIZE.length; i++) {
                if ((LAST_CHARS_TO_CAPITALIZE[i]) == aux_trans_txt[aux_trans_txt.length - 1]) {

                db.collection('tasks').doc(task_id).collection('subtitles').doc(segment_to_change.id).update({
                        translate_text: capitalize(segment_to_change.translate_text)
                    })

                    .catch(error => {
                        commit('error/addError', {
                            context: 'update_translate_text',
                            error_ref: error,
                            segment_ref: current_segment,
                            message: `Erro ao capitalizar o texto da transcrição do segmento seguinte`,
                        }, {
                            root: true
                        })
                    })                
            }
            }
        }


    }, DEBOUNCE_TIME_CAP_MS),

    deleteAction: _.debounce(function ({
        dispatch,
        getters
    }
    ){
        let active_segment = getters.active_segment
        let next_segment = getters.next_segment
        let first_word = next_segment.text.substr(0, next_segment.text.indexOf(" "))

        //change times 
        let total_time_active
        let milisec_per_character_active 
        let time_active 

        if(!getters.is_translate){

            active_segment = getters.active_segment
            next_segment = getters.next_segment
            first_word = next_segment.text.substr(0, next_segment.text.indexOf(" "))

            total_time_active = active_segment.end - active_segment.start
            milisec_per_character_active = total_time_active / active_segment.text.length
            time_active = milisec_per_character_active * first_word.length;

            active_segment.end = active_segment.end + time_active
            next_segment.start = active_segment.end
        
            //change texts
            next_segment.text = next_segment.text.substr(first_word.length + 1)
            active_segment.text = active_segment.text + " " + first_word
            //
    
            dispatch('updateSegment',{subtitle_id: active_segment.id, segment_data: active_segment,})
            dispatch('updateSegment',{subtitle_id: next_segment.id, segment_data: next_segment,})     
        }else{
            active_segment = getters.active_segment
            next_segment = getters.next_segment
            first_word = next_segment.translate_text.substr(0, next_segment.translate_text.indexOf(" "))

            total_time_active = active_segment.end - active_segment.start
            milisec_per_character_active = total_time_active / active_segment.translate_text.length
            time_active = milisec_per_character_active * first_word.length;

            active_segment.end = active_segment.end + time_active
            next_segment.start = active_segment.end
        
            //change texts
            next_segment.translate_text = next_segment.translate_text.substr(first_word.length + 1)
            active_segment.translate_text = active_segment.translate_text + " " + first_word
            //
    
            dispatch('updateSegment',{subtitle_id: active_segment.id, segment_data: active_segment,})
            dispatch('updateSegment',{subtitle_id: next_segment.id, segment_data: next_segment,})   
        }
        

       

          

    }, DEBOUNCE_TIME_CAP_MS),

    async insertSymbol({
        rootState,
        getters
    }, symbol) {
        const cursor_position = getters.cursor_position
        const task_id = rootState.task_id
        const current_segment = getters.active_segment

        if (!getters.is_translate) {
            let before_text = current_segment.text.substring(0, cursor_position)
            let after_text = current_segment.text.substring(cursor_position)
            let finaltext = before_text + symbol + after_text

            await db.collection('tasks').doc(task_id).collection('subtitles').doc(current_segment.id).update({
                text: finaltext
            })
        } else {
            let before_text = current_segment.translate_text.substring(0, cursor_position)
            let after_text = current_segment.translate_text.substring(cursor_position)
            let finaltext = before_text + symbol + after_text


            await db.collection('tasks').doc(task_id).collection('subtitles').doc(current_segment.id).update({
                translate_text: finaltext
            })
        }
    },
    updateText: _.debounce( async function ({
        commit,
        rootState,
        getters,
        dispatch
    }, {
        text,
        subtitle_id,
        index
    }) {
        const task_id = rootState.task_id
        const current_segment = getters.active_segment

        text = text.replace(/^\s+/gm, "")
        text = text.trim()

        
        let final_text = ""
        let capitalize_next = false
        let has_changed = false

        for (let letter in text){
            let character

            character = text[letter]

            if(!LAST_CHARS_TO_CAPITALIZE.includes(text[letter]) 
            && !INVALID_CHARS_TO_CAPITALIZE.includes(text[letter])
            && capitalize_next)
            {  
                character = text[letter].toUpperCase()
                capitalize_next = false
            }

            if(LAST_CHARS_TO_CAPITALIZE.includes(text[letter])){
                capitalize_next = true
                has_changed = true
            }

            final_text += character
        }

        db.collection('tasks').doc(task_id).collection('subtitles').doc(subtitle_id).update({
                text: final_text
            })
            .then(() =>{ 
                dispatch('updateLastSegmentSelected', index)
                dispatch('updateLastSavedDate')
            })
            .catch(error => {
                commit('error/addError', {
                    context: 'update_subtitle_text',
                    error_ref: error,
                    segment_ref: current_segment,
                    message: `Erro ao atualizar o texto da transcrição do segmento`,
                }, {
                    root: true
                })
            })

    }, DEBOUNCE_TIME_MS),

    updateTranslateText: _.debounce(function ({
        commit,
        rootState,
        dispatch,
        getters
    }, {
        translate_text,
        subtitle_id,
        index
    }) {
        const task_id = rootState.task_id
        const current_segment = getters.active_segment

        translate_text = translate_text.replace(/^\s+/gm, "")
        translate_text = translate_text.trim()

        let final_text = ""
        let capitalize_next = false
        let has_changed = false

        for (let letter in translate_text){
            let character

            character = translate_text[letter]

            if(!LAST_CHARS_TO_CAPITALIZE.includes(translate_text[letter]) 
            && !INVALID_CHARS_TO_CAPITALIZE.includes(translate_text[letter])
            && capitalize_next)
            {  
                character = translate_text[letter].toUpperCase()
                capitalize_next = false
            }

            if(LAST_CHARS_TO_CAPITALIZE.includes(translate_text[letter])){
                capitalize_next = true
                has_changed = true
            }

            final_text += character
        }

        db.collection('tasks').doc(task_id).collection('subtitles').doc(subtitle_id).update({
                translate_text: final_text
            })
            .then(() => {
                dispatch('updateLastSegmentSelected', index)
                dispatch('updateLastSavedDate')
            })

            .catch(error => {
                commit('error/addError', {
                    context: 'update_translate_text',
                    error_ref: error,
                    segment_ref: current_segment,
                    message: `Erro ao atualizar a tradução da transcrição do segmento`,
                }, {
                    root: true
                })
            })
    }, DEBOUNCE_TIME_MS),

    async updateSegmentBeforeChange({getters, dispatch}){
        let activeSegment = getters.active_segment;

        let textArea = document.getElementById("transcription")
        let textAreaTranslate = document.getElementById("translation")

        activeSegment.text =  await dispatch(`cleanText`, textArea.value)        
        activeSegment.translate_text =  await dispatch(`cleanText`, textAreaTranslate.value)
        
        
        dispatch(`updateSegment`, {
            subtitle_id: activeSegment.id,
            segment_data: activeSegment
        })

        dispatch('updateLastSegmentSelected', getters.current_index)
        dispatch('updateLastSavedDate')
    },

    cleanText({}, text){
        text = text.replace(/( )+/gm, ' ')
        text = text.trim()
        
        let final_text = ""
        let capitalize_next = false
        let has_changed = false
        
        for(let i=0; i<text.length; i++){
            let character = text[i]
          
            if(!LAST_CHARS_TO_CAPITALIZE.includes(character) 
            && !INVALID_CHARS_TO_CAPITALIZE.includes(character)
            && capitalize_next)
            {  
                character = character.toUpperCase()
                capitalize_next = false
            }

            if(LAST_CHARS_TO_CAPITALIZE.includes(character)){
                
                if(text[i+1] == ' '){
                    capitalize_next = true
                    has_changed = true
                }else{
                    capitalize_next = false
                }
            }

            final_text += character

        }
        
        return final_text
    },

    async addSegment({
        getters,
        rootState,
        dispatch
    }) {

        var editionMode  = await dispatch('controller/checkEditionMode', null, { root: true })
        if(!editionMode){
            return
        }

        const current_segment = getters.active_segment
        const current_index = getters.current_index
        const max_index = getters.max_index
        const task_id = rootState.task_id

        let new_segment = {
            ...current_segment
        }
        new_segment.text = ' '
        new_segment.translate_text = ' '
        new_segment.start = current_segment.end
        new_segment.is_bookmark = "false"
        new_segment.is_saved = false

        if (new_segment.start > new_segment.end && new_segment.end == 0) {
            new_segment.start = 0
            new_segment.end = 5000
        }

        let id = null

        if (current_index == max_index) {
            id = `${+new_segment.id + 1000000}`
            new_segment.end += 5000
        } else {
            let next_segment = {
                ...getters.next_segment
            }
            new_segment.end = next_segment.start

            const to_add_id = (+next_segment.id - +current_segment.id) / 2
            id = `${(+current_segment.id + +to_add_id).toFixed(0)}`
        }

        delete new_segment.id

        db.collection('tasks')
            .doc(task_id)
            .collection('subtitles')
            .doc(id)
            .set(new_segment)
            .catch(error => {
                commit('error/addError', {
                    context: 'add_new_segment',
                    error_ref: error,
                    segment_ref: current_segment,
                    message: `Erro ao adicionar novo segmento`,
                }, {
                    root: true
                })
            })

    },
    async removeSegment({
        commit,
        rootState,
        getters,
        dispatch
    }, subtitle_id) {

        var editionMode  = await dispatch('controller/checkEditionMode', null, { root: true })
        if(!editionMode){
            return
        }

        subtitle_id = subtitle_id || getters.active_segment.id
        const task_id = rootState.task_id
        state.removed_index = getters.current_index
        db.collection('tasks')
            .doc(task_id)
            .collection('subtitles')
            .doc(String(subtitle_id))
            .delete()
            .catch(error => {
                commit('error/addError', {
                    context: 'delete_new_segment',
                    error_ref: error,
                    segment_ref: current_segment,
                    message: `Erro ao remover segmento`,
                }, {
                    root: true
                })
            })
    },
    updateSegment({
        rootState,
        commit
    }, {
        subtitle_id,
        segment_data
    }) {
        const task_id = rootState.task_id
        db.collection('tasks')
            .doc(task_id)
            .collection('subtitles')
            .doc(String(subtitle_id))
            .update(segment_data)
            .catch(error => {
                commit('error/addError', {
                    context: 'update_segment',
                    error_ref: error,
                    segment_ref: current_segment,
                    message: `Erro ao atualizar segmento`,
                }, {
                    root: true
                })
            })
    },
    async joinSegment({
        getters,
        commit,
        dispatch
    }) {

        var editionMode  = await dispatch('controller/checkEditionMode', null, { root: true })
        if(!editionMode){
            return
        }

        const current_segment = getters.active_segment
        const current_index = getters.current_index

        if (current_index == 0) return

        const previous_index = current_index - 1
        const previous_segment = getters.previous_segment
        const joined_segment = {
            ...current_segment
        }

        delete joined_segment.id

        joined_segment.text = (previous_segment.text || '') + ' ' + (current_segment.text || '')
        joined_segment.translate_text = (previous_segment.translate_text || '') + ' ' + (current_segment.translate_text || '')

        joined_segment.start = previous_segment.start
        joined_segment.end = current_segment.end

        joined_segment.text = joined_segment.text.trim()
        joined_segment.translate_text = joined_segment.translate_text.trim()

        dispatch('removeSegment', current_segment.id)
        dispatch('updateSegment', {
            subtitle_id: previous_segment.id,
            segment_data: joined_segment,
        })
        commit('controller/setCurrentSegmentIndex', current_index - 1, {
            root: true
        })
    },
    getSubtitleById:({state}, id)=>{
        let subtitles = state.subtitles
        for(let i=0; i<subtitles.length; i++){
            if(subtitles[i].store_index == id){
                return subtitles[i]
            }
        }
        
    },    
    updateTimeInitFromPlayer({
        dispatch,
        rootGetters,
        getters
    }) {
        const current_time = rootGetters['video/getUpdateTime']
        const current_segment = getters.active_segment

        dispatch('updateTimeInit', {
            time_ms: current_time,
            index: getters.current_index,
            segment_id: current_segment.id,
        })        
    },
    updateTimeFinalFromPlayer({
        dispatch,
        rootGetters,
        getters
    }) {
        const current_time = rootGetters['video/getUpdateTime']
        const current_segment = getters.active_segment

        dispatch('updateTimeFinal', {
            time_ms: current_time,
            index: getters.current_index,
            segment_id: current_segment.id,
        })
    },
    async updateBatchTimeSegments({
        state,
        rootState,
        getters
    }, {
        first_index,
        last_index,
        time_init_ms
    }) {
        try {
            last_index = last_index == -1 ? getters.max_index : last_index
            const task_id = rootState.task_id
            const first_segment = state.subtitles[first_index]
            const first_time_init = first_segment.start
            const delta_time = time_init_ms - first_time_init

            let batch = null
            let batch_length = 0

            for (let i = first_index; i <= last_index; i++) {
                if (!batch) batch = db.batch()
                const segment = state.subtitles[i]

                let new_start = segment.start + delta_time
                let new_end = segment.end + delta_time

                new_start = new_start < 0 ? 0 : new_start
                new_end = new_end < 0 ? 0 : new_end

                const doc_ref = db.collection('tasks').doc(task_id).collection('subtitles').doc(String(segment.id))
                batch.update(doc_ref, {
                    start: new_start,
                    end: new_end
                })
                batch_length++

                if (batch_length == 400) {
                    batch_length = 0
                    await batch.commit()
                    batch = null
                }
            }

            if (batch_length > 0) {
                await batch.commit()
            }            
            return true
        } catch (error) {
            throw new Error(error)
        }
    },
    async splitAddNewSegment({
        getters,
        rootState,
        dispatch
    }, {
        passingParams
    }) {

        var editionMode  = await dispatch('controller/checkEditionMode', null, { root: true })
        if(!editionMode){
            return
        }

        const current_segment = getters.active_segment
        const current_index = getters.current_index
        const max_index = getters.max_index
        const task_id = rootState.task_id

        const new_segment = {
            ...current_segment
        }
        new_segment.text = passingParams.trimmed_text.trim().replace('\n', ' ') || '';
        new_segment.translate_text = passingParams.trimmed_translation.trim().replace('\n', ' ') || '';
        new_segment.start = current_segment.end - passingParams.time_to_remove
        const modified_end_time = current_segment.end - passingParams.time_to_remove
        let id = null

        if (current_index == max_index) {
            id = `${+new_segment.id + 1000000}`
            new_segment.end += 5000
        } else {
            const next_segment = {
                ...getters.next_segment
            }
            new_segment.end = next_segment.start

            const to_add_id = (+next_segment.id - +current_segment.id) / 2
            id = `${(+current_segment.id + +to_add_id).toFixed(0)}`
        }

        delete new_segment.id

        dispatch('updateTimeFinal', {
            time_ms: modified_end_time,
            index: current_index,
            segment_id: current_segment.id,
        })

        db.collection('tasks')
            .doc(task_id)
            .collection('subtitles')
            .doc(id)
            .set(new_segment)
            .catch(error => {
                commit('error/addError', {
                    context: 'add_new_segment',
                    error_ref: error,
                    segment_ref: current_segment,
                    message: `Erro ao adicionar novo segmento`,
                }, {
                    root: true
                })
            })        
        
    },
    async finalizeTask({ getters }, { feedback = [] }) {
		try {
			// parentId is used only in subtasks
			const task_id = getters.get_task.id
            const system_used = 'task'
            await axios.post(FINALIZE_TASK_URL, {
                task_id,
                feedback,
                system_used
            });
			
		} catch (err) {
			console.error('[FirabaseManager]: Erro ao finalizar task.', err);
		}
	},
    async finalizeAssessment({ getters }, { feedback = null }) {
		try {
			await axios.post(`${FINALIZE_ASSESSMENT_URL}`, {
				task_id: getters.get_task.id,
				feedback,
			});
		} catch (err) {
			console.error('[FirabaseManager]: Erro ao finalizar assessment.', err);
		}
	},
    async addFeedback({getters}, feedback) {
		let docRef = db.collection('questionary').doc(`${new Date().getTime()}`);
        feedback.parentId =  null
        feedback.taskId = getters.get_task.id
		try {
			await docRef.set(feedback);
			return true;
		} catch (err) {
			return null;
		}
	},
}

const namespaced = true;

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
};