<template>
    <div class="level-left" >
        <div v-if="task_type == 'srt'">
            <p>Salvo por último as: {{last_saved_date_srt | toDate}}</p>
        </div>
        <div v-else>
            <p>Salvo por último as: {{last_saved_date_txt | toDate}}</p>
        </div>
    </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
const { mapGetters } = createNamespacedHelpers('srt_divided')
export default {
    computed:{
        last_saved_date_srt(){
            let date = this.$store.getters['srt_divided/last_saved_date']
            if (!date){ 
               let formatted = new Date()
               return formatted
            }
            let formatted = new Date(date)
            return formatted
        },

        last_saved_date_txt(){
            let date = this.$store.getters['txt/last_saved_date']
            if (!date){ 
               let formatted = new Date()
               return formatted
            }
            let formatted = new Date(date)
            return formatted
        },

        task_type(){
            let type = this.$store.state.task_type
            if(type == "srt") return 'srt'
            else return 'txt'
        },
    },

    filters: {
        toDate(val) {
            if (!val) val = new Date()
            return val.toLocaleString()
        }
    }    
}
</script>
<style scoped>
p{
    color: white;
    /* margin-top: 10rem; */
    font-size: 14px;
    position: absolute;
    bottom: 5px;
    margin-left: 5px;
}
</style>