<template>
    <div class="automatic-reviewer" v-if="reviewdByQa">
        <LoadingScreen 
            v-if="showLoadingScreen"
            @closeLoadingScreen="closeLoadingScreen"
            @segmentDone="segmentDone"
            @finishedLoading="loadingCompleted"
        />
        <button class="button is-primary"  v-on:click="openLoadingScreen">Realizar QA</button>
        
        
        <div class="modal-review" v-if="showModal && totalErrors > 0" @click="changePosition"> 
            <span class="close-modal" v-on:click="closeModal">X</span>
            <div class="bar">
                <span>Erros encontrados: {{actualError + 1}}/{{totalErrors}}</span>    
            </div>
            <div class="text" id="textViewer"></div>
            <button class="buttonUp" v-on:click="upNavigation"><i class="fas fa-arrow-alt-circle-up"></i></button>
            <button class="buttonDown" v-on:click="downNavigation"><i class="fas fa-arrow-alt-circle-down"></i></button>
            <div class="suggestion" v-if="!segmentError.verified">
                <span class="message-suggestion">Você quis dizer: </span>
                <div class="suggestion-div">
                    <span  v-for="suggestion in segmentError.suggestions" :key="suggestion" class="suggestion-item" v-on:click="changeText(suggestion)">{{suggestion}}</span>
                </div>
            </div>
            <div class="suggestion" v-else>
                <span class="message-suggestion">Segmento já corrigido</span>
            </div>
            <div class="time-suggestion"  v-if="segmentError.timeError">
                <img src="@/assets/danger.png" width="20px" height="20px"  />
                <span v-for="msgError in segmentError.timeMsg" :key="msgError"> {{msgError}}</span>
            </div>
        </div>

    </div>
 
</template>

<script>
import LoadingScreen from './components/LoadingScreen'

export default {
    components:{
        LoadingScreen
    },
    data(){
        return{
            subtitles:[],
            showModal: false,
            showLoadingScreen: false,
            firstError: true,
            errors: [],
            totalErrors: 0,
            actualError: -1,
            segmentError: {
                segmentId: -1,
                textError: 'Nenhum erro encotrado!',
                suggestions: [''], 
                verified: false,
                indice: -1,
                timeMsg: []
            },
            startCorrection: false,
            isDown: false,
            offset: [0,0],
            mousePosition: null,
            is_translate: null,
            type: 'srt'

        }
    },
    computed:{
        reviewdByQa(){
            return this.$store.getters['getReviewdByQa']
        },
        getSubtitles(){
            return this.$store.getters[`${this.type}/subtitles`]
        },
    },
    watch:{
       actualError(){
           this.showError()
       },
       segmentError(){
              this.$store.dispatch('controller/setCurrentSegmentIndex', this.segmentError.segmentId)
       }   
    },
    created(){

        if(this.$store.getters['isDivided']){
            this.type = 'srt_divided'
        }else{
            this.type = 'srt'
        }

        let is_translate = this.$store.getters[`${this.type}/is_translate`] 
        if(is_translate){
            this.is_translate = true
        }else{
            this.is_translate = false
        }


    },
    methods:{
        //Windowns controllers
        openModal(){
            this.showModal = true 
        },
        closeModal(){
            this.showModal = false
            this.actualError = -1
            this.errors = []
            this.startCorrection = false
        },
        openLoadingScreen(){
            this.actualError = -1
            this.totalErrors = 0
            this.showLoadingScreen = true
            this.errors = []
        },
        closeLoadingScreen(){
            this.showLoadingScreen = false
        },
        loadingCompleted(){
            this.closeLoadingScreen()

            if(this.totalErrors == 0){
                alert('Não foram encontrados erros! =)')
                return
            }
            
            // Ordena com base no indice criado. Isso serve para manter a ordem dos erros no mesmo segmento.
            this.errors.sort(function (a, b) {
                return (a.indice > b.indice) ? 1 : ((b.indice > a.indice) ? -1 : 0);
            });
            // Ordena com base no id do segmento com erro
            this.errors.sort(function (a, b) {
                return (a.segmentId > b.segmentId) ? 1 : ((b.segmentId > a.segmentId) ? -1 : 0);
            });

            // Abre o modal do corretor e seta o primeiro erro
            this.openModal()
            this.actualError = 0
        },
        // Segment controller
        segmentDone(segment){
            segment.timeError = false
            segment.textError = false
            segment.timeMsg = []
           
            // Converte segment para o segmento no store 
            let realSegment = this.getRealSegment(segment)
            if(realSegment == null) return

            // Verificação de tempo do segmento
            this.verifySegmentTime(realSegment, segment)

            // Verifica se existe erro ortográfico
            this.verifySegmentText(realSegment, segment)

            // Adiciona a lista de erros 
            if((segment.timeError == true || segment.textError == true) && Object.keys(segment.suggestions).length > 0){

                Object.keys(segment.suggestions).forEach((item)=>{
                    let newError = {segmentId: null, textError: null, suggestions: null, verified: false, indice: null, timeMsg: []}
                    newError.timeError = segment.timeError
                    newError.timeMsg = segment.timeMsg
                    newError.segmentId = segment.segment
                    newError.textError = item
                    newError.suggestions = segment.suggestions[item]
                    newError.indice = this.setIndice(newError, realSegment)
                    this.errors.push(newError)
                    this.totalErrors += 1
                });
            }else if(segment.timeError == true){
                //console.log("err")
                let newError = {segmentId: null, textError: null, suggestions: null, verified: false, indice: null, timeMsg: []}
                newError.timeError = segment.timeError
                newError.timeMsg = segment.timeMsg
                newError.segmentId = segment.segment
                newError.textError = ''
                newError.suggestions = []
                newError.indice =1
                this.errors.push(newError)
                this.totalErrors += 1
            }
        },
        getRealSegment(segment){
            var realSegment = null
            for(let i=0; i<this.getSubtitles.length;i++){
                let subtitle = this.getSubtitles[i]
                if(segment.segment == subtitle.store_index){
                    realSegment = subtitle
                }
            }
            return realSegment
        },
        verifySegmentTime(realSegment, segment){

            let beforeSegment
            let afterSegment    
            let actualSegmentId = realSegment.store_index
            let maxIndex = this.getSubtitles.length

            if(actualSegmentId == 0){
                beforeSegment = null
                afterSegment = this.getRealSegment({segment: actualSegmentId + 1})
            }else if(actualSegmentId == maxIndex - 1){
                beforeSegment = this.getRealSegment({segment:actualSegmentId - 1})
                afterSegment = null
            }else{
                beforeSegment = this.getRealSegment({segment:actualSegmentId - 1})
                afterSegment = this.getRealSegment({segment:actualSegmentId + 1})
            }
            
            // Verifica se o tempo incial é maior que o tempo final do mesmo segmento
            if(realSegment.start > realSegment.end){
                segment.timeError = true
                segment.timeMsg.push("O tempo inicial é maior que o tempo final do segmento!")
            }

            // Verifica se o tempo inicial é menor que o tempo final da legenda anterior
            if(beforeSegment != null){
                if(realSegment.star < beforeSegment.end){
                    segment.timeMsg.push("O tempo inicial é menor que o tempo final do último segmento!")
                    segment.timeError = true
                }
            }

            // Verifica se o tempo final é maior que o tempo inicial do segmento posterior
             if(afterSegment != null){
                if(realSegment.end > afterSegment.start){
                    segment.timeMsg.push("O tempo final é maior que o tempo incial do próximo segmento!")
                    segment.timeError = true
                }
            }
        },
        verifySegmentText(realSegment, segment){
            if(Object.keys(segment.suggestions).length != 0 && segment != undefined){
                segment.textError = true
            }
            
            // Realiza a correção de multiplos espaços
            if(this.is_translate){
                realSegment.translate_text = realSegment.translate_text.replace(/( ){2,}/g, '$1');
            }else{
                realSegment.text = realSegment.text.replace(/( ){2,}/g, '$1');
            }
            
            this.$store.dispatch(`${this.type}/updateSegment`, {
                subtitle_id: realSegment.id,
                segment_data: realSegment,
            })
            
        },
        setIndice(newError, realSegment){

            let fullText 
            let indice
            if(this.is_translate){
                fullText = realSegment.translate_text.split(' ')
                indice = fullText.indexOf(newError.textError)
            }else{
                fullText = realSegment.text.split(' ')
                indice = fullText.indexOf(newError.textError)
            }
           
            return indice
        },
        // Navigation controller
        upNavigation(){
            if(this.actualError > 0){
                 this.actualError -= 1
            }
        }, 
        downNavigation(){
            if(this.actualError < this.errors.length - 1){
                   this.actualError += 1
            }
        },
        showError(){
            if(this.actualError == -1) return
            if(this.segmentError == undefined) return

            this.segmentError =  this.errors[this.actualError]
            let realSegment = this.getRealSegment({segment: this.segmentError.segmentId})
            let textToFind = this.segmentError.textError

            let text
            if(this.is_translate){
                text = realSegment.translate_text
            }else{
                text = realSegment.text
            }

            text = text.replace(/(\r\n|\n|\r)/gm, " ");
            text = text.split(" ")

            let finalText = []
            for(let i=0; i<text.length; i++){
                let txt = text[i]
                if(textToFind == txt){
                    finalText.push("<mark>"+textToFind+"</mark>")
                }else{
                    finalText.push(text[i])
                }
            }
            finalText = finalText.join(" ")

            setTimeout(()=>{
                var textView = document.getElementById('textViewer')
                textView.innerHTML  = finalText
            }, 50)
        },
        changeText(suggestion){
            let realSegment = this.getRealSegment({segment: this.segmentError.segmentId})
 
            let fullText
            if(this.is_translate){
                fullText = realSegment.translate_text.replace(/(\r\n|\n|\r)/gm, " ");
            }else{
                fullText = realSegment.text.replace(/(\r\n|\n|\r)/gm, " ");
            }
            

            fullText = fullText.split(' ')
            fullText[this.segmentError.indice] = suggestion
            fullText = fullText.join(' ')
            
            if(this.is_translate){
                realSegment.translate_text = fullText
            }else{
                realSegment.text = fullText
            }

            this.$store.dispatch(`${this.type}/updateSegment`, {
                subtitle_id: realSegment.id,
                segment_data: realSegment,
            })

            this.segmentError.textError = suggestion
            this.segmentError.verified = true
            this.downNavigation()
            //this.showError()
        },
        // Modal popup events
        changePosition(){
            var div = document.getElementsByClassName("modal-review")[0]
            var bar = document.getElementsByClassName('bar')[0]
            
           

            bar.addEventListener('mousedown', function(e) {
                this.isDown = true;
                this.offset = [
                    div.offsetLeft - e.clientX,
                    div.offsetTop - e.clientY
                ];
            }, true);

            bar.addEventListener('mouseup', function() {
                this.isDown = false;
            }, true);

            bar.addEventListener('mouseleave', function() {
                this.isDown = false;
            }, true);

            bar.addEventListener('mousemove', function(event) {
                event.preventDefault();
                if (this.isDown) {
                    this.mousePosition = {

                        x : event.clientX,
                        y : event.clientY

                    };
                    div.style.left = (this.mousePosition.x + this.offset[0]) + 'px';
                    div.style.top  = (this.mousePosition.y + this.offset[1]) + 'px';
                }
            }, true);

        }  
    },
}
</script>

<style lang="scss">
@import './styles/index.scss';

</style>