<template>
    <div class="header">
      <div style="margin-right:3%;">Seg.</div>
      <div style="margin-right:11%;">Tempo</div>
      <div style="margin-right:31%;">Transcrição</div>
      <div>Tradução</div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
  .header {
    position: fixed;
    display: flex;
    width: 66%;
    padding-left: 5px;
    height: 24px;
    z-index: 99;
    color: white;
    background-color: #526079;
  }

</style>