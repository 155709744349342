<template>
  <div id="container-editSrt" @scroll="scrollChange">
    <!-- Header -->
    <Header />
    <GlassEditor @changeScrollHeigth="setScrollHeigth" />
   
    <div class="fakeScroll"></div>
      
  </div>
</template>


<script>
import { createNamespacedHelpers } from "vuex";
import { mapState } from "vuex";
const { mapGetters } = createNamespacedHelpers("srt_divided");

import _ from 'lodash'

import GlassEditor from './GlassEditor'
import Header from './Header'

export default {
  components: {
    Header,
    GlassEditor,  
  },
  data(){
    return{
      lastMaxIndex: 0,
      maxHeigth: 0,
      lastScrollTop: 0,
      subtitle_active: []
    }
  },

  mounted(){
        const toggleSwitch = document.querySelector('input[type="checkbox"]');
        const switchTheme = e => {
            if (e.target.checked) {
                document.documentElement.setAttribute('data-theme', 'dark');
                localStorage.setItem('theme', 'dark'); //add this
            }
            else {
                document.documentElement.setAttribute('data-theme', 'light');
                localStorage.setItem('theme', 'light'); //add this
            }   
        }
        toggleSwitch.addEventListener('change', switchTheme, false);
        const currentTheme = localStorage.getItem('theme') ? localStorage.getItem('theme') : null;
        if (currentTheme) {
            document.documentElement.setAttribute('data-theme', currentTheme);
            if (currentTheme === 'dark') {
                toggleSwitch.checked = true;
            }
        }        
  },
  created(){

    // Seta a altura do scrollFake de acordo com a quantidade de segmentos
    setTimeout(()=>{
      this.setScrollHeigth()
    }, 1000)
  },
  methods: {
    changeGoTo(){
      if(document.getElementById("go-to-button").innerHTML == `<i class="fa fa-angle-double-up" aria-hidden="true"></i>`){ 
      document.getElementById("go-to").style.visibility = "hidden";
      document.getElementById("go-to-button").style.marginTop = "1vw";
      document.getElementById("go-to-button").innerHTML = `<i class="fa fa-angle-double-down" aria-hidden="true">`;
      }
      else{
        document.getElementById("go-to").style.visibility = "visible";
        document.getElementById("go-to-button").style.marginTop = "4.2vw";
        document.getElementById("go-to-button").innerHTML = `<i class="fa fa-angle-double-up" aria-hidden="true"></i>`;
        document.getElementById("go-to-input-box").focus();
      }
    },
    goToIndex() {
        var index = document.getElementById("go-to-input-box").value;
        var scrollFake = document.getElementById('container-editSrt')
        var elementHeigth = document.getElementsByClassName('fakeItem')
        elementHeigth = elementHeigth[0].offsetHeight
        scrollFake.scrollTop = 0

        if (index >= 1 && index <= this.max_index + 1) {
          this.$store.commit("controller/setCurrentSegmentIndex", index - 1);
          document.getElementById("go-to-input-box").value = "";
          scrollFake.scrollTop = this.current_index * elementHeigth     
        } else {
          document.getElementById("go-to-input-box").value = "";
        }
    }, 
    scrollChange(event){

      var currentScrollTop = -1
      var scrollFake = document.getElementById('container-editSrt')
      let elementHeigthGlass = document.getElementsByClassName('selectItem')
      var elementHeigth = document.getElementsByClassName('fakeItem')
      
      //if(elementHeigth != undefined || elementHeigthGlass != undefined || scrollFake != undefined) return 
      elementHeigthGlass = elementHeigthGlass[0].offsetHeight
      elementHeigth = elementHeigth[0].offsetHeight
      currentScrollTop = scrollFake.scrollTop 
        

      let newCurrentIndex
      if(this.segments_filter == "all"){
        newCurrentIndex = Math.round(currentScrollTop / elementHeigth)
        if(newCurrentIndex == undefined) return
        if(newCurrentIndex > this.max_index) newCurrentIndex = this.max_index
        this.$store.dispatch('controller/setCurrentSegmentIndex', newCurrentIndex)    
      }else{
        newCurrentIndex = Math.round(currentScrollTop / elementHeigth)
        if(newCurrentIndex == undefined) return
        if(newCurrentIndex > this.subtitle_active.length-1) newCurrentIndex = this.subtitle_active[(this.subtitle_active.length-1)].store_index
        this.$store.dispatch('controller/setCurrentSegmentIndex', this.subtitle_active[newCurrentIndex].store_index)
      }
        
  
    },
    setScrollHeigth(subtitle_active = []){

      this.subtitle_active = subtitle_active

      let scrollFake = document.getElementsByClassName('fakeScroll')
      let elementHeigthGlass = document.getElementsByClassName('selectItem')
      let elementHeigth = document.getElementsByClassName('fakeItem')

      if(elementHeigthGlass[0] == undefined || elementHeigth[0] == undefined) return
      elementHeigthGlass = elementHeigthGlass[0].offsetHeight
      elementHeigth = elementHeigth[0].offsetHeight

      let maxHeigth = 0
      if(this.segments_filter == 'all'){
        maxHeigth = (this.max_index * elementHeigth) + (elementHeigthGlass*5)
        scrollFake[0].style.height = `${maxHeigth}px`
      }else{
        maxHeigth = (subtitle_active.length * elementHeigth) + (elementHeigthGlass*5)
        scrollFake[0].style.height = `${maxHeigth}px`
      }     
      
    }
  }, 
  watch:{    
    max_index(){
      this.setScrollHeigth()
    },    
  },
  computed: {
    segments_filter(){      
      return this.$store.state.controller.segments_filter
    },
    atual_segments(){
      return this.$store.state.controller.atual_segments
    }, 
    ...mapGetters([
      "current_index",
      "subtitles_length",
      "max_index",
      "subtitle_length"
    ])
  }
};
</script>

<style lang="scss">

:root {
    --primary-color: #302AE6;
    --secondary-color: #536390;
    --button-collor: #4d5a71;
    --sec-bg-color: rgb(192, 192, 192);
    --font-color: #424242;
    --bg-color: rgb(230, 230, 230);
    --heading-color: #292922;
}
[data-theme="dark"] {
    --primary-color: #21205a;
    --secondary-color: #818cab;
    --font-color: #e1e1ff;
    --bg-color: #1f1f1f;
    --sec-bg-color: #666;
    --heading-color: #818cab;
}

#container-editSrt {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
  .fakeScroll{
    width: 0%;
    height: 0px;
    background:none
  } 
  
}

::-webkit-scrollbar {
width: 15px;
height: 15px;
}
::-webkit-scrollbar-track-piece  {
background-color: var(--bg-color);
}
::-webkit-scrollbar-thumb:vertical {
height: 30px;
background-color: var(--sec-bg-color);
}






</style>