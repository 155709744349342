<template>
    <div
     v-if="show" 
     class="alert-mode fade-in">
        <span>{{textAlert}}</span>
        <i class="fas fa-exclamation-triangle"></i>
    </div>
</template>

<script>

export default {
    data(){
        return{
            show: false,
            timeAlert: 5000,
            textAlert: ''
        }
    },   
    watch:{
        alert(){  
            this.textAlert = 'Altere para "Todos Segmentos" para realizar edições!'          
            this.show = true
            setTimeout(()=>{
                this.show = false               
            }, this.timeAlert)   
        }
    },
    computed:{
        alert(){      
            return this.$store.getters['controller/getAlert']
        },
    }, 
}
</script>

<style lang="scss">


.alert-mode{
  position: fixed;
  display: flex;
  text-align: left;
  justify-content: center;
  align-items: center;
  bottom: 5%;
  right: 2%;
  height: 10%;
  width: 400px;
  padding: 10px;
  background-color: #ff9800;
  border-radius: 10px;
  color: #ffff;
  i{
    transform: scale(1.5);
    margin-right: 3%;
  }
}

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
}
}

</style>