const state = {
    change_layout_sides: false,
    
    current_segment_index: 0,

    revision_mode: false,

    open_batch_segment_editor: false,

    segments_filter: 'all',

    alert: 0,

    atual_segments: [],

    shortCutes: {
        navigateUp: {
            indice: 1,
            atl: 'arrowup',
            code: 38,
            title: 'Retroceder segmento: ',
            page:1
        },
        navigateDown: {
            indice: 2,
            atl: 'arrowdown',
            code: 40,
            title: 'Avançar segmento: ',
            page:1
        },
        playPause: {
            indice: 3,
            atl: 'p',
            code: 80,
            title: 'Play/Pause do vídeo: ',
            page:1
        },
        setCurrentTime: {
            indice: 4,
            atl: 'r',
            code: 82,
            title: 'Sincronizar vídeo no tempo inicial do segmento: ',
            page:1
        },
        rewind: {
            indice: 5,
            atl: ',',
            code: 188,
            title: 'Voltar X segundos no segmento: ',
            page:1
        },
        forward: {
            indice: 6,
            atl: '.',
            code: 190,
            title: 'Avançar X segundos no segmento: ',
            page:1
        },
        newSegment: {
            indice: 7,
            atl: 'b',
            code: 66,
            title: 'Criar novo segmento a partir do segmento selecionado: ',
            page:2
        },
        joinSegment: {
            indice: 8,
            atl: 'n',
            code: 78,
            title: 'Unir segmento atual com segmento anterior: ',
            page:2
        },
        setInit: {
            indice: 9,
            atl: 'j',
            code: 74,
            title: 'Adicionar tempo do vídeo como tempo inicial do segmento selecionado: ',
            page:2
        },
        setFinal: {
            indice: 10,
            atl: 'k',
            code: 75,
            title: 'Adicionar tempo do vídeo como tempo final do segmento selecionado: ',
            page:2
        },
        revisionMode: {
            indice: 11,
            atl: 'i',
            code: 73,
            title: 'Ativar modo revisão: ',
            page:3
        }
    },
    
};

const getters = {
    getSegmentsFilter: state => state.segments_filter,
    getAtualSegments: state => state.atual_segments,
    getAlert : state => state.alert,
    getCurrentSegmentIndex: state => state.current_segment_index,
    getRevisionMode: state=> state.revision_mode,
    getShortCutes: state => state.shortCutes
}

const mutations = {
    changeLayoutSide: state => state.change_layout_sides = !state.change_layout_sides,

    setCurrentSegmentIndex: (state, index) =>{
        return state.current_segment_index = index
    } ,
    
    toggleRevisionMode: state => state.revision_mode = !state.revision_mode,

    setOpenBatchSegmentEditor: (state, open) => state.open_batch_segment_editor = open,

    changeSegmentsFilter: (state, filter) => state.segments_filter = filter,

    setAtualSegments: (state, list) => state.atual_segments = list,

    setAlert: (state) => state.alert += 1,

    setShortCutes: (state, newShortCutes) => state.shortCutes = newShortCutes,
};

const actions = {
    
    setCurrentSegmentIndex({commit, rootGetters, dispatch}, index){
        commit('setCurrentSegmentIndex', index)
    },

    changeSegmentsFilter({commit, rootGetters}, new_filter){
        commit('changeSegmentsFilter', new_filter)        
    },

    setIndexBeforeRender({ commit }, index){
        commit('setIndexBeforeRender', index)
    },
    
    navigateUp({ state, commit, rootState, getters, dispatch }) {
        if(!rootState.txt.task.text){    
            let subtitles_length;

            if (rootState.task_type == 'txt') return
            if(rootState.is_divided){
                subtitles_length = rootState.srt_divided.subtitles.length-1
                if(state.current_segment_index > 0){

                    if(state.segments_filter === 'unsaved'){
                        let unsaved_subtitles = rootState.srt_divided.subtitles.filter(e => 
                            (e.is_saved === false || e.is_saved == undefined || e.is_saved == 'false') && e.store_index < state.current_segment_index )
                        if(unsaved_subtitles.length > 0){
                            commit('setCurrentSegmentIndex', unsaved_subtitles[unsaved_subtitles.length - 1].store_index)
                            commit('setAtualSegments', unsaved_subtitles)
                            dispatch('setScrollEditSrt', 'up')
                        }        
                    }
                    else if(state.segments_filter === 'saved'){
                        let saved_subtitles = rootState.srt_divided.subtitles.filter(e => 
                            (e.is_saved === true || e.is_saved === "true") && e.store_index < state.current_segment_index )                        
                        if(saved_subtitles.length > 0){
                            commit('setCurrentSegmentIndex', saved_subtitles[saved_subtitles.length - 1].store_index)
                            commit('setAtualSegments', saved_subtitles)
                            dispatch('setScrollEditSrt', 'up')
                        }
                    }
                    else{
                        commit('setCurrentSegmentIndex', getters.getCurrentSegmentIndex - 1)
                        dispatch('setScrollEditSrt', 'up')
                    }

                }
            }else{

                subtitles_length = rootState.srt.subtitles.length - 1
                if(state.current_segment_index > 0){    
                    if(state.segments_filter === 'unsaved'){
                        let unsaved_subtitles = rootState.srt.subtitles.filter(e => 
                            (e.is_saved === false || e.is_saved == undefined || e.is_saved == 'false') && e.store_index < state.current_segment_index )
                        if(unsaved_subtitles.length > 0){
                            commit('setCurrentSegmentIndex', unsaved_subtitles[unsaved_subtitles.length - 1].store_index)
                            commit('setAtualSegments', unsaved_subtitles)
                            dispatch('setScrollEditSrt', 'up')
                        }        
                    }
                    else if(state.segments_filter === 'saved'){
                        let saved_subtitles = rootState.srt.subtitles.filter(e => 
                            (e.is_saved === true || e.is_saved === "true") && e.store_index < state.current_segment_index )                        
                        if(saved_subtitles.length > 0){
                            commit('setCurrentSegmentIndex', saved_subtitles[saved_subtitles.length - 1].store_index)
                            commit('setAtualSegments', saved_subtitles)
                            dispatch('setScrollEditSrt', 'up')
                        }
                    }
                    else{
                        commit('setCurrentSegmentIndex', getters.getCurrentSegmentIndex - 1)
                        dispatch('setScrollEditSrt', 'up')
                    }
                    
                }

            }
        }
    },
    navigateDown({ state, commit, rootState, getters, dispatch}) {
        if(!rootState.txt.task.text){        
            let subtitles_length;
            
            if (rootState.task_type == 'txt') return
            if (rootState.is_divided) {
                subtitles_length = rootState.srt_divided.subtitles.length - 1
                if (state.current_segment_index < subtitles_length) {
                    if(state.segments_filter === 'unsaved'){
                        let unsaved_subtitles = rootState.srt_divided.subtitles.filter(e => 
                            (e.is_saved === false || e.is_saved == undefined || e.is_saved == 'false') && e.store_index > state.current_segment_index )
                        commit('setCurrentSegmentIndex', unsaved_subtitles[0].store_index)
                        commit('setAtualSegments', unsaved_subtitles)
                        dispatch('setScrollEditSrt', 'down')
                    }
                    else if(state.segments_filter === 'saved'){
                        let saved_subtitles = rootState.srt_divided.subtitles.filter(e => 
                            (e.is_saved === true || e.is_saved === "true") && e.store_index > state.current_segment_index )
                        
                        if(saved_subtitles.length > 0){
                            commit('setCurrentSegmentIndex', saved_subtitles[0].store_index)
                            commit('setAtualSegments', saved_subtitles)
                            dispatch('setScrollEditSrt', 'down')
                        }  
                    }
                    else{
                        commit('setCurrentSegmentIndex', getters.getCurrentSegmentIndex + 1)
                        dispatch('setScrollEditSrt', 'down')
                    }
                }
            } else {
                subtitles_length = rootState.srt.subtitles.length - 1
                if (state.current_segment_index < subtitles_length) {
                    if(state.segments_filter === 'unsaved'){
                        let unsaved_subtitles = rootState.srt.subtitles.filter(e => 
                            (e.is_saved === false || e.is_saved == undefined || e.is_saved == 'false') && e.store_index > state.current_segment_index )
                        commit('setCurrentSegmentIndex', unsaved_subtitles[0].store_index)
                        commit('setAtualSegments', unsaved_subtitles)
                        dispatch('setScrollEditSrt', 'down')
                    }
                    else if(state.segments_filter === 'saved'){
                        let saved_subtitles = rootState.srt.subtitles.filter(e => 
                            (e.is_saved === true || e.is_saved === "true") && e.store_index > state.current_segment_index )

                        if(saved_subtitles.length > 0){
                            commit('setCurrentSegmentIndex', saved_subtitles[0].store_index)
                            commit('setAtualSegments', saved_subtitles)
                            dispatch('setScrollEditSrt', 'down')
                        }    
                    }
                    else{
                        commit('setCurrentSegmentIndex', getters.getCurrentSegmentIndex + 1)
                        dispatch('setScrollEditSrt', 'down')
                    }
                    
                    
                }
            }       
        }
        
    },
    setScrollEditSrt({state}, direction){
        let scrollFake = document.getElementById('container-editSrt')
        let elementHeigth = document.getElementsByClassName('fakeItem')
        elementHeigth = elementHeigth[0].offsetHeight

        if(state.segments_filter == "all"){
            scrollFake.scrollTop = state.current_segment_index * elementHeigth
        }else{            
            for(let i=0; i< state.atual_segments.length; i++){                
                if(state.current_segment_index == state.atual_segments[i].store_index){
                    if(direction == 'down'){
                        scrollFake.scrollTop = scrollFake.scrollTop + elementHeigth
                    }else{
                        scrollFake.scrollTop = scrollFake.scrollTop - elementHeigth
                    }
                    
                }
            }
        }
    },
    checkEditionMode({commit, state}){
        if(state.segments_filter == 'saved' || state.segments_filter == 'unsaved'){
            commit('setAlert')
            return false
        }
        return true
    },
    setShortCutes({commit}, newShortCutes){
        commit('setShortCutes', newShortCutes)
    },
    resetShortCutes({commit}){
        commit('setShortCutes', {
            navigateUp: {
                indice: 1,
                atl: 'arrowup',
                code: 38,
                title: 'Retroceder segmento: ',
                page:1
            },
            navigateDown: {
                indice: 2,
                atl: 'arrowdown',
                code: 40,
                title: 'Avançar segmento: ',
                page:1
            },
            playPause: {
                indice: 3,
                atl: 'p',
                code: 80,
                title: 'Play/Pause do vídeo: ',
                page:1
            },
            setCurrentTime: {
                indice: 4,
                atl: 'r',
                code: 82,
                title: 'Sincronizar vídeo no tempo inicial do segmento: ',
                page:1
            },
            rewind: {
                indice: 5,
                atl: ',',
                code: 188,
                title: 'Voltar X segundos no segmento: ',
                page:1
            },
            forward: {
                indice: 6,
                atl: '.',
                code: 190,
                title: 'Avançar X segundos no segmento: ',
                page:1
            },
            newSegment: {
                indice: 7,
                atl: 'b',
                code: 66,
                title: 'Criar novo segmento a partir do segmento selecionado: ',
                page:2
            },
            joinSegment: {
                indice: 8,
                atl: 'n',
                code: 78,
                title: 'Unir segmento atual com segmento anterior: ',
                page:2
            },
            setInit: {
                indice: 9,
                atl: 'j',
                code: 74,
                title: 'Adicionar tempo do vídeo como tempo inicial do segmento selecionado: ',
                page:2
            },
            setFinal: {
                indice: 10,
                atl: 'k',
                code: 75,
                title: 'Adicionar tempo do vídeo como tempo final do segmento selecionado: ',
                page:2
            },
            revisionMode: {
                indice: 11,
                atl: 'i',
                code: 73,
                title: 'Ativar modo revisão: ',
                page:3
            }
        })
    }
}

const namespaced = true;

export default  {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
