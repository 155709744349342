<template>
  <div class="subtask-info">
      <div class="time-subtask">
        <span v-if="!hasError">Tempo inicial:  {{ msToHMS(time_interval.start_time) }}</span>
        <span v-if="!hasError">Tempo final:  {{ msToHMS(time_interval.end_time) }}</span>
        <span class="errorSpan" v-if="hasError">Tempo inicial:  {{ msToHMS(time_interval.start_time) }}</span>
        <span class="errorSpan" v-if="hasError">Tempo final:  {{ msToHMS(time_interval.end_time) }}</span>
      </div>
      <div class="info-msg">
          <span>Esses tempo são referente ao intervalo do vídeo que devem ser revisados por você nesse job!</span>
      </div>
  </div>
</template>

<script>
export default {
    computed:{
        time_interval: function () {
            const time_interval = {start_time: this.$store.state.start_time, end_time: this.$store.state.end_time}
            return time_interval
        },
        hasError: function () {
            return this.$store.getters['error/segmentWithError']
        },
    },
    methods:{
        msToHMS( duration ) {
            var milliseconds = parseInt((duration % 1000) / 100),
                seconds = parseInt((duration / 1000) % 60),
                minutes = parseInt((duration / (1000 * 60)) % 60),
                hours = parseInt((duration / (1000 * 60 * 60)) % 24);

            hours = (hours < 10) ? "0" + hours : hours;
            minutes = (minutes < 10) ? "0" + minutes : minutes;
            seconds = (seconds < 10) ? "0" + seconds : seconds;

            return hours + ":" + minutes + ":" + seconds ;
        }   
    }
}
</script>

<style lang="scss">
    .subtask-info{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        font-size: 16px;
        color: #fff;
        padding: 10px;

        .time-subtask{
            width: 100%;
            display: flex;
            justify-content: left;
            align-items: center;
            span{
                margin-right: 20px;
                padding: 3px;
                background-color: #526079;
                border-radius: 5px;
                margin-bottom: 5px;
            }
            .errorSpan{
                margin-right: 20px;
                padding: 3px;
                background-color: rgb(197, 57, 57);
                border-radius: 5px;
                margin-bottom: 5px;
            }
        }

        .info-msg{
            width: 100%;
            height: 5%;
            display: flex;
            color: rgb(184, 194, 40);
            font-size: 12px;
        }
    }

    @media screen and (min-width: 500px) and (max-width: 1400px) {
        .subtask-info{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            font-size: 16px;
            color: #fff;
            padding: 10px;
            margin-top: -10%;

            .info-msg{
                width: 100%;
                height: 5%;
                display: flex;
                color: rgb(184, 194, 40);
                font-size: 12px;
            }
        }
    }
</style>