<template>
    <div style="margin: 0 5px !important;">
        <TutorialPopover ref="tutorial" :stepsDefinitions="tutorialSteps"/>
        <p class="has-text-white-ter is-size-5">Ferramentas</p>
        
        <div id="tool-box" class="buttons-box has-text-white-ter">
            <div>
                <tool-button id="add-button" title="Add" icon="fas fa-plus" :action="addSegment"/>
                <tool-button id="remove-button" title="Rem" icon="fas fa-minus" :action="removeSegment"/>
                <tool-button id="join-button" title="Join" icon="fas fa-object-group" :action="joinSegment"/>
                <tool-button id="init-button" title="Init" icon="fas fa-history" :action="updateTimeInitFromPlayer"/>
                <tool-button id="final-button" title="Final" icon="fas fa-history fa-rotate-180" :action="updateTimeFinalFromPlayer"/>
                <tool-button id="play-button" title="Play" icon="fas fa-play" :action="playVideo"/>
            </div>

            <div>
                <tool-button id="rev-button" title="Rev" icon="fas fa-marker" :action="toggleRevisionMode" :highlight="is_revision_mode"/>
                <tool-button title="Batch" icon="fas fa-list-ul" :action="openBatch"/>
                <tool-button v-if="canUndo > 0" title="Undo" icon="fas fa-undo" :action="undoAction" />
                <tool-button v-else-if="canUndo == 0" title="Undo" v-bind:style="{ color: 'gray', cursor: 'not-allowed' }" icon="fas fa-ban" class="{background: black}" :action="undoAction" />
                <tool-button v-if="canRedo > 0" title="Redo" icon="fas fa-undo fa-rotate-180" :action="redoAction"/>
                <tool-button v-else-if="canRedo == 0" title="Redo" v-bind:style="{ color: 'gray', cursor: 'not-allowed' }" icon="fas fa-ban" class="{background: black}" :action="redoAction" />
                <tool-button id="tutorial-button" title="Tutorial" icon="far fa-question-circle has-text-white-ter fa-lg" :action="startTutorial"/>
            </div>

            <div>
                <tool-button v-if="soundwaveEnabled" id="soundwave-button" title="Soundwave" icon="fa fa-volume-up has-text-white-ter ml-4" style="margin-right: 10%;" :action="openSoundwave"/>
                <div class="tool-button-center" v-b-modal.modal-1 id="configs-button" title="Configs"  >
                    <span  class="is-size-7">Configs</span>
                    <div class="tool-button">
                         <i class="fa fa-cogs has-text-white-ter"></i>
                    </div>
                </div>
            </div>
        </div>

        <span id="segment-count" class="has-text-white-ter is-size-7">
            Segmentos: {{ subtitles_length }}
        </span>

        <edit-batch-segment-times />
    </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
const { mapActions } = createNamespacedHelpers('srt_divided')
const { mapState } = createNamespacedHelpers('undo')

import ToolButton from './ToolButton'
import EditBatchSegmentTimes from './EditBatchSegmentTimes'
import TutorialPopover from '@/components/TutorialPopover'

export default {
    components: {
        ToolButton,
        EditBatchSegmentTimes,
        TutorialPopover
    },

    data(){
        return{
            tutorialSteps:[
                
                 {
                    msg: "Isto é um segmento. Segmentos são uma representação de uma legenda em determinado período de tempo dentro do vídeo que você está editando.",
                    target: 'glass-div', 
                    placement: 'top',
                    skip: false,
                    name: "glass-div"
                },
                {
                    msg: "Este é o número do segmento que representa sua ordem cronológica de exibição.",
                    target: 'segment-id', 
                    placement: 'rigth',
                    skip: false,
                    name: "segment-id"
                },
                {
                    msg: "Tempo inicial: Representa o tempo inicial de exibição do segmento. Você pode alterá-lo clicando nas setas ou pausando o vídeo no momento desejado e usando o atalho Alt + J.",
                    target: 'time-start', 
                    placement: 'rigth',
                    skip: false,
                    name: "time-start"
                },
                {
                    msg: "Tempo final: Representa o tempo final de exibição do segmento. Você pode alterá-lo clicando nas setas ou pausando o vídeo no momento desejado e usando o atalho Alt + K.",
                    target: 'time-end', 
                    placement: 'rigth',
                    skip: false,
                    name: "time-end"
                },
                {
                    msg: "Está é a área de edição da transcrição. Aqui você pode encontrar informações sobre quantidade de caracteres por linha e no total, CPS, além de poder editar o texto da transcrição ou marcar o segmento para ver depois (Bookmark)." +
                    " Não é editável quando está trabalhando direto com a tradução.",
                    target: 'text-transcription', 
                    placement: 'top',
                    skip: false,
                    name: "text-transcription"
                },
                {
                    msg: "Esta é a área de edição da tradução. Aqui você pode encontrar informações sobre quantidade de caracteres por linha e no total do segmento, seu CPS, além de poder editar o texto da tradução ou marcar o segmento para ver depois (Bookmark).",
                    target: 'text-translate', 
                    placement: 'top',
                    skip: false,
                    name: "text-translate"
                },
                {
                    msg: "Este ícone confirma que tudo está certo com esse segmento, de acordo com as regras do manual. Após a pós-edição de todos os segmentos, você deve voltar ao primeiro segmento ir até o último confirmando dando Enter ou clicando neste ícone.",
                    target: 'checkmark', 
                    placement: 'bottom',
                    skip: false,
                    name: "checkmark"
                },
                {
                    msg: "Clicando sobre este botão você pode adicionar um novo segmento abaixo do segmento selecionado. Atalho: Alt + B.",
                    target: 'add-button', 
                    placement: 'top',
                    skip: false,
                    name: "add-button"
                },
                {
                    msg: "Clicando sobre este botão você pode remover o segmento atual selecionado.",
                    target: 'remove-button', 
                    placement: 'top',
                    skip: false,
                    name: "remove-button"
                },
                {
                    msg: "Clicando sobre este botão você irá unir o segmento atual ao segmento anterior. Atalho: Alt + N.",
                    target: 'join-button', 
                    placement: 'top',
                    skip: false,
                    name: "join-button"
                },
                {
                    msg: "Selecionando está opção você irá atualizar o tempo inicial do segmento com o tempo atual do player do vídeo. Atalho: Alt + J.",
                    target: 'init-button', 
                    placement: 'top',
                    skip: false,
                    name: "init-button"
                },
                {
                    msg: "Selecionando está opção você irá atualizar o tempo final do segmento com o tempo atual do player do vídeo. Atalho: Alt + K.",
                    target: 'final-button', 
                    placement: 'top',
                    skip: false,
                    name: "final-button"
                },
                 {
                    msg: "Botão de play/pause do player de vídeo. Atalho: Alt + P.",
                    target: 'play-button', 
                    placement: 'bottom',
                    skip: false,
                    name: "play-button"
                },
                {
                    msg: "Botão do modo revisão. Clicando sobre ele a troca de segmentos será realizada automaticamente de acordo com o tempo atual do player de vídeo. Atalho: Alt + I.",
                    target: 'rev-button', 
                    placement: 'top',
                    skip: false,
                    name: "rev-button"
                },
                {
                    msg: "Alterando esse campo você pode filtrar seus segmentos confirmados ou não confirmados."+
                    " Inserções, remoções e alterações só são efetuados quando este campo é selecionado como 'Todos segmentos'.",
                    target: 'saved-segments', 
                    placement: 'bottom',
                    skip: false,
                    name: "saved-segments"
                },
                {
                    msg: "Clicando sobre este campo ou usando a tecla de atalho 'Ctrl+F' você pode buscar"+
                    " por textos nos segmentos e realizar subtituições se necessário.",
                    target: 'search-bar', 
                    placement: 'bottom',
                    skip: false,
                    name: "search-bar"
                },
                {
                    msg: 'Em "Bookmarks" você pode verificar todos os segmentos marcados por você. Para marcar basta clicar no ícone da marcador de página em um segmentos.',
                    target: 'bookmark', 
                    placement: 'bottom',
                    skip: false,
                    name: "Bookmarks"
                },
                {
                    msg: "Este é o botão de inversão de layout. Clique sobre ele caso deseje que o vídeo esteja do lado esquerdo da tela.",
                    target: 'change-layout', 
                    placement: 'bottom',
                    skip: false,
                    name: "change-layout"
                },
                {
                    msg: "Número total de segmentos.",
                    target: 'segment-count', 
                    placement: 'left',
                    skip: false,
                    name: "segment-count"
                },
                 {
                    msg: "Botão de finalização da legendagem. Para concluir o trabalho, é necessário que todos os segmentos estejam revisados e confirmados!",
                    target: 'finalize-button', 
                    placement: 'left',
                    skip: false,
                    name: "finalize-button"
                },
            ],
            tutorialOpen: false
        }
    },

    methods: {
        openBatch() {
            this.$store.commit('controller/setOpenBatchSegmentEditor', true)
        },
        playVideo() {
            this.$store.commit('video/play')
        },
        toggleRevisionMode() {
            this.$store.commit('controller/toggleRevisionMode')  
        },
        undoAction() {
            this.$store.dispatch('undo/undoAction')
        },

        redoAction() {
            this.$store.dispatch('undo/redoAction')
        },
       
        startTutorial() {
            if(this.tutorialOpen){
                this.$refs.tutorial.finishTutorial();
                this.tutorialOpen = false
            }else{
                this.$refs.tutorial.startTutorial();
                this.tutorialOpen = true
            }
        },

        openSoundwave(){
            this.$store.dispatch('soundwave/open')
        },

        ...mapActions([
            'addSegment',
            'removeSegment',
            'joinSegment',
            'updateTimeInitFromPlayer',
            'updateTimeFinalFromPlayer',
        ])
    },

    computed: {

         ...mapGetters({
            soundwaveEnabled: 'soundwaveEnabled'
        }),
        subtitles_length: function () {
            return this.$store.getters['srt_divided/subtitles_length']
        },

        is_revision_mode: function () {
            return this.$store.state.controller.revision_mode
        },

        ...mapState({
            canUndo: state => state.done.length,
            canRedo: state => state.redo.length
        })

        
    },
}
</script>

<style>
.buttons-box {
    background: rgba(46,66,105,.6);
    display: flex;
    padding: 5px;
}
.buttons-box > div {
    flex: 1;
    padding: 0 5px;
    display: flex;
    flex-wrap: wrap;
}
.buttons-box > div:first-child {
    border-right: 1px solid #d3d3d3;
    
}

.dropdown-menu {
  width: 30rem;
}
.hotkeys{
    display: grid;
    grid-template-columns: auto auto;
}

</style>