<template>
    <div>
        <notifications :closeOnClick="false" width="400px" classes="vue-notification custom-notification" group="internet_off" />
        <notifications :closeOnClick="false" width="400px" classes="vue-notification custom-notification" group="internet_slow" />
        <notifications :closeOnClick="false" width="400px" classes="vue-notification custom-notification-success" group="internet_on" />
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            is_alert_showing: false,
        }
    },

    created() {
        setInterval(this.checkingInternetStatus, 1000)
    },
    methods: {
        checkingInternetStatus() {
            let downlink = navigator.connection.downlink
            if ( downlink >= 0.4 && downlink <= 2 ) {
                this.showSlowInternetNotification()
            } else if( downlink >= 0 && downlink < 0.4 ){
                this.showNotification()
            } else {
                this.hideNotification()
            }
        },

        hideNotification() {
            if (!this.is_alert_showing) return
            
            this.$notify({
                group: 'internet_off',
                clean: true,
            })
            this.$notify({
                group: 'internet_slow',
                clean: true,
            })
            this.$notify({
                group: 'internet_on',
                title: 'Alerta',
                text: 'Internet estabilizada.',
                duration: 5000,
            })

            this.is_alert_showing = false
        },
        showNotification() {
            if (this.is_alert_showing) return

            this.$notify({
                group: 'internet_off',
                type: 'warn',
                title: 'Alerta',
                text: 'Falha na conexão. Não feche o navegador para não perder as alterações.',
                duration: -1,
            })

            this.is_alert_showing = true
        },
        showSlowInternetNotification(){
            if(this.is_alert_showing) return

            this.$notify({
                group: 'internet_slow',
                type: 'warn',
                title: 'Alerta',
                text: 'Conexão lenta. Possível problema para reproduzir vídeo. Os dados continuam sendo armazenados.',
                duration: 4000,
            })

            this.is_alert_showing = true
        }
    },
    computed: {
        errors: function () {
            return this.$store.state.error.errors
        }
    },
    watch: {
        errors: function (errs) {
            if (errs.length > 0) {
                // TODO
                // this.alertCustomError(errs[0].context.toLowerCase())
            }
        }
    }
}
</script>

<style lang="scss">

@keyframes blinker {
  50% {
    opacity: 0.7;
  }
}

.custom-notification {
    margin: 15px !important;

    .notification-title {
        font-size: 17px;
        animation: blinker 1s linear infinite;
    }
    
    .notification-content {
        font-size: 15px;
        animation: blinker 1s linear infinite;
    }
}
.custom-notification-success {
    margin: 15px !important;

    .notification-title {
        font-size: 17px;
    }
    
    .notification-content {
        font-size: 15px;
    }
}
</style>