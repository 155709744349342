<template>
  <div class="text-editor-wrapper">
    <div style="padding: 5px; display: flex; justify-content: space-between">
      <div>
        <p style="color: white;" v-html="characters_per_line_string"></p>
      </div>
      <div style="padding-left: 1vw">
        CPS:
        <span :class="{ 'has-text-danger': characters_per_second >= 19 }">{{
          characters_per_second || ""
        }}</span>
      </div>
      <div
        data-tooltip="Bookmark no segmento atual"
        class="has-tooltip-arrow has-tooltip-top"
      >
        <i
          v-if="is_bookedmarked == 'false' || is_bookedmarked == false "
          class="fas fa-bookmark bookmark"
          @click="bookmarkActualSegment"
        ></i>
        <i
          v-else
          class="fas fa-bookmark is-bookmark"
          @click="bookmarkActualSegment"
        ></i>
      </div>
    </div>
    <div style="position: relative">
      <textarea
        :disabled="disabled"
        :autofocus="!disabled"
        v-model="text"
        ref="textarea"
        v-shortkey="{
          invertedQuestionMark: ['alt', 'w'],
          invertedExclamationMark: ['alt', 'q'],
          deleteAction: ['alt', 'del'],
          saveSegment: ['enter']
        }"
        @shortkey="hotkeysEvents"
        @input="textUpdated($event.target.value)"
        @keydown.enter="enterPressed($event)"
        rows="2"
        class="textarea"
        :id="computed_textarea_id"
      ></textarea>
      <i @click="saveSegment('current')" v-if="!disabled && is_saved == true" id="checkmark" style="font-size: 1.5rem; color: green; cursor: pointer" class="fas fa-check-circle"></i>
      <i @click="saveSegment('current')" v-if="!disabled && is_saved == false" id="checkmark" style="font-size: 1.5rem; color: #D3D3D3; cursor: pointer" class="fas fa-check-circle"></i>
    </div>
  </div>
</template>

<script>
import ToolButton from "./ToolBox/ToolButton";

export default {
  components: {
    ToolButton,
  },
  props: {
    disabled: { type: Boolean, default: false },
    value: { type: String, required: true },
    duration: { type: Number, required: true },
    main: { type: Boolean, default: false },
    index: Number,
    text_area_type: String,
  },

  data() {
    return {
      text: "",
      last_text: "",
      selectionStart: 0,
      selectionStart: 0,
      selectionEnd: 0,
      characters_per_second: 0,
      character_lines_count: [],
      cursor_position: 0,
    };
  },

  mounted() {
    this.text = this.value;
    this.last_text = this.text;
  },
  updated(){
    this.$refs.textarea.focus();
  },
  methods: {
    async bookmarkActualSegment() {
      this.$store.dispatch("srt/bookmarkActualSegment");
    },
    async saveSegment(id) {
      await this.updateTextSegment()
      await this.$store.dispatch("srt/changeSaveSegment", id);
    },
    async enterPressed(event) {
      const text = event.target.value;
      if (event.shiftKey) {
        if (text.includes("\n")) {

          event.preventDefault();
          let textArea = this.$refs.textarea;
          let selectionEnd = this.$refs.textarea.selectionStart;
          let new_line_position = textArea.value.indexOf("\n");
          textArea.value.charAt(new_line_position - 1) != " "
            ? (textArea.value = textArea.value.replace(/\n/g, " "))
            : (textArea.value = textArea.value.replace(/\n/g, ""));
          if (new_line_position > selectionEnd || new_line_position == -1) {
            textArea.value = textArea.value
              .substr(0, selectionEnd)
              .concat(
                "\n".concat(
                  textArea.value.substr(selectionEnd, textArea.value.length)
                )
              );
            this.$refs.textarea.selectionStart = selectionEnd;
            this.$refs.textarea.selectionEnd = selectionEnd;

          } else {

            textArea.value = textArea.value
              .substr(0, selectionEnd - 1)
              .concat(
                "\n".concat(
                  textArea.value.substr(selectionEnd - 1, textArea.value.length)
                )
              );
            this.$refs.textarea.selectionStart = selectionEnd;
            this.$refs.textarea.selectionEnd = selectionEnd;

          }
        }
        setTimeout(async ()=>{
          this.$store.dispatch('srt/updateSegmentBeforeChange')
        }, 50)
        
      } else if (event.ctrlKey) {
        event.preventDefault();
        let textArea = this.$refs.textarea;
        let selectionStart = textArea.selectionStart;
        let characters_per_second = this.characters_per_second;
    
        this.$emit("ctrlEnterPressed", selectionStart, characters_per_second);
      } else if (event.altKey) {
        event.preventDefault();
        let textArea = this.$refs.textarea;
        let selectionStart = textArea.selectionStart;
        let characters_per_second = this.characters_per_second;

        this.$emit("altEnterPressed", selectionStart);
      } else {
        /**
         * On enter, do not leaves the editor
         */
        event.preventDefault();
      }

      
    },
    /**
     * Fired onchange event.
     * Emit event for parent manipulating
     */
    async textUpdated(text) {
      await this.updateTextSegment()
      await this.$store.dispatch("srt/setSavedSegmentFalse", 'current');
    },

    async hotkeysEvents(event) {
      switch (event.srcKey) {
        case "invertedQuestionMark": {
          let textArea = this.$refs.textarea;
          let selectionStart = textArea.selectionStart;
          await this.$store.commit("srt/cursorPositionChanged", selectionStart);
          await this.$store.dispatch("srt/insertSymbol", "\u00BF");
          break;
        }
        case "invertedExclamationMark": {
          let textArea = this.$refs.textarea;
          let selectionStart = textArea.selectionStart;
          await this.$store.commit("srt/cursorPositionChanged", selectionStart);
          await this.$store.dispatch("srt/insertSymbol", "\u00A1");
          break;
        }
        case "deleteAction": {
          await this.$store.dispatch("srt/deleteAction");
          break;
        }
        case "saveSegment": {
          await this.$store.dispatch('srt/updateSegmentBeforeChange')

          await this.$store.dispatch("srt/saveSegment", 'current');
          if(this.$store.getters['controller/getSegmentsFilter'] != 'all') break
          setTimeout(async ()=>{
            await this.$store.dispatch('controller/navigateDown')
          }, 200)
          
          break;
        }
      }
    },
    /**
     * Fired oninput event.
     * Recalculates CPL/CPS
     */
    updateMetadata(text) {
      /**
       * Removes all tags
       */
      text = this.removeTags(text);

      this.character_lines_count = [];
      /**
       * Splits text in lines and gets the
       * trimmed character count of each one
       * and calculates CPS
       */
      text.split("\n").forEach((line) => {
        this.character_lines_count.push(line.trim().length);
      });
      if (this.character_lines_count.length == 1)
        this.character_lines_count.push(0);

      this.calculatesCPS();
    },
    calculatesCPS() {
      const characters = this.character_lines_count.reduce(
        (a, count) => a + count
      );
      const seconds = this.duration / 1000;

      this.characters_per_second = (+characters / +seconds).toFixed();
    },
    /**
     * Removes all tags to count only
     * the characters.
     */
    removeTags(text) {
      return text.replace(/<i>/g, "").replace(/<\/i>/g, "");
    },
    async updateTextSegment(){
      let textArea
      if (this.computed_textarea_id == "translation") {
        textArea = document.getElementById("translation")
      } else {
        textArea = document.getElementById("transcription")
      }
      this.selectionStart = textArea.selectionStart
      this.selectionEnd = textArea.selectionEnd
      
      let text = textArea.value
      this.$emit("textChanged", text, this.index);
    },
  },

  computed: {
    computed_textarea_id() {
      return this.text_area_type == "transcription"
        ? "transcription"
        : "translation";
    },
    characters_per_line_string: function () {
      const first_line = this.character_lines_count[0] || 0;
      const second_line = this.character_lines_count[1] || 0;
      const sum = first_line + second_line || 0;

      /**
       * Check size
       */
      let first_line_html = first_line;
      if (first_line > 38)
        first_line_html = `<span class="has-text-danger">${first_line}</span>`;
      let second_line_html = second_line;
      if (second_line > 38)
        second_line_html = `<span class="has-text-danger">${second_line}</span>`;
      let sum_html = sum;
      if (sum > 76) sum_html = `<span class="has-text-danger">${sum}</span>`;

      return `${first_line_html}/${second_line_html}=${sum_html}`;
    },

    current_segment_index: function () {
      return this.$store.getters["srt/current_index"];
    },

    is_bookedmarked: function () {
      let subtitle = this.$store.getters["srt/subtitle"](
        this.current_segment_index
      );

      if(subtitle.is_bookmark == undefined) return false
      return subtitle.is_bookmark;
    },
    is_saved: function () {
      let subtitle = this.$store.getters["srt/subtitle"](
        this.current_segment_index
      );
      if(subtitle.is_saved === undefined || subtitle.is_saved == "false"){
        return false
      }
      return subtitle.is_saved;
    },
    last_saved_date: function(){
      return this.$store.getters['srt/last_saved_date']
    }
  },

  watch: {
    last_saved_date(){
      this.$refs.textarea.focus()
    },   
    is_saved(){
      this.$refs.textarea.focus()
    },
    text: async function (text) {
      this.updateMetadata(text);
    },
    duration: function () {
      this.updateMetadata(this.text);
    },
    value: function (value) {
      this.text = value;
    },

    current_segment_index: function (a) {
      if (this.$refs && !this.disabled) {
        this.$refs.textarea.focus();
      }
    },
  },
};
</script>

<style lang='scss'>




.text-editor-wrapper {
  height: 8.5vw;

  .flag-btn {
    width: 20px;
    height: 20px;
  }

  .bookmark {
    cursor: pointer;
    opacity: 0.3;
  }
  .is-bookmark {
    color: rgba(46, 66, 105, 0.9);
    cursor: pointer;
  }
  #checkmark {
    position: absolute;
    bottom: 2px;
    right: 2px;
  }
  .textarea {
    font-size: 1.15vw;
    padding: 0.5vw;
    resize: none;
    width: 25vw;
    border-radius: 0.4vw;
    padding-top: 0.1vw;
    box-shadow: 0 0 0.1vw;
  }
}


</style>
