<template>
  <div id="glass-div" class="has-text-white-ter" style="background: rgba(46,66,105,.6); min-height: 20%; max-height: 20%;">
        <!-- Segment number -->
            <div id="segment-id" :class="{modifiedText: this.index == last_segment_modified}">{{ index + 1 }}</div>
        
        <!-- Time manipulation -->
        <div>
            <subtitle-time-editor id="time-start" @timeChanged="formatUpdateTimeInit" :value="start" :start="start" :end="end" :modifier="'start'"/>
            <subtitle-time-editor id="time-end" @timeChanged="formatUpdateTimeFinal" :value="end" :start="start" :end="end" :modifier="'end'"/> 
        </div>


        <!-- TranscriptionEdit -->
        <div>
            <subtitle-text-editor 
                id="text-transcription"
                @textChanged="updateTextDebounced"
                @ctrlEnterPressed="splitAndJoinTranscriptionSegment"
                @altEnterPressed="splitAndNewTranscriptionSegment" 
                :value="text" 
                :duration="duration"     
                :disabled="is_translate"
                :text_area_type="transcription"
            />
        </div>

        <!-- TranslationEdit -->
        <div>
            <subtitle-text-editor 
                id="text-translate"
                @textChanged="updateTranslateTextDebounced"
                @ctrlEnterPressed="splitAndJoinTranslationSegment"
                @altEnterPressed="splitAndNewTranslationSegment" 
                :value="translate_text" 
                :duration="duration" 
                :disabled="!is_translate"
                :text_area_type="translation"
            />
        </div>
        <div style="height:20vh"></div>
    </div>
</template>


<script>
//import { db } from '@/db'
import _ from 'lodash'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions, mapState } = createNamespacedHelpers('srt_divided')

import SubtitleTimeEditor from './SubtitleTimeEditor'
import SubtitleTextEditor from './SubtitleTextEditor'
export default {
    components: {
        SubtitleTimeEditor,
        SubtitleTextEditor,
    },

    data(){
        return {
            transcription: "transcription",
            translation: "translation"
        }
    },
    methods: {
        formatUpdateTimeInit(time_ms) {
             let segment = this.subtitles.filter(s => s.store_index == this.index)[0]
            this.updateTimeInit({
                time_ms,
                index: this.index,
                segment_id: segment.id
            })
        },
        formatUpdateTimeFinal(time_ms) {
            let segment = this.subtitles.filter(s => s.store_index == this.index)[0]

            //console.log(segment)
            this.updateTimeFinal({
                time_ms,
                index: this.index,
                segment_id: segment.id
            })
        },
        updateTextDebounced(text) {
            this.updateText({
                text,
                subtitle_id: this.active_segment.id,
                index: this.index,
            })
        
            this.capitalizeNextSegment()
        },
        updateTranslateTextDebounced(translate_text) {
            this.updateTranslateText({
                translate_text,
                subtitle_id: this.active_segment.id,
                index: this.index,
            })
            /
            this.capitalizeNextSegment()
        },
        async splitAndJoinTranscriptionSegment(selectionStart, characters_per_second){
            var editionMode  = await this.$store.dispatch('controller/checkEditionMode')
            if(!editionMode){
                return
            }

            await this.$store.dispatch(`srt/updateSegmentBeforeChange`)

            let current_segment = this.active_segment //Get active segment
            let current_vue_index = this.current_index // Get current index **USELESS**

            if (current_vue_index == 0) return

            const next_index = current_vue_index + 1 //Get next index to modify
            const next_segment= this.next_segment //Get next segment to modify

            if(current_segment.index === this.max_index){
                let text_segment = current_segment.text

                let splittedText = text_segment.substring(selectionStart)
                current_segment.text = current_segment.text.slice(0, selectionStart)

                const trimmed_text = splittedText
                const trimmed_translation = ''

                /* Time modification */
                const total_time = current_segment.end - current_segment.start
                const milisec_per_caharacter = total_time / text_segment.length
                const time_to_remove = milisec_per_caharacter * trimmed_text.length;
                /*        *        */

                const passingParams = {
                    trimmed_text: splittedText,
                    trimmed_translation: trimmed_translation,
                    time_to_remove: time_to_remove
                }
                this.updateSegment({
                subtitle_id: current_segment.id,
                segment_data: current_segment,
                })

                this.splitAddNewSegment({passingParams})
                return

            } else {
                /* Inner text modification */ 
            let text_segment = current_segment.text
            let splittedText = text_segment.substring(selectionStart)
            current_segment.text = current_segment.text.slice(0, selectionStart)

            let before_split = text_segment.substring(0,selectionStart) 
            current_segment.text = before_split
            
            next_segment.text = (splittedText || '') + ' ' +  (next_segment.text || '') 
            
            //Modify END TIME & START TIME OF NEXT SEGMENT
            let time_difference = current_segment.end - current_segment.start
            let splittedText_lenght = splittedText.length
            let seconds_per_character = (1 / characters_per_second) * 1000
            let time_to_move = splittedText_lenght * seconds_per_character

            let time_current_end = current_segment.end //Correct
            let time_joined_start  = next_segment.start //Passing value from current one, should remain the same value - time_to_move
            current_segment.end = time_current_end - time_to_move //Correct
            next_segment.start = time_joined_start - time_to_move
            /** MODIFY TIME SECTION ENDS */

                this.updateSegment({
                    subtitle_id: current_segment.id,
                    segment_data: current_segment,
                })
                this.updateSegment({
                    subtitle_id: next_segment.id,
                    segment_data: next_segment //next_segment,
                })
            return
            }
        },
        async splitAndJoinTranslationSegment(selectionStart, characters_per_second){
            var editionMode  = await this.$store.dispatch('controller/checkEditionMode')
            if(!editionMode){
                return
            }

            await this.$store.dispatch(`srt/updateSegmentBeforeChange`)

            let current_segment = this.active_segment //Get active segment
            let current_vue_index = this.current_index // Get current index

            if (current_vue_index == 0) return
            const next_index = current_vue_index + 1 //Get next index to modify
            const next_segment= this.next_segment //Get next segment to modify
           
            if(current_segment.index === this.max_index){
                let text_segment = current_segment.translate_text

                let splittedText = text_segment.substring(selectionStart)
                current_segment.translate_text = current_segment.translate_text.slice(0, selectionStart)

                const trimmed_text = ''
                const trimmed_translation = splittedText

                /* Time modification */
                const total_time = current_segment.end - current_segment.start
                const milisec_per_caharacter = total_time / text_segment.length
                const time_to_remove = milisec_per_caharacter * trimmed_text.length;
                /*        *        */

                const passingParams = {
                    trimmed_text: trimmed_text,
                    trimmed_translation: trimmed_translation,
                    time_to_remove: time_to_remove
                }
                this.updateSegment({
                subtitle_id: current_segment.id,
                segment_data: current_segment,
                })

                this.splitAddNewSegment({passingParams})
                return
            } else {
                /* Inner text modification */ 
                let text_segment = current_segment.translate_text
                let splittedText = text_segment.substring(selectionStart)
                // current_segment.translate_text = current_segment.translate_text.replace(splittedText, "")
                current_segment.translate_text = current_segment.translate_text.slice(0, selectionStart)
                current_segment.text = current_segment.text
                
                next_segment.translate_text = (splittedText || '') + ' ' +  (next_segment.translate_text || '') 
                next_segment.translate_text = next_segment.translate_text.replace('\n', '').trim()

                
                //Modify END TIME & START TIME OF NEXT SEGMENT
                let time_difference = current_segment.end - current_segment.start
                let splittedText_lenght = splittedText.length
                let seconds_per_character = (1 / characters_per_second) * 1000
                let time_to_move = splittedText_lenght * seconds_per_character

                let time_current_end = current_segment.end //Correct
                let time_joined_start  = next_segment.start //Passing value from current one, should remain the same value - time_to_move
                current_segment.end = time_current_end - time_to_move //Correct
                next_segment.start = time_joined_start - time_to_move
                /** MODIFY TIME SECTION ENDS */

                this.updateSegment({
                subtitle_id: current_segment.id,
                segment_data: current_segment,
                })
                this.updateSegment({
                subtitle_id: next_segment.id,
                segment_data: next_segment //next_segment,
                })
                return
            }
        },
        async splitAndNewTranscriptionSegment(selectionStart){ // NEW FUNCTION ** TEST ON GOING ** 
             var editionMode  = await this.$store.dispatch('controller/checkEditionMode')
            if(!editionMode){
                return
            }
            
            await this.$store.dispatch(`srt/updateSegmentBeforeChange`)

            let current_segment = this.active_segment //Get active segment
            let current_vue_index = this.current_index // Get current index
            let text_segment = current_segment.text
            let splittedText = text_segment.substring(selectionStart)

            current_segment.text = current_segment.text.slice(0, selectionStart)
            
            const trimmed_text = splittedText
            const trimmed_translation = ''

            /* Time modification */
            const total_time = current_segment.end - current_segment.start
            const milisec_per_caharacter = total_time / text_segment.length
            const time_to_remove = milisec_per_caharacter * trimmed_text.length;
            
            const passingParams = {
                trimmed_text: splittedText,
                trimmed_translation: trimmed_translation,
                time_to_remove: time_to_remove
            }
            this.updateSegment({
             subtitle_id: current_segment.id,
             segment_data: current_segment,
             })

            this.splitAddNewSegment({passingParams})
            
        },
        async splitAndNewTranslationSegment(selectionStart){
            var editionMode  = await this.$store.dispatch('controller/checkEditionMode')
            if(!editionMode){
                return
            }

            await this.$store.dispatch(`srt/updateSegmentBeforeChange`)

            let current_segment = this.active_segment //Get active segment
            let current_vue_index = this.current_index // Get current index
            let text_segment = current_segment.translate_text
            let splittedText = text_segment.substring(selectionStart)
            current_segment.translate_text = current_segment.translate_text.slice(0, selectionStart)
    
            const trimmed_text = ''
            const trimmed_translation = splittedText

            /* Time modification */
            const total_time = current_segment.end - current_segment.start
            const milisec_per_caharacter = total_time / text_segment.length
            const time_to_remove = milisec_per_caharacter * trimmed_translation.length;
            
            const passingParams = {
                trimmed_text: trimmed_text,
                trimmed_translation: trimmed_translation,
                time_to_remove: time_to_remove
            }
            this.updateSegment({
             subtitle_id: current_segment.id,
             segment_data: current_segment,
             })

            this.splitAddNewSegment({passingParams})
        },

        ...mapActions([
            'updateTimeInit',
            'updateTimeFinal',
            'updateText',
            'capitalizeNextSegment',
            'updateTranslateText',
            'updateSegment',
            'splitAddNewSegment'
        ])
    },

    computed: {
        index: function () {
            return this.active_segment.index
        },
        start: function () {
            return this.active_segment.start
        },
        end: function () {
            return this.active_segment.end
        },
        text: function () {
            return this.active_segment.text || ''
        },
        translate_text: function () {
            return this.active_segment.translate_text || ''
        },
        is_bookmark: function(){

            if(this.active_segment.is_bookmark == undefined){
                return false
            }

            if((this.active_segment.is_bookmark) == "true"){
                return true
            }
            if((this.active_segment.is_bookmark) == "false"){
                return false
            }
        },

        duration: function () {
            return this.end - this.start
        },
        subtitles(){
            return this.$store.getters['srt_divided/subtitles']
        },

        ...mapGetters([
            'active_segment',
            'is_translate',
            'next_segment',
            'max_index',
            'last_segment_modified'
        ]),        
    }
}
</script>


<style>
.modifiedText{
    background-color: rgba(46,66,105,.9);
    color: whitesmoke;
    font-weight: bold;

    width: 100%;
    height: 50%;

    display: flex;
    justify-content: center;
    align-self: center;
}
textarea, input{
    background-color: var(--bg-color) !important;
    color: var(--font-color) !important;
    outline: none !important;
    border:1px solid var(--sec-bg-color) !important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--font-color) !important;
  opacity: 1; /* Firefox */
}

textarea:focus{
    
    outline: none !important;
    border:3px solid var(--bg-color) !important;
}
</style>