<template>
    <div id="subline-div"
        :class="{'line-data': index >= 0 && index <= max_index}" 
        @click="setCurrentIndex(index)"
    >   
        <template v-if="data" >
                <div :class="{modifiedText: this.index == last_segment_modified}" >
                    <span v-if="index >= 0 && index <= max_index">{{ index + 1 }}</span>
                </div>
            <div>
                <span 
                    style="padding-right:1.8vw;"
                    v-if="data.start != NaN"
                    :class="{'has-text-danger': highlight.start}"
                >
                    {{ data.start | mili2srt }}
                </span>
                <span
                    style="padding-right:1.8vw;" 
                    v-if="data.end != NaN"
                    :class="{'has-text-danger': highlight.end}"
                >
                    {{ data.end | mili2srt }}
                </span>
            </div>
            <!--<div>-->
                
            <!--</div>-->
            <div style="align-items:baseline; padding: 1vw;">
                <div v-if="highlight.is_bookmark && data.text && !data.translate_text" style="align-self:flex-end;">
                    <i class="fas fa-bookmark"></i>
                </div>
                <span
                    
                    v-if="data.text" 
                    v-html="data.text.replace('\n', '<br>')"
                    :class="{'text has-text-danger': highlight.text}"
                ></span>
            </div>
            <div style="align-items:baseline; padding: 1vw; position: relative">
                <div v-if="highlight.is_bookmark && data.translate_text" style="align-self:flex-end;">
                    <i class="fas fa-bookmark"></i>
                </div>
                
                
                    
                <div v-if="isTranslate && data.id" style="position: absolute; bottom: 0; right: 10px;">
                    <i @click.stop="saveSegment(data.id)" v-if="data.is_saved == true" id="checkmark" style="font-size: 1.5rem; color: green; cursor: pointer" class="fas fa-check-circle"></i>
                    <i @click.stop="saveSegment(data.id)" v-else id="checkmark" style="font-size: 1.5rem; color: #D3D3D3; cursor: pointer" class="fas fa-check-circle"></i>
                </div>
                <div v-if="!isTranslate && data.id" style="position: absolute; bottom: 0; left: -35px;">
                    <i @click.stop="saveSegment(data.id)" v-if="data.is_saved == true" id="checkmark" style="font-size: 1.5rem; color: green; cursor: pointer" class="fas fa-check-circle"></i>
                    <i @click.stop="saveSegment(data.id)" v-else id="checkmark" style="font-size: 1.5rem; color: #D3D3D3; cursor: pointer" class="fas fa-check-circle"></i>
                </div>

                <span 
                    v-if="data.translate_text" 
                    v-html="data.translate_text.replace('\n', '<br>')"
                    :class="{'text has-text-danger': highlight.translate_text}"
                ></span>
            </div>
                
                <div style="height:15vh"></div>
        </template>
    </div>
</template>

<script>
import { mili2srt } from '../../utils/functions'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapState } = createNamespacedHelpers('srt')

export default {
    props: {
        index: {
            type: Number,
        },
        data: {
            type: Object,
            description: 'Data: start, end, text and translate_text',
        },
    },

    methods: {
        async setCurrentIndex(index) {
            if (index < 0 || index > this.max_index || index == undefined) return
            //await this.$store.dispatch('srt/updateSegmentBeforeChange')
            this.$store.dispatch('controller/setCurrentSegmentIndex', index)
                
        },
        async saveSegment(id){
            await this.$store.dispatch("srt/changeSaveSegment", id);
        },        
    },

    computed: {
        
        segments_filter(){
            return this.$store.getters['controller/getSegmentsFilter']
        },

        is_in_filter() {
            if(this.segments_filter === 'unsaved'){
                if(this.data.is_saved === true) return false
                else return true
            }
            else if(this.segments_filter === 'saved'){
                if(this.data.is_saved !== true) return false
                else return true
            }
            else{
                return true
            }
        },

        max_index: function () {
            return this.$store.getters['srt/max_index']
        },

        highlight: function () {
            let highlight = {
                start: false,
                end: false,
                text: false,
                translate_text: false,
                is_bookmark: false,
            }

            // helper function
            const highlightField = (field=null) => {
                if (!field) highlight = {
                    start: true,
                    end: true,
                    text: true,
                    translate_text: true,
                    is_bookmark:true,
                }; else {
                    highlight[field] = true
                }
            }

            if (this.index < 0) return highlight

            /**
             * Previous end overlapping
             */
            if (this.index > 0 && this.previous_segment.end > this.data.start) {
                highlightField('start')
            }
            /**
             * Next time_init overlapping
             */
            if (this.next_segment && this.next_segment.start < this.data.end) {
                highlightField('end')
            }
            /**
             * Min (1s) and max (7s) duration
             */
            const { start, end } = this.data
            const delta = end - start
            if (delta < 1000 || delta > 7000) {
                highlightField('start')
                highlightField('end')
            }
            /**
             * Max 76 characters per segment
             */
            /**
             * Helper function
             */
            const countChars = lines => {
                let character_count = 0
                lines.forEach(line => {                    
                    character_count += line.trim().length
                })
                return +character_count
            } 

            if (this.data.text) {

                const transcription_chars = countChars(this.data.text.trim().split('<br>')) -1 //Count all the chars on the string

                let line = (this.data.text.split('\n')) //Creates string on 

                if (line[1]== undefined){
                    line[1] = ''
                }

                if ((transcription_chars > 76) || (line[0].length > 38) || (line[1].length > 38) ){
                    highlightField('text')
                }

                //Turns the transcription text red on CPS greater then 19
                                                                                        
                let seconds = ((+(this.data.end) - +(this.data.start))/1000) 
                let CPS_text = ((transcription_chars) / seconds ).toFixed()

                if( CPS_text >= 19){                                                       
                    highlightField('text')
                }

            }
            if (this.data.translate_text) {

                let translation_chars = countChars(this.data.translate_text.trim().split('<br>')) - 1
                
                let line = (this.data.translate_text.split('\n'))

                if (line[1]== undefined){
                    line[1] = ''
                    translation_chars ++
                }

                if ((translation_chars > 76) || (line[0].length > 38) || (line[1].length > 38)) {
                    highlightField('translate_text')
                }

                //Turns the transcription text red on CPS greater then 19
                let seconds = ((+(this.data.end) - +(this.data.start))/1000)
                let CPS_translate_text = ( translation_chars / seconds).toFixed()

                if(CPS_translate_text >= 19 ){
                    highlightField('translate_text')
                }

            }
            
            highlight.is_bookmark = false

            if(this.data.is_bookmark =="true" || this.data.is_bookmark == true ){
                highlight.is_bookmark = true
            }
            if(this.data.is_bookmark =="false" || this.data.is_bookmark == false) {
                highlight.is_bookmark = false
            }

            return highlight
        },

        previous_segment: function () {
            if (this.index < 0) return null
            return this.$store.getters['srt/subtitle'](this.index - 1)
        },
        next_segment: function () {
            if (this.index >= this.max_index) return null
            return this.$store.getters['srt/subtitle'](this.index + 1)
        },

        max_index: function () {
            return this.$store.getters['srt/max_index']
        },
        isTranslate:  function () {
            return this.$store.getters['srt/is_translate']
        },

        ...mapState({
            last_segment_modified: state => {
            return state.task.last_segment_selected
            }
        })
    },
    filters: {
        mili2srt,
    }
}
</script>

<style lang="scss">

.line-data {
    transition: all 0.1s;
}
.line-data:hover {
    background-color: #bbb7b7;
}
.line-data:active {
    background-color: #fff;
}
.modifiedText{
    background-color: rgba(46,66,105,.9);;
    color: whitesmoke;
    font-weight: bold;
    
    width: 100%;
    height: 50%;

    display: flex;
    justify-content: center;
    align-self: center;
}

</style>