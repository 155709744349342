// TXT task module

import { last } from 'lodash';
let firebase = null

if(process.env.NODE_ENV == "development"){
  firebase = require('@/firebase/test_environment/db_test')
}
else{
  firebase = require('@/firebase/production_environment/db')
}

const db = firebase.db
const auth = firebase.auth

const state = {
    task: {
        id: '',
        name: '',
        source_language: '',
        status: '',
        callback_url: '',
        media_url: '',
        translate_language: '',
        type: '',
        text: '',
        translate_text: '',
        last_saved_date: null,
        cursor_position: 0,
        last_text: "",
    },
};

const getters = {
    last_saved_date: state => {
        return state.task.last_saved_date
    },
    last_text: state => {
        return state.task.last_text
    },
    last_focus: state => {
        return {
            focusParentXPath: state.task.focusParentXPath,
            focusNodeIndex: state.task.focusNodeIndex,
            focusOffset: state.task.focusOffset,
        }
    },
    translate_language: state => {
        return state.task.translate_language
    },    
    task_text: state => {
        if (state.task.translate_language) {
            return state.task.translate_text;
        } 
        return state.task.text;        
    },
}

const mutations = {
    setTask: (state, task) => state.task = task,
    setLastSavedTime: (state, timestamp) => state.task.last_saved_date = timestamp,
    setPosition: (state, data) => {
        state.task.focusParentXPath = data.focusParentXPath;
        state.task.focusNodeIndex = data.focusNodeIndex;
        state.task.focusOffset = data.focusOffset;
    }
};

const actions = {
    async updatePosition({commit, rootState}, data){

        const task_id = rootState.task_id

        await db.doc(`tasks/${task_id}`).update({
            ...data
        })
        
        commit("setPosition", { ...data })
    },
    async updateText({ rootState, state, commit }, text) {

        const task_id = rootState.task_id

        const savedTime = Date.now()
        const docRef = db.doc(`tasks/${task_id}`)

        if (state.task.translate_language) {
           await docRef.update({ 
                translate_text: text,
                last_saved_date: savedTime
           });
        } else {
           await docRef.update({ 
                text,
                last_saved_date: savedTime
           });
        }

        commit("setLastSavedTime", savedTime);

        return savedTime;
    },
}


export default  {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
