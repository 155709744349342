export default {
    /**
     * Starts listener
     * @param {Object} vueElement Vue element
     */
    initialize: (vm, el, is_divided) => {
        // TODO refatorar para chave:função
        const element = el || window // to handle tinymce
        let srt_module = is_divided ? srt_module = "srt_divided" : srt_module = "srt"; //Vuex srt module

        element.addEventListener('keydown', async (e) => {
            
            // Get all configs
            let config = vm.$store.getters['controller/getShortCutes']

            // prevent Shift default
            if (e.keyCode == 16) {
                e.preventDefault()
            }


             // prevent ALT default
            if (e.keyCode == 18) {
                e.preventDefault()
            }

            /**
             * Navigate UP 
             */
            if (e.keyCode == config.navigateUp.code && e.altKey) {
                e.preventDefault()
                //await vm.$store.dispatch(`${srt_module}/updateSegmentBeforeChange`)
                await vm.$store.dispatch('controller/navigateUp')
            }

            /**
             * Navigate DOWN 
             */
            if (e.keyCode == config.navigateDown.code && e.altKey) {
                e.preventDefault()
                //await vm.$store.dispatch(`${srt_module}/updateSegmentBeforeChange`)
                await vm.$store.dispatch('controller/navigateDown')
                
            }

            /**
             * Play video 
             */
            if (e.keyCode == config.playPause.code && e.altKey) {
                e.preventDefault()
                vm.$store.dispatch('video/play')
            }
            
            /**
             * Play segment - Alt+R
             */
            if (e.keyCode == config.setCurrentTime.code && e.altKey) {
                e.preventDefault()
                vm.$store.dispatch('video/replaySegment')
            }   

            /*
            * Set forward/backward interval - ALT + 2 
            */
           if (e.keyCode == 50 && e.altKey || e.keyCode == 98 && e.altKey){
               e.preventDefault()
               vm.$store.commit('video/setForwardBackwardInterval', 2000)
           }

           /*
            * Set forward/backward interval ALT + 5
            */
           if (e.keyCode == 53 && e.altKey || e.keyCode == 101 && e.altKey){
               e.preventDefault()
               vm.$store.commit('video/setForwardBackwardInterval', 5000)
           }

            /**
             * Rewind <interval> 
             */
            if (e.keyCode == config.rewind.code && e.altKey) {
                e.preventDefault()
                vm.$store.dispatch('video/rewind')
            }
            
            /**
             * Forward <interval>
             */
            if (e.keyCode == config.forward.code && e.altKey) {
                e.preventDefault()
                vm.$store.dispatch('video/forward')
            }
            
            /**
             * New Segment
             */
            if (e.keyCode == config.newSegment.code && e.altKey) {
                e.preventDefault()
                /**
                 * Not passing parameters, the action will populate it
                 */
                await vm.$store.dispatch(`${srt_module}/updateSegmentBeforeChange`)
                await vm.$store.dispatch(`${srt_module}/addSegment`)               
            }
            
            /**
             * Join Segment
             */
            if (e.keyCode ==  config.joinSegment.code && e.altKey) {
                e.preventDefault()
                /**
                 * Not passing parameters, the action will populate it
                 */
                await vm.$store.dispatch(`${srt_module}/updateSegmentBeforeChange`)
                await vm.$store.dispatch(`${srt_module}/joinSegment`, {})
            }
            
            /**
             * Set time init
             */
            if (e.keyCode ==  config.setInit.code && e.altKey) {
                e.preventDefault()
                /**
                 * Not passing parameters, the action will populate it
                 */
                vm.$store.dispatch(`${srt_module}/updateTimeInitFromPlayer`, {})
            }
            
            /**
             * Set time final - ALT + K
             */
            if (e.keyCode == config.setFinal.code && e.altKey) {
                e.preventDefault()
                /**
                 * Not passing parameters, the action will populate it
                 */
                vm.$store.dispatch(`${srt_module}/updateTimeFinalFromPlayer`, {})

            }
            /** 
            * Ponto Final e ponto final do numpad 
            */
            if (e.keyCode == 190 || e.keyCode == 194){  
                vm.$store.dispatch(`${srt_module}/capitalizeNextSegment`)
            }
            /* Interrogação */
            if (e.shiftKey && e.keyCode == 193){       
                vm.$store.dispatch(`${srt_module}/capitalizeNextSegment`)
            }
            /* Exclamação */
            if (e.shiftKey && e.keyCode == 49){         
                vm.$store.dispatch(`${srt_module}/capitalizeNextSegment`)
            }
            /**
             * Bookmarks
             */
            if(e.ctrlKey && e.keyCode == 66){
                e.preventDefault()
                vm.$store.dispatch(`${srt_module}/bookmarkActualSegment`)
            }
            
            /**
             * Toggle revision mode - ALT + I
             */
            if (e.keyCode == config.revisionMode.code && e.altKey) {
                e.preventDefault()
                vm.$store.commit('controller/toggleRevisionMode')
            }
        })

        /**
         * Mouse wheels
         */
        
        window.addEventListener("wheel", async (event) => {
            const delta = Math.sign(event.deltaY) // 1=down | -1=up
            //await vm.$store.dispatch(`${srt_module}/updateSegmentBeforeChange`)

            if (delta == 1) {
                vm.$store.dispatch('controller/navigateDown')
            } else if (delta == -1) {
                vm.$store.dispatch('controller/navigateUp')
            }            
        }, {passive: true});

    }
}